import { FC } from 'react';

import DirectionPercent from '../../../components/DirectionPercent';
import {
  StyledInfoBlock,
  StyledInfoKw,
  StyledInfoPower,
  StyledInfoRow,
  StyledInfoTitle,
} from '../../styles';
import { InfoItemsType } from '../../types';
import { formatNumberWithSpace } from '../../utils';

interface ElectricityConsumptionInfoProps {
  data?: {
    title: string;
    power?: number | string;
    percent?: number;
  }[];
  infoItems: InfoItemsType[];
  isConsumer?: boolean;
}

interface ElectricityConsumptionBlockProps {
  borderColor: string;
  title: string;
  power?: number | string;
  percent?: number;
}

const ElectricityConsumptionBlock: FC<ElectricityConsumptionBlockProps> = ({
  borderColor,
  title,
  power,
  percent,
}) => {
  if (!title) {
    return <></>;
  }
  return (
    <StyledInfoBlock borderColor={borderColor}>
      <StyledInfoTitle>{title}</StyledInfoTitle>
      <StyledInfoPower>
        {formatNumberWithSpace(power)}
        <StyledInfoKw> kWh</StyledInfoKw>
      </StyledInfoPower>
      {percent ? <DirectionPercent percent={percent} /> : null}
    </StyledInfoBlock>
  );
};

const ElectricityConsumptionInfo: FC<ElectricityConsumptionInfoProps> = ({
  data,
  infoItems,
  isConsumer,
}) => {
  return (
    <StyledInfoRow isConsumer={isConsumer}>
      {infoItems.map((infoItem, idx) => (
        <ElectricityConsumptionBlock
          key={idx}
          title={infoItem.title}
          borderColor={infoItem.color}
          power={data?.[idx].power}
          percent={undefined}
        />
      ))}
    </StyledInfoRow>
  );
};

export default ElectricityConsumptionInfo;
