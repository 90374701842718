import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { formatNumberWithSpace } from 'components/communities/Charts/ElectricityConsumption/utils';
import EmissionsPrevented from 'components/communities/Charts/img/EmissionsPrevented.svg';
import { popoverOverlayStyles } from 'components/communities/Communities/styles';

import Popover from '../Popover';
import { SkeletonLine } from '../Skeleton';
import {
  WidgetAmount,
  WidgetBlock,
  WidgetCo2,
  WidgetCo2Image,
  WidgetHeaderRow,
  WidgetRow,
  WidgetSubtitle,
  WidgetTitle,
  WidgetWrapper,
} from './styles';

interface WidgetEmissionsProps {
  isLoading?: boolean;
  amount: number | null | undefined;
  subTitle: string;
  tooltipContext: string;
}

const EMISSION_DEFAULT_COEFFICIENT = 34.2;

const WidgetEmissions: FC<WidgetEmissionsProps> = ({
  isLoading = false,
  amount = null,
  subTitle,
  tooltipContext,
}) => {
  const { t } = useTranslation();
  const subtitleText = amount ? '' : t('prevent');
  const emission = amount ?? EMISSION_DEFAULT_COEFFICIENT;
  const units = amount ? 'kgCO' : 'gCO';
  const unitPostfix = '2';

  return (
    <WidgetWrapper>
      <WidgetHeaderRow>
        <WidgetRow>
          <WidgetTitle>{t('emissions_prevented')}</WidgetTitle>
          <Popover
            placement="bottom"
            context={tooltipContext}
            overlayStyles={popoverOverlayStyles}
          />
        </WidgetRow>
      </WidgetHeaderRow>
      <WidgetRow>
        <WidgetCo2Image src={EmissionsPrevented} />
        <WidgetBlock>
          <WidgetSubtitle>
            {isLoading ? <SkeletonLine active size="small" /> : subtitleText}
          </WidgetSubtitle>
          {isLoading ? (
            <SkeletonLine active height={30} />
          ) : (
            <WidgetAmount>
              {formatNumberWithSpace(emission)}
              <WidgetCo2>
                {units}
                <sub>{unitPostfix}</sub>
              </WidgetCo2>
            </WidgetAmount>
          )}
          {amount ? (
            <WidgetSubtitle />
          ) : (
            <WidgetSubtitle>
              {isLoading ? <SkeletonLine active size="small" /> : subTitle}
            </WidgetSubtitle>
          )}
        </WidgetBlock>
      </WidgetRow>
    </WidgetWrapper>
  );
};

export default WidgetEmissions;
