import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import FinancialFollowup from 'components/communities/Charts/FinancialFollowup';
import { FinancialDataTypes } from 'components/communities/Dashboard/types';
import { mapFinancialData } from 'components/communities/Dashboard/utils';
import CreateReportFinancialData from 'components/communities/components/CreateReportFinancialData';
import { labelsByType } from 'components/communities/constants';
import { formatFinancialDataWithEmptyTypes } from 'components/communities/utils';
import { ROLES } from 'constants/global';
import AppRoutes from 'constants/routes';
import { useGetCommunityFinancialDataQuery } from 'services/communityManagement';
import { useGetMembersQuery } from 'services/membersManagement';

import { useAppSelector } from 'utils/hooks';

import {
  StyledContainer,
  StyledFinTableHeader,
  StyledTabWrapper,
} from '../../styles';
import FinancialFollowUpTable from './components/FinancialFollowUpTable';
import {
  infoItems,
  legendItems,
  someEarningsDummyData,
  types,
} from './constants';
import { getFinancialDataTotal } from './utils';

const FinancialDataTab = () => {
  const period = useAppSelector((state) => state.dashboardPeriod.period);
  const { data: members } = useGetMembersQuery();
  const { data: financialData } = useGetCommunityFinancialDataQuery({ period });
  const [selectedType, setSelectedType] = useState(
    FinancialDataTypes.ALL_EARNINGS,
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  useEffect(() => {
    searchParams.set('tab', AppRoutes.FinancialData);
    setSearchParams(searchParams);
  }, []);

  const mappedSelectConsumers = () => {
    if (!members) return [];
    return members
      ?.filter((member) => member.role !== ROLES.PRODUCER, [])
      .map((member) => ({
        label: member.firstName + member.lastName,
        key: member?._id!,
      }));
  };

  const financialDataMapped =
    financialData && financialData.length > 0
      ? formatFinancialDataWithEmptyTypes(
          mapFinancialData(financialData, t),
          types,
          labelsByType,
        )
      : someEarningsDummyData;

  return (
    <StyledTabWrapper>
      <StyledContainer>
        <FinancialFollowup
          selectItems={[]}
          setSelectValue={setSelectedType}
          selectValue={selectedType}
          infoItems={infoItems}
          infoData={getFinancialDataTotal(financialData)}
          legendItems={legendItems}
          data={financialDataMapped}
          hasData={financialData?.length > 0}
        />
        <CreateReportFinancialData
          datePickerType="month"
          consumers={mappedSelectConsumers()}
        />
        <StyledFinTableHeader>{t('financial_follow_up')}</StyledFinTableHeader>
        <FinancialFollowUpTable />
      </StyledContainer>
    </StyledTabWrapper>
  );
};

export default FinancialDataTab;
