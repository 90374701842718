import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Tag } from 'antd';

import { TFunction } from 'i18next';

import { getPrmStatusLayout } from 'components/pricing/components/communityTab/ConsumerPrmItem';
import { TextRegular } from 'components/shared/Text';
import CustomTooltip from 'components/shared/Tooltip';
import { USER_ROLES, USER_STATUSES } from 'constants/types';
import { IDBPrm } from 'services/types';

import { DetailCardRow } from '../../styles';
import {
  DetailCardBlock,
  DetailCardLabel,
  DetailCardTitle,
  DetailCardValue,
  StatusCardPendingMessage,
  StatusCardPendingRow,
  StatusPrmsContainer,
} from './styles';

interface StatusCardProps {
  title: string;
  status?: string;
  paymentStatus?: string;
  role?: string;
  prms: IDBPrm[];
}

interface ConsumerStatusCardProps {
  title: string;
  status?: string;
  paymentStatus?: string;
  role?: string;
}

interface ProducerStatusCardProps {
  title: string;
  prms: IDBPrm[];
}

const ConsumerStatusCard: FC<ConsumerStatusCardProps> = ({
  title,
  paymentStatus = 'N/A',
  status = 'Active',
}) => {
  const { t } = useTranslation();
  return (
    <DetailCardBlock>
      <DetailCardTitle>{title}</DetailCardTitle>
      <DetailCardRow>
        <DetailCardLabel>{t('status')}</DetailCardLabel>
        <DetailCardValue>{getPrmStatusLayout(status, t)}</DetailCardValue>
      </DetailCardRow>
      <DetailCardRow>
        <DetailCardLabel>{t('payment_status')}</DetailCardLabel>
        <DetailCardValue>{paymentStatus}</DetailCardValue>
      </DetailCardRow>
    </DetailCardBlock>
  );
};

const ProducerStatusCard: FC<ProducerStatusCardProps> = ({ title, prms }) => {
  const { t } = useTranslation();
  return (
    <DetailCardBlock>
      <DetailCardTitle>{title}</DetailCardTitle>
      <DetailCardRow>
        <DetailCardLabel width="100px">
          {t('prm').toUpperCase()}
        </DetailCardLabel>
        <DetailCardLabel width="100px">
          {t('prm').toUpperCase() + ' ' + t('status').toLowerCase()}
        </DetailCardLabel>
      </DetailCardRow>
      <StatusPrmsContainer>
        {prms.map(({ prmValue, status }) => (
          <DetailCardRow>
            <DetailCardValue width="100px">{prmValue}</DetailCardValue>
            <DetailCardValue width="100px">
              {getPrmStatusLayout(status as string, t)}
            </DetailCardValue>
          </DetailCardRow>
        ))}
      </StatusPrmsContainer>
    </DetailCardBlock>
  );
};

const StatusCard: FC<StatusCardProps> = ({
  title,
  paymentStatus = 'N/A',
  status = 'Active',
  role,
  prms,
}) => {
  return role === USER_ROLES.CONSUMER ? (
    <ConsumerStatusCard
      title={title}
      paymentStatus={paymentStatus}
      status={status}
      role={role}
    />
  ) : (
    <ProducerStatusCard title={title} prms={prms} />
  );
};

export default StatusCard;
