import dayjs from 'dayjs';
import { getAppRole } from 'utils';

import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BtnPrimary } from 'components/shared/Button';
import { IDBPrmPrice, USER_ROLES } from 'constants/types';

import ActualPrices from './ActualPrices';
import HistoricalPrices from './HistoricalPrices';
import UpcomingPrices from './UpcomingPrices';
import {
  StyledNoPricesContainer,
  StyledNoPricesText,
  StyledPricesWrapper,
  customAddPriceBtnStyles,
} from './styles';
import isBetween from 'dayjs/plugin/isBetween';

interface PrmPricesProps {
  prmPrice: any;
  isHistoricalPricesShown: boolean;
}

dayjs.extend(isBetween);

const PrmPrices: FC<PrmPricesProps> = ({
  prmPrice,
  isHistoricalPricesShown,
}) => {
  const { t } = useTranslation();
  const userRole = getAppRole();

  const { actualPrices, upcomingPrices, historyPrices } = useMemo(() => {
    const currentDate = dayjs();
    return prmPrice?.periods.reduce(
      (acc: any, period: any) => {
        const startDate = dayjs(period.startDate);
        const endDate = dayjs(period.endDate);

        switch (true) {
          case currentDate.isBefore(startDate): {
            acc.upcomingPrices.push(period);
            return acc;
          }

          case currentDate.isBetween(startDate, endDate): {
            acc.actualPrices.push(period);
            return acc;
          }

          default: {
            acc.historyPrices.push(period);
          }
        }

        return acc;
      },
      { actualPrices: [], upcomingPrices: [], historyPrices: [] },
    );
  }, [prmPrice]);

  const noPrices = useMemo(
    () =>
      !actualPrices &&
      !actualPrices.length &&
      !upcomingPrices &&
      !upcomingPrices.length &&
      !historyPrices &&
      !historyPrices.length,
    [actualPrices, upcomingPrices, historyPrices],
  );

  const isAdmin = useMemo(() => userRole === USER_ROLES.ADMIN, [userRole]);

  return (
    <StyledPricesWrapper>
      {actualPrices && actualPrices.length ? (
        <ActualPrices
          period={actualPrices[0]}
          indexation={prmPrice.indexation}
        />
      ) : null}
      {upcomingPrices && upcomingPrices.length ? (
        <UpcomingPrices
          period={upcomingPrices[0]}
          indexation={prmPrice.indexation}
        />
      ) : null}
      <HistoricalPrices
        periods={historyPrices}
        indexation={prmPrice.indexation}
        isShown={
          isHistoricalPricesShown && historyPrices && !!historyPrices.length
        }
      />
      {noPrices && (
        <StyledNoPricesContainer>
          <StyledNoPricesText>{t('no_prices')}</StyledNoPricesText>
          {isAdmin && (
            <BtnPrimary additionalStyles={customAddPriceBtnStyles}>
              {t('add_new_prices')}
            </BtnPrimary>
          )}
        </StyledNoPricesContainer>
      )}
    </StyledPricesWrapper>
  );
};

export default PrmPrices;
