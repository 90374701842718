import { FC } from 'react';

import ChartInfo from 'components/communities/Charts/ChartInfo';
import { formatNumberWithSpace } from 'components/communities/Charts/ElectricityConsumption/utils';
import CostEarnedSvg from 'components/communities/Charts/img/CostSaving.svg';

import {
  InfoBlock,
  InfoCostEarnedImage,
  InfoCostEarnedRow,
  InfoItemAmount,
  InfoItemEuro,
  StyledInfoItemsBlock,
} from './styles';

interface CostEarnedInfoProps {
  data: number[];
  infoItems: any;
  totalCostEarned?: number;
}

const CostEarnedInfo: FC<CostEarnedInfoProps> = ({ data, infoItems }) => {
  const totalCostEarned = data?.some((item) => item)
    ? (data?.[0] + data?.[1]).toFixed(2)
    : 'N/A';

  return (
    <InfoBlock>
      <InfoCostEarnedRow>
        <InfoCostEarnedImage src={CostEarnedSvg} />
        <InfoItemAmount>
          {formatNumberWithSpace(totalCostEarned)}
          <InfoItemEuro> €</InfoItemEuro>
        </InfoItemAmount>
      </InfoCostEarnedRow>
      <StyledInfoItemsBlock>
        <ChartInfo data={data} infoItems={infoItems} />
      </StyledInfoItemsBlock>
    </InfoBlock>
  );
};

export default CostEarnedInfo;
