import { IElectricityPrice } from 'services/types';

export type NumberTuple = [number, number];

export interface MapPoint {
  id: string;
  latitude: number;
  longitude: number;
  title: string;
  location: string;
  type?: USER_ROLES;
}

interface CommunityPerimeter {
  id: string;
  coordinates: number[][];
}

export type CommunitiesPerimeter = CommunityPerimeter[];

export enum USER_ROLES {
  PMO_ADMIN = 'PMO',
  ADMIN = 'OYO_Admin',
  CONSUMER = 'Consumer',
  PRODUCER = 'Producer',
}

export enum USER_STATUSES {
  ACTIVE = 'Active',
  PENDING = 'Pending',
  CONFLICT = 'Conflict',
  DISABLED = 'Disabled',
  PROBATION = 'Probation',
}

export enum PRM_STATUSES {
  PENDING = 'Pending',
  ACTIVE = 'Active',
  CONFLICT = 'Conflict',
  DEACTIVATION = 'Deactivation',
  DELETED = 'Deleted',
}

export enum PRM_TYPE {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  PRIVATE = 'private',
}

export interface ILocation {
  type: 'Point';
  coordinates: [number, number];
  _id?: number;
}

export interface IDBAddress {
  city: string;
  country: string;
  postalCode: number;
  street: string;
  building: string;
  location: ILocation;
}

export enum CONSUMER_TYPE {
  PRIVATE = 'private',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  COLLECTIVITY = 'collectivity',
}

export interface IUser {
  position?: string;
  email?: string;
  contactNumber?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
}

export interface IAdditionalInfo {
  type?: CONSUMER_TYPE;
  siretNumber?: number;
  power: number;
  billingAddress: IDBAddress;
  billingPerson: IUser;
  legalPerson: IUser;
  electricityPrice?: IElectricityPrice;
  prmNumbers: [number];
  consumptionAgreementSigned?: boolean;
  cppaSigned?: boolean;
  agreementSigned: boolean;
  enedisConsentSigned: boolean;
  licenseOperationSigned?: boolean;
  prms?: string[];
}

export interface IDBUser extends IUser {
  _id: string;
  auth0Id?: string;
  role: USER_ROLES;
  status?: USER_STATUSES;
  address: IDBAddress;
  additionalInfo: IAdditionalInfo;
}

export interface IPrm extends Document {
  _id?: string;
  prmValue: string;
  prmType?: PRM_TYPE;
  role: [USER_ROLES.CONSUMER, USER_ROLES.PRODUCER];
  status: PRM_STATUSES;
  community: string;
  gridPrice: {
    RH: number;
    HS_HH: number;
    HS_LH: number;
    LS_HH: number;
    LS_LH: number;
  };
}

export interface IDBPrm extends IPrm {
  user: IDBUser;
}

export interface IDBPrmPrice extends IDBPrm {
  prms: IDBPrm[];
}

export interface IDBLocation {
  type: 'Point';
  coordinates: [number, number];
}

export interface Community {
  id: string;
  communityName: string;
  location: string;
  coordinates: number[];
  consumers: [IDBPrm];
  producers: [IDBPrm];
  pvPower: string;
  pmoFirstName: string;
  pmoLastName: string;
  pmoMiddleName: string;
  pmoId: string;
  pmoEmail: string;
  selfConsumedEnergy: number;
  gridEnergy: number;
  perimeter: number;
  pmoOrgName?: string;
}

export interface IDBCommunity {
  _id: string;
  name: string;
  address: {
    city: string;
    country: string;
    postalCode: number;
    location: IDBLocation;
  };
  pmoOrgName: string;
  sirenNumber: number;
  pmo: IDBUser;
  consumers: [IDBPrm];
  producers: [IDBPrm];
  enedisAgreed: boolean;
  supportAgreed: boolean;
  maxConsumers: number;
  maxProducers: number;
  maxDistance: number;
  maxPower: number;
  agreementId: string;
}

export interface Member {
  communityName: string;
  firstName: string;
  lastName: string;
  middleName: string;
  payment: keyof typeof PAYMENT_STATUS;
  role: string;
  status: keyof typeof MEMBER_STATUS;
  _id: string;
  selfProductionRatio?: number;
  address: IDBAddress;
}

export enum MEMBER_STATUS {
  Active = 'Active',
  Pending = 'Pending',
  Disabled = 'Disabled',
  Conflict = 'In Conflict',
}

export enum PAYMENT_STATUS {
  PAID = 'Paid',
}

export enum PERIOD {
  CURRENT_WEEK = 'current_week',
  LAST_SIX_MONTH = 'last_six_month',

  PREVIOUS_DAY = 'previous_day',
  LAST_SEVEN_DAYS = 'last_seven_days',
  CURRENT_MONTH = 'current_month',
  LAST_MONTH = 'last_month',
  CURRENT_YEAR = 'current_year',
  LAST_YEAR = 'last_year',
  LAST_TWELVE_MONTHS = 'last_twelve_months',
}

export type CustomSelectItem = {
  label: string | null;
  key: string | number;
};

export enum PRODUCER_INVOICE_STATUSES {
  ISSUED = 'Issued',
  PAYMENT_ERROR = 'PaymentError',
  ERROR = 'InvoiceGenerationError',
  PAID = 'Paid',
  AWAITING_PAYMENT = 'AwaitingPayment',
  OVERDUE = 'Overdue',
  CANCEL = 'Cancel',
}

export enum CONSUMER_INVOICE_STATUSES {
  ISSUED = 'Issued',
  OVERDUE = 'Overdue',
  PAID = 'Paid',
  PAYMENT_IN_PROGRESS = 'PaymentInProgress',
  PAYMENT_ERROR = 'PaymentError',
  CANCELLED = 'CANCELLED',
  ERROR = 'InvoiceGenerationError',
  AWAITING_PAYMENT = 'AwaitingPayment',
}

export interface IScheduleTypes {
  RH: number;
  HS_HH: number;
  HS_LH: number;
  LS_HH: number;
  LS_LH: number;
}

export interface IPeriods {
  scheduleTypes: IScheduleTypes;
  startDate: Date;
  endDate: Date;
}
