import { styled } from 'styled-components';

import { COLORS } from 'constants/colors';

export const StyledPMOContainer = styled.div`
  padding: 32px;
  background: ${COLORS.BgContainer};
`;

export const StyledPMOName = styled.div`
  font-size: 30px;
  font-weight: 600;
  margin-top: 20px;
  min-height: 75px;
  display: flex;
  align-items: center;
`;
