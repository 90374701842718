import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Container } from 'components/communities/styles';
import Loader from 'components/shared/Loader';
import { useGetPaymentsQuery } from 'services/paymentManagment';
import { useGetMemberByIdQuery } from 'services/pmoMemberDashboardManagment';

import { StyledTabWrapper } from '../../../Profile/styles';
import BillingData from './components/BillingData';
import ProfileData from './components/ProfileData';
import Security from './components/Security';

const PAYMENT_FETCH_DELAY = 2000;

const ConsumerProfileTab = () => {
  const [skipFetchPayment, setSkipFetchPayment] = useState(true);
  const { id } = useParams();
  const {
    data: paymentsData,
    isLoading: isLoadingPayments,
    refetch: refetchPayments,
  } = useGetPaymentsQuery({ id }, { skip: skipFetchPayment });

  const {
    isLoading: isLoadingMember,
    data: member,
    refetch: refetchMember,
  } = useGetMemberByIdQuery(id);

  const isLoading = isLoadingMember || isLoadingPayments;

  const defaultPaymentMethod = paymentsData?.paymentMethods?.find(
    (method) => method.default,
  );

  // in case consumer just changed payment method, we need delay for stripe updating new data
  useEffect(() => {
    const timeout = setTimeout(
      () => setSkipFetchPayment(false),
      PAYMENT_FETCH_DELAY,
    );
    return () => clearTimeout(timeout);
  }, []);

  return (
    <StyledTabWrapper>
      {isLoading && <Loader />}
      <Container>
        <ProfileData member={member} refetch={refetchMember} />
        <BillingData
          member={member}
          paymentMethod={defaultPaymentMethod}
          refetch={refetchPayments}
        />
        <Security />
      </Container>
    </StyledTabWrapper>
  );
};

export default ConsumerProfileTab;
