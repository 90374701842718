import { styled } from 'styled-components';

import { COLORS } from 'constants/colors';

export const StyledLineChart = styled.div<{ width: number }>`
  max-width: ${(props) => props.width}px;
  width: 100%;
  margin-top: 20px;
  height: 226px;
`;

export const Card = styled.div<{ width: number }>`
  display: flex;
  width: ${(props) => props.width}px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  min-height: 366px;

  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  background: ${COLORS.BgContainer};
`;

export const CardBody = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  row-gap: 50px;
  align-items: center;
  margin-top: 24px;
  padding: 0px 0px 21px 15px;
`;

export const StyledInfoWrapper = styled.div<{
  width: number;
  height: number;
  padding: number;
}>`
  box-sizing: border-box;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  background-color: ${COLORS.BgLayout};
  border-radius: 2px;
  padding: ${(props) => props.padding}px;
  margin-right: 31px;
  margin-bottom: 26px;
`;

export const StyledInfoRow = styled.div`
  display: flex;
  column-gap: 21px;
  margin-bottom: 40px;
  align-items: center;
`;

export const StyledInfoImageRow = styled.img`
  padding: 11px 12px 8px 8px;
  border-radius: 6px;
  background: ${COLORS.BgElevated};
`;

export const StyledInfoValue = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
`;

export const StyledInfoEuro = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin-left: 5px;
`;

export const StyledInfoTotalSavings = styled.div`
  font-size: 12px;
  line-height: 20px;
`;

export const StyledInfoTotalTitle = styled.div`
  font-size: 12px;
  line-height: 20px;
  width: 40px;
  margin-right: 18px;
`;
