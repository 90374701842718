import { IDBCommunity, IPeriods, USER_ROLES } from 'constants/types';

export type CreateCommunityPayload = {
  name: string;
  address: CommunityAddress;
  pmoOrgName: string;
  sirenNumber: number | null;
  pmo: PMOInfo;
  enedisAgreed: boolean;
  supportAgreed: boolean;
  maxConsumers: number | null;
  maxProducers: number | null;
  maxDistance: number | null;
  maxPower: number | null;
  agreementId: string;
};

export type PMOAddress = {
  city: string;
  country: string;
  postalCode: string;
  street: string;
  building: string;
};

type PMOInfo = {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  contactNumber: number | string | null;
  address: PMOAddress;
};

type CommunityAddress = {
  city: string;
  country: string;
  postalCode: string;
  location: {
    coordinates: number[] | null[];
  };
};

export type CreateConsumerPayload = {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  contactNumber: number | string | null;
  role: string;
  address: ConsumerAddress;
  additionalInfo: {
    type: string;
    siretNumber: number | null;
    prms: IPrmNumber[];
    power?: number | null;
    billingAddress: PMOAddress;
    billingPerson: {
      firstName: string;
      middleName: string;
      lastName: string;
      email: string;
      contactNumber: string;
    };
    legalPerson: {
      firstName: string;
      middleName: string;
      lastName: string;
      email: string;
      contactNumber: string;
      position: string;
    };
    cppaSigned: boolean;
    agreementSigned: boolean;
    enedisConsentSigned: boolean;
    paymentMethodAdded?: boolean;
    hasSpecialTariffs?: boolean;
  };
};

interface ConsumerAddress extends PMOAddress {
  location: {
    coordinates: number[];
    type?: string;
  };
}

export interface IElectricityPrice {
  dynamic?: {
    RH?: number | null;
    HS_HH?: number | null;
    HS_LH?: number | null;
    LS_HH?: number | null;
    LS_LH?: number | null;
  };
  static?: number | null;
}

interface IAdditionalInfo {
  siretNumber: number | null;
  power: number | null;
  vatNumber: number | null;
  sharedCapital: string;
  billingAddress: ConsumerAddress;
  billingPerson: {
    firstName: string;
    lastName: string;
    middleName: string;
    email: string;
    contactNumber: string | number;
  };
  legalPerson: {
    firstName: string;
    lastName: string;
    middleName: string;
    email: string;
    contactNumber: string | number;
    position: string;
  };
  prms: IProducerPrmNumber[];
  agreementSigned: boolean;
  licenseOperationSigned: boolean;
  consumptionAgreementSigned: boolean;
  enedisConsentSigned: boolean;
}

export type CreateProducerPayload = {
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  contactNumber: string | number;
  role: string;
  address: ConsumerAddress;

  additionalInfo: IAdditionalInfo;
};

export interface Suggestion {
  center: number[];
  text: string;
  place_name: string;
  context: any[];
}

export interface IMember extends CreateConsumerPayload {
  createdAt: string;
  updatedAt: string;
  _id?: string;
  auth0Id: string;
  status: string;
}

export interface ILabelMap {
  totalConsumption?: string;
  totalProduction?: string;
  selfConsumption: string;
  gridConsumption?: string;
  overproduction?: string;
}

type IProducerPrmNumber = {
  prmValue: number | null;
};

export enum PRM_TYPE {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  PRIVATE = 'private',
}

export enum USER_STATUSES {
  ACTIVE = 'Active',
  PENDING = 'Pending',
  CONFLICT = 'Conflict',
  DISABLED = 'Disabled',
}

export interface IUser {
  position?: string;
  email?: string;
  contactNumber?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  name?: string;
}

export interface IDBUser extends IUser {
  _id?: string;
  auth0Id?: string;
  role: USER_ROLES;
  status?: USER_STATUSES;
  address?: ConsumerAddress;
  additionalInfo?: IAdditionalInfo;
}

export interface IPrmNumber {
  prmValue: number | null;
  power: number | null;
  name: string;
  gridPrice: IElectricityPrice;
}
export interface IDBPrm extends IPrmNumber {
  _id: string;
  user: IDBUser;
  community: string;
  status: USER_STATUSES;
  prmType?: PRM_TYPE;
  role: [USER_ROLES.CONSUMER, USER_ROLES.PRODUCER];
}

export interface ICommunityData {
  consumption: string;
  selfConsumption: string;
  gridConsumption: string;
  overproduction: string;
  consumersAmount: number;
  producersAmount: number;
  maxPover: number;
}
export interface IPayment {
  sessionUrl: string;
}

export interface IPaymentMethod {
  id: string;
  bicAccount: number;
  branchCode: number;
  country: string;
  ibanLast4: number;
  mandateReferenceNumber: string;
  default: boolean;
}

export interface DateRange {
  startDate?: string;
  endDate?: string;
}

export interface DateRangeWithUserId extends DateRange {
  userId?: string;
}

export interface DateRangeWithUserIdAndPrmId extends DateRangeWithUserId {
  prmId?: string | number | undefined;
}

export interface DateRangeWithCommunityId extends DateRange {
  communityId: string | undefined;
}

export interface IDBPrmPrice {
  community: IDBCommunity;
  prm: IDBPrm;
  indexation: number;
  indexType: number;
  cppa: string;
  periods: [IPeriods];
}
