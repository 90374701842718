import { ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { ROLES } from 'constants/global';
import AppRoutes from 'constants/routes';

import { isValidRole } from 'utils/index';

interface IProtectedByRole {
  children: ReactElement;
}
const ProtectedByRole = ({ children }: IProtectedByRole) => {
  const { pathname } = useLocation();
  const isOyoAdmin = isValidRole(ROLES.OYO_ADMIN);
  const isPMO = isValidRole(ROLES.PMO);
  const isProducer = isValidRole(ROLES.PRODUCER);
  const isConsumer = isValidRole(ROLES.CONSUMER);

  // for the community details page to prevent redirect to communities page
  if (isOyoAdmin && pathname.includes(AppRoutes.Communities)) {
    return children;
  }

  switch (pathname) {
    case AppRoutes.Communities:
    case AppRoutes.AddNewCommunity:
    case AppRoutes.TaxPrices: {
      if (isPMO) {
        return <Navigate to={AppRoutes.MyCommunity} replace />;
      }

      if (isConsumer || isProducer) {
        return <Navigate to={AppRoutes.Dashboard} replace />;
      }

      return children;
    }

    case AppRoutes.MyCommunity: {
      if (isOyoAdmin) {
        return <Navigate to={AppRoutes.Communities} replace />;
      }

      return children;
    }

    case AppRoutes.EnergyData: {
      return children;
    }

    case AppRoutes.FinancialData: {
      if (isPMO) {
        return <Navigate to={AppRoutes.MyCommunity} replace />;
      }

      if (isOyoAdmin) {
        return <Navigate to={AppRoutes.Communities} replace />;
      }

      return children;
    }

    case AppRoutes.Reports: {
      if (isOyoAdmin) {
        return <Navigate to={AppRoutes.Communities} replace />;
      }
      if (!isPMO) {
        return <Navigate to={AppRoutes.Dashboard} replace />;
      }

      return children;
    }
    case AppRoutes.Profile: {
      return children;
    }

    case AppRoutes.AddPayment: {
      if (isPMO) {
        return <Navigate to={AppRoutes.MyCommunity} replace />;
      }

      if (isOyoAdmin) {
        return <Navigate to={AppRoutes.Communities} replace />;
      }

      return children;
    }

    // case AppRoutes.Pricing: {
    //   if (isOyoAdmin || isPMO || isProducer) {
    //     return children;
    //   }
    //   return <Navigate to={AppRoutes.Dashboard} replace />;
    // }

    default: {
      return <Navigate to={AppRoutes.SignIn} replace />;
    }
  }
};

export default ProtectedByRole;
