import { styled } from 'styled-components';

import { TextRegular } from 'components/shared/Text';

export const WidgetRatioTitle = styled.div`
  display: flex;
  margin-top: 5px;
`;

export const WidgetRatioRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const WidgeRatioTitlesRow = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const PieChartContainer = styled.div`
  width: 101px;
  height: 101px;
  margin-top: -15px;
  position: relative;
`;

export const WidgetChartPercent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
