import { getBaseRoute } from 'utils';

import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Breadcrumbs from 'components/shared/Header/Breadcrumbs';
import NavList from 'components/shared/Header/NavList';
import { LogoContainer, StyledWrapper } from 'components/shared/Header/styles';
import Logo from 'components/shared/Logo';

import Notifications from './Notifications';
import UserMenu from './UserMenu';

const Header: React.FC = () => {
  const navigate = useNavigate();
  const baseRoute = getBaseRoute();

  const onLogoClick = useCallback(() => {
    navigate(baseRoute);
  }, [baseRoute, navigate]);

  return (
    <StyledWrapper id="header">
      <LogoContainer onClick={onLogoClick}>
        <Logo size={82} color="Grey5" />
      </LogoContainer>
      <NavList />
      {/*<Notifications />*/}
      <UserMenu />
      <Breadcrumbs />
    </StyledWrapper>
  );
};

export default Header;
