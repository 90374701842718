import { IPieItem } from 'components/shared/PieChart';
import { EnergyTypes } from 'constants/energy-types';
import { Community } from 'constants/types';

export const preparePieChartData = (
  community: Community,
  selfConsumedEnergy?: number,
): IPieItem[] => [
  {
    type: EnergyTypes.SELF_CONSUMED,
    value: selfConsumedEnergy || community.selfConsumedEnergy,
  },
  {
    type: EnergyTypes.GRID_ENERGY,
    value: selfConsumedEnergy ? 100 - selfConsumedEnergy : community.gridEnergy,
  },
];

export const prepareNextUrl = (communityName: string) =>
  communityName.toLowerCase().trim().split(' ').join('-');
