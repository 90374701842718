import { useTranslation } from 'react-i18next';

import { WidgetTitle } from 'components/communities/Charts/ConsumerSummaryView/styles';
import { formatNumberWithSpace } from 'components/communities/Charts/ElectricityConsumption/utils';
import CostSavingSvg from 'components/communities/Charts/img/CostSaving.svg';
import { popoverOverlayStyles } from 'components/communities/Communities/styles';
import { WidgetWrapper } from 'components/communities/styles';
import Popover from 'components/shared/Popover';

import {
  WidgetAmount,
  WidgetAmountType,
  WidgetImage,
  WidgetRow,
} from '../../styles';

const WidgetEarnings = ({ amount }: { amount?: number | string }) => {
  const { t } = useTranslation();
  return (
    <WidgetWrapper>
      <WidgetTitle>
        {t('earnings')}
        <Popover
          context={t('the_sum_of_consumer_and_producers_invoices')}
          overlayStyles={popoverOverlayStyles}
        />
      </WidgetTitle>
      <WidgetRow>
        <WidgetImage src={CostSavingSvg} />

        <WidgetAmount>
          {amount ? formatNumberWithSpace(amount) : 'N/A'}
          <WidgetAmountType>€</WidgetAmountType>
        </WidgetAmount>
      </WidgetRow>
    </WidgetWrapper>
  );
};

export default WidgetEarnings;
