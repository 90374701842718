import styled from 'styled-components';

import { TextRegular } from 'components/shared/Text';
import { COLORS } from 'constants/colors';

export const CardSmall = styled.div`
  display: flex;
  width: 443px;
  height: 203px;
  flex-shrink: 0;

  border-radius: 2px;
  background: ${COLORS.BgLayout};
  box-sizing: border-box;

  padding: 12px;
`;

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
export const CardChart = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const ChartCardLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
`;

export const ChartCardLineItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

export const ChartData = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

export const ChartDataColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ChartHeader = styled.div`
  display: flex;
  align-self: flex-start;
  align-items: center;
`;

export const LegendText = styled(TextRegular)<{ bold?: boolean }>`
  width: 150px;
  line-height: normal;
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
`;
