import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Button } from 'antd';

import ModalMessage from 'components/shared/ModalMessage';
import { useGetMemberByIdQuery } from 'services/pmoMemberDashboardManagment';
import { usePutForgotPasswordMutation } from 'services/userManagement';

import {
  DetailCardBlock,
  DetailCardLabel,
  DetailCardTitle,
} from '../../../../../Profile/components/Card/styles';
import { DetailCardRow } from '../../../../../Profile/styles';
import {
  StyledBlockBody,
  StyledBlockHeader,
  StyledBlockTitle,
  StyledBlockWrapper,
  StyledButtonRow,
} from '../../styles';

const Security = () => {
  const [putForgotPassword, { isSuccess }] = usePutForgotPasswordMutation();
  const { id } = useParams();
  const { data: member } = useGetMemberByIdQuery(id);
  const [sentStatus, setSentStatus] = useState(false);

  const { t } = useTranslation();

  const handleSetNewPassword = async () => {
    await putForgotPassword(member?.email);
  };

  useEffect(() => {
    if (isSuccess) {
      setSentStatus(true);
    }
  }, [isSuccess]);

  return (
    <StyledBlockWrapper>
      <StyledBlockHeader>
        <StyledBlockTitle>{t('security')}</StyledBlockTitle>
      </StyledBlockHeader>
      <StyledBlockBody>
        <DetailCardBlock width="427px">
          <DetailCardTitle>{t('security')}</DetailCardTitle>
          <DetailCardRow>
            <DetailCardLabel width="100%">
              {t('update_your_password')}
            </DetailCardLabel>
          </DetailCardRow>
          <StyledButtonRow>
            <Button disabled={sentStatus} onClick={handleSetNewPassword}>
              {sentStatus ? t('email_sent') : t('set_new_password')}
            </Button>
          </StyledButtonRow>
        </DetailCardBlock>
      </StyledBlockBody>
      {isSuccess && (
        <ModalMessage
          messageType="success"
          title={t('check_your_email')}
          subTitle={t('reset_password_succes')}
          btnText={t('ok')}
        />
      )}
    </StyledBlockWrapper>
  );
};

export default Security;
