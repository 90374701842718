import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { USER_ROLES } from 'constants/types';

import ConsumerProfileTab from '../ConsumerDashboard/components/ProfileDataTab';
import ConsumerSettingTab from '../ConsumerDashboard/components/SettingsTab';
import ProducerProfileTab from '../ProducerDashboard/components/ProfileDataTab';
import ProducerSettingsTab from '../ProducerDashboard/components/SettingsTab';

const useUrlQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const useTabsConfig = (userRole: USER_ROLES) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const query = useUrlQuery();
  const defaultTab = query.get('tab');

  const [activeTabKey, setActiveTabKey] = useState(defaultTab || 'Profile');

  const handleChangeTab = (key: string) => {
    setActiveTabKey(key);
  };

  useEffect(() => {
    if (defaultTab && defaultTab !== activeTabKey) {
      setActiveTabKey(defaultTab);
      query.delete('tab');
      navigate({ ...location, search: query.toString() });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTab]);

  const tabsConfig = useMemo(() => {
    switch (userRole) {
      case USER_ROLES.CONSUMER: {
        return [
          {
            label: t('profile_data').toUpperCase(),
            key: 'Profile',
            children: <ConsumerProfileTab />,
          },
          {
            label: t('settings').toUpperCase(),
            key: 'Settings',
            children: <ConsumerSettingTab />,
          },
        ];
      }
      case USER_ROLES.PRODUCER: {
        return [
          {
            label: t('profile_data').toUpperCase(),
            key: 'Profile',
            children: <ProducerProfileTab />,
          },
          {
            label: t('settings').toUpperCase(),
            key: 'Settings',
            children: <ProducerSettingsTab />,
          },
        ];
      }
      default:
        return [];
    }
  }, [userRole]);

  return { activeTabKey, handleChangeTab, tabsConfig };
};
