import { styled } from 'styled-components';

import { Tag } from 'antd/lib';

import { CalendarTwoTone, FileDoneOutlined } from '@ant-design/icons';

import { COLORS } from 'constants/colors';

export const StyledPrmWrapper = styled.div`
  background-color: ${COLORS.BgLayout};
  padding: 20px;
  display: flex;
  border-radius: 4;
`;

export const StyledPrmBgWrapper = styled.div`
  background-color: ${COLORS.BgContainer};
  padding: 8px 16px;
`;

export const StyledPrmNumber = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

export const StyledPrmInfoItem = styled.div<{ width?: string }>`
  width: ${(props) => props.width};
`;

export const StyledPrmTitle = styled.span`
  font-size: 12px;
  font-weight: 600;
  opacity: 65%;
  text-transform: uppercase;
  margin-right: 5px;
`;

export const FileIcon = styled(FileDoneOutlined)`
  color: ${COLORS.Green6};
  font-size: 12px;
  margin-right: 6px;
`;

export const CalendarIcon = styled(CalendarTwoTone).attrs(() => ({
  twoToneColor: COLORS.Green6,
}))`
  font-size: 12px;
  margin-right: 6px;
`;

export const StyledExpiredDuration = styled.span`
  color: ${COLORS.ErrorBase};
`;

export const StyledDeactivationStatus = styled(Tag)`
  background: #0000000f;
  color: #000000a6;
  text-transform: capitalize;
`;

export const StyledDeletedStatus = styled(Tag)`
  background: #d0d5dd;
  color: #1d2939;
  text-transform: capitalize;
`;
