import dayjs from 'dayjs';

import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex } from 'antd';

import { TextRegular } from 'components/shared/Text';
import CustomTooltip from 'components/shared/Tooltip';
import { IDBPrmPrice } from 'constants/types';

import {
  StyledCalendarIcon,
  StyledCommunityTitle,
  StyledIndexCol,
  StyledIndexIcon,
  StyledPriceCol,
  StyledPriceContainer,
  StyledPriceTypeText,
  StyledPriceUnitText,
  StyledPriceValue,
  StyledPricesContainer,
  StyledPricesTitle,
  StyledPricesTitleContainer,
  customTooltipStyles,
} from './styles';

interface CommunityPricesProps {
  period: any;
  indexation: string;
}

const emptySymbol = '-';

const CommunityPrices: FC<CommunityPricesProps> = ({
  period = {},
  indexation,
}) => {
  const { t } = useTranslation();
  const { scheduleTypes, startDate, endDate } = period;
  const hasData = useMemo(
    () => scheduleTypes && startDate && endDate,
    [scheduleTypes, startDate, endDate],
  );

  return (
    <StyledPricesContainer>
      <Flex>
        <StyledCommunityTitle>
          {t('community_electricity_price')}
          <CustomTooltip
            tooltip={
              <TextRegular>{t('ttc_price_all_taxes_included')}</TextRegular>
            }
          />
        </StyledCommunityTitle>
      </Flex>
      <Flex>
        <StyledPriceCol width="300px">
          <StyledPricesTitleContainer>
            <StyledPricesTitle>{t('actual_prices')}</StyledPricesTitle>
            <CustomTooltip
              tooltip={<TextRegular>{t('actual_prices_tooltip')}</TextRegular>}
              iconSize={16}
              wrapperStyles={customTooltipStyles}
              containerStyles={customTooltipStyles}
            />
          </StyledPricesTitleContainer>
        </StyledPriceCol>
        <StyledIndexCol width="184px">
          <div>
            <StyledCalendarIcon />
            {hasData ? (
              <span>
                {`${dayjs(startDate).format('DD.MM.YYYY')} - ${dayjs(
                  endDate,
                ).format('DD.MM.YYYY')}`}
              </span>
            ) : (
              <span>{emptySymbol}</span>
            )}
          </div>
          <div>
            <StyledIndexIcon />
            <span>{hasData ? indexation : emptySymbol}</span>
          </div>
        </StyledIndexCol>
        <StyledPriceCol width="150px">
          <StyledPriceContainer>
            <div>
              <StyledPriceTypeText>{t('hshp')}</StyledPriceTypeText>
              <StyledPriceUnitText>, {t('eur_kwh')}</StyledPriceUnitText>
            </div>
            <StyledPriceValue>
              {hasData ? scheduleTypes.HS_HH : emptySymbol}
            </StyledPriceValue>
          </StyledPriceContainer>
        </StyledPriceCol>
        <StyledPriceCol width="150px">
          <StyledPriceContainer>
            <div>
              <StyledPriceTypeText>{t('hshc')}</StyledPriceTypeText>
              <StyledPriceUnitText>, {t('eur_kwh')}</StyledPriceUnitText>
            </div>
            <StyledPriceValue>
              {hasData ? scheduleTypes.HS_LH : emptySymbol}
            </StyledPriceValue>
          </StyledPriceContainer>
        </StyledPriceCol>
        <StyledPriceCol width="150px">
          <StyledPriceContainer>
            <div>
              <StyledPriceTypeText>{t('bshp')}</StyledPriceTypeText>
              <StyledPriceUnitText>, {t('eur_kwh')}</StyledPriceUnitText>
            </div>
            <StyledPriceValue>
              {hasData ? scheduleTypes.LS_HH : emptySymbol}
            </StyledPriceValue>
          </StyledPriceContainer>
        </StyledPriceCol>
        <StyledPriceCol width="150px">
          <StyledPriceContainer>
            <div>
              <StyledPriceTypeText>{t('bshc')}</StyledPriceTypeText>
              <StyledPriceUnitText>, {t('eur_kwh')}</StyledPriceUnitText>
            </div>
            <StyledPriceValue>
              {hasData ? scheduleTypes.LS_LH : emptySymbol}
            </StyledPriceValue>
          </StyledPriceContainer>
        </StyledPriceCol>
        <StyledPriceCol width="150px">
          <StyledPriceContainer>
            <div>
              <StyledPriceTypeText>{t('hdp')}</StyledPriceTypeText>
              <StyledPriceUnitText>, {t('eur_kwh')}</StyledPriceUnitText>
            </div>
            <StyledPriceValue>
              {hasData ? scheduleTypes.RH : emptySymbol}
            </StyledPriceValue>
          </StyledPriceContainer>
        </StyledPriceCol>
      </Flex>
    </StyledPricesContainer>
  );
};

export default CommunityPrices;
