import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TablePaginationConfig } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { MAX_PAGINATION_PAGE_SIZE } from 'components/communities/constants';
import Loader from 'components/shared/Loader';
import { TextRegular } from 'components/shared/Text';

import { extractAndTransformDate } from 'utils/date/date-transform';

import {
  FollowUpTable,
  StyledEmptyDataWrapper,
  StyledTableWrapper,
} from '../../Dashboard/components/ProducerDashboard/components/EnergyData/styles';
import { Card } from '../../Dashboard/components/ProducerDashboard/components/FinancialDataTab/styles';
import { columns } from './constants';

type FilterType = {
  text: string;
  value: string;
};

type TableDataType = {
  period: string;
  prm: string;
  totalProduction: number;
  overproduction: number;
};

interface ProductionFollowUpTableProps {
  tableData?: TableDataType[];
  isSuccess?: boolean;
  isLoading?: boolean;
}

const ProductionFollowUpTable: FC<ProductionFollowUpTableProps> = ({
  tableData,
  isSuccess,
  isLoading,
}) => {
  const { t } = useTranslation();
  const [showedData, setShowedData] = useState<any>([]);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    },
  );
  const [filterPrmNumber, setFilterPrmNumber] = useState<FilterType[]>([]);

  useEffect(() => {
    if (tableData) {
      setShowedData(Object.values(tableData)[0]);
      const prmNumbers = Object.keys(tableData).map((item: any) => ({
        text: item,
        value: item,
      }));

      setFilterPrmNumber(prmNumbers);
    }
  }, [tableData]);

  const handlePaginationChange = (page: number, pageSize: number) => {
    setTablePagination((prev) => ({ ...prev, current: page, pageSize }));
  };

  const modifiedColumns: ColumnsType<any> = [
    {
      title: t('period').toUpperCase(),
      dataIndex: 'period',
      sorter: (report, nextReport) => {
        const date1 = extractAndTransformDate(report.period);
        const date2 = extractAndTransformDate(nextReport.period);
        return date1.localeCompare(date2);
      },
    },
    {
      title: t('prm').toUpperCase(),
      dataIndex: 'prm',
      filters: filterPrmNumber,
      onFilter: (value, record) => record.prm.includes(String(value)),
    },
    ...columns(t),
  ];

  const hasEnoughDataToShowPagination =
    showedData && showedData?.length > MAX_PAGINATION_PAGE_SIZE;

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : isSuccess && showedData && showedData?.length > 0 ? (
        <StyledTableWrapper>
          <FollowUpTable
            columns={modifiedColumns}
            dataSource={showedData}
            pagination={
              hasEnoughDataToShowPagination
                ? {
                    ...tablePagination,
                    position: ['bottomCenter'],
                    showSizeChanger: true,
                    onChange: handlePaginationChange,
                    showTotal: (total) =>
                      `${t('total')} ${total} ${t('items')}`,
                  }
                : false
            }
          />
        </StyledTableWrapper>
      ) : (
        <StyledEmptyDataWrapper>
          <Card isEmpty>
            <TextRegular>{t('no_data_yet').toUpperCase()}</TextRegular>
          </Card>
        </StyledEmptyDataWrapper>
      )}
    </>
  );
};

export default ProductionFollowUpTable;
