import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IMember, IPaymentMethod } from 'services/types';

import DetailCard from '../../../../../Profile/components/Card';
import {
  StyledBlockBody,
  StyledBlockHeader,
  StyledBlockTitle,
  StyledBlockWrapper,
} from '../../styles';
import PaymentMethodCard from './PaymentMethod';
import { BillingAddressLabels, BillingInfoLabels } from './constants';

interface BillingDataProps {
  member: IMember | undefined;
  paymentMethod?: IPaymentMethod;
  refetch?: () => void;
}

const BillingData: FC<BillingDataProps> = ({
  member,
  paymentMethod,
  refetch,
}) => {
  const { t } = useTranslation();

  const billingAddress = member?.additionalInfo.billingAddress.street;
  const billingPerson = member?.additionalInfo.billingPerson;
  const paymentMethodAdded = member?.additionalInfo.paymentMethodAdded || false;

  const billingName = `${billingPerson?.firstName} ${billingPerson?.middleName} ${billingPerson?.lastName}`;
  const iban = paymentMethod
    ? `${paymentMethod?.country}**************${paymentMethod?.ibanLast4}`
    : 'N/A';

  return (
    <StyledBlockWrapper>
      <StyledBlockHeader>
        <StyledBlockTitle>{t('billing_data')}</StyledBlockTitle>
      </StyledBlockHeader>
      <StyledBlockBody>
        <DetailCard
          cardWidth="427px"
          labelWidth={'250px'}
          labels={BillingInfoLabels}
          title={t('billing_information').toString()}
          firstValue={billingName}
          secondValue={billingPerson?.email}
          thirdValue={billingPerson?.contactNumber}
        />
        <DetailCard
          cardWidth="427px"
          labelWidth={'250px'}
          labels={BillingAddressLabels}
          title={t('billing_address').toString()}
          firstValue={billingAddress}
          secondValue={''}
          thirdValue={''}
        />
        <PaymentMethodCard
          title={t('payment_method')}
          cardWidth="427px"
          labelWidth={'90px'}
          iban={iban}
          paymentMethodId={paymentMethod?.id}
          paymentMethodAdded={paymentMethodAdded}
          refetch={refetch}
        />
      </StyledBlockBody>
    </StyledBlockWrapper>
  );
};

export default BillingData;
