import { styled } from 'styled-components';

import { COLORS } from 'constants/colors';

export const SummaryDetailsBlock = styled.div`
  padding: 20px 0 0;
  background-color: ${COLORS.BgElevated};
`;

export const DetailCardsRow = styled.div`
  display: flex;
  column-gap: 24px;
  margin-bottom: 24px;
`;

export const DetailCardRow = styled.div`
  display: flex;
  column-gap: 30px;
  margin-bottom: 10px;
`;
