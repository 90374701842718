import { styled } from 'styled-components';

import { EditTwoTone } from '@ant-design/icons';

import { BtnDefault } from 'components/shared/Button';
import { COLORS } from 'constants/colors';

export const StyledBlockWrapper = styled.div`
  box-sizing: border-box;
  background-color: ${COLORS.BgContainer};
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  min-height: 354px;
  box-sizing: border-box;
  margin-bottom: 24px;
`;

export const StyledBlockHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
`;

export const StyledBlockTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
`;

export const StyledContainer = styled.div`
  padding: 24px 0;
  width: 1440px;
  margin: 0 auto;
`;

export const StyledBlockBody = styled.div`
  display: flex;
  column-gap: 25px;
  padding: 16px 24px;
`;

export const StyledButtonRow = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 50px;
`;

export const StlyedProfileSupportText = styled.span`
  color: ${COLORS.TextBase};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`;

export const StyledSupportWrapper = styled.div`
  padding-left: 24px;
`;

export const StyledProfileSupportEmail = styled.a`
  padding-left: 4px;
  color: ${COLORS.Green6};
`;

export const Button = styled(BtnDefault)`
  height: 28px;
`;

export const EditIcon = styled(EditTwoTone).attrs(({ disabled }) => ({
  twoToneColor: disabled ? COLORS.Grey3 : COLORS.Green6,
}))``;
