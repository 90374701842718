import { useSearchParams } from 'react-router-dom';

import { PageHeader, StyledPricesWrapper } from 'components/communities/styles';
import TabsComponent from 'components/shared/Tabs';
import { COLORS } from 'constants/colors';
import { USER_ROLES } from 'constants/types';

import PmoMemberSummaryDetails from './components/SummaryDetails';
import { StyledPMOContainer, StyledPMOName } from './styles';
import { useTabsConfig } from './useTabsConfig';

const MembersDashboard = () => {
  const { tabsConfig, activeTabKey, handleChangeTab } = useTabsConfig();
  const [searchParams] = useSearchParams();
  const { role, name } = Object.fromEntries(searchParams);

  const isPMOMember = role === USER_ROLES.PMO_ADMIN;

  const renderCommunityDashboard = () => [
    <PageHeader background={COLORS.BgLayout}>
      <PmoMemberSummaryDetails />
    </PageHeader>,
    <TabsComponent
      backgroundColor={COLORS.BgContainer}
      activeTabKey={activeTabKey as string}
      onChange={handleChangeTab}
      items={tabsConfig}
    />,
  ];

  const renderPMOProfile = () => (
    <StyledPMOContainer>
      <StyledPMOName>{name}</StyledPMOName>
    </StyledPMOContainer>
  );

  return (
    <StyledPricesWrapper>
      {isPMOMember ? renderPMOProfile() : renderCommunityDashboard()}
    </StyledPricesWrapper>
  );
};

export default MembersDashboard;
