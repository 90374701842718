import { styled } from 'styled-components';

import { COLORS } from 'constants/colors';

export const StyledHeaderWrapper = styled.div`
  width: 100%;
  padding-top: 68px;
  margin-bottom: 38px;
`;

export const StyledHeaderRow = styled.div`
  background-color: ${COLORS.BgContainer};
  width: 100%;
  padding: 36px 0px 1px 0px;
`;

export const StyledPeriodPicker = styled.div`
  display: flex;
  justify-content: end;
  width: 1377px;
  margin: 0 auto;
`;

export const StyledContainer = styled.div`
  width: 1377px;
  margin: 0 auto;
`;

export const StyledTabWrapper = styled.div`
  background-color: ${COLORS.BgLayout};
  width: 100%;
  padding: 38px 0px 20px 0px;
`;

export const StyledFinTableHeader = styled.div`
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
`;
