import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { MenuProps } from 'antd';
import { Button } from 'antd';

import { CustomSelectItem } from 'constants/types';

import { ArrowDownIcon, ArrowUpIcon, StyledSelect } from './styles';

export interface CustomSelectProps {
  items: CustomSelectItem[];
  onChange?: (item: CustomSelectItem) => void;
  value: CustomSelectItem;
  width: number;
  withNoBorder?: boolean;
}

const CustomSelect = ({
  items,
  value,
  width,
  onChange,
  withNoBorder,
}: CustomSelectProps) => {
  const { t } = useTranslation();
  const selectRef = useRef<HTMLDivElement>(null);

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible((prev) => !prev);
  };

  const handleMenuClick: MenuProps['onClick'] = (event) => {
    onChange?.({
      label: (event.domEvent.target as HTMLElement).textContent,
      key: event.key,
    });
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const handleDocumentClick = (event: MouseEvent) => {
    const target = event.target as Node;
    if (selectRef.current && !selectRef.current.contains(target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  return (
    <div ref={selectRef}>
      <StyledSelect
        trigger={['click']}
        className={'dropdown-class' + value.key}
        width={width}
        withNoBorder={withNoBorder}
        open={dropdownVisible}
        menu={menuProps}
      >
        <Button onClick={toggleDropdown}>
          {t(value.label || '')}
          {dropdownVisible ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </Button>
      </StyledSelect>
    </div>
  );
};

export default CustomSelect;
