import styled from 'styled-components';

import { Input as AntInput } from 'antd';

import { COLORS } from 'constants/colors';

export const FormInput = styled(AntInput)<{ isError?: boolean }>`
  height: 40px;
  border-color: ${(props) => props.isError && COLORS.ErrorBase};
`;
