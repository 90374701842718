import { styled } from 'styled-components';

import { CalendarTwoTone, LineChartOutlined } from '@ant-design/icons';

import { COLORS } from 'constants/colors';

export const customTooltipStyles = { display: 'inline-block', height: '16px' };

export const StyledCommunityTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  padding-bottom: 6px;
  display: flex;
  align-items: center;
  column-gap: 5px;
  color: ${COLORS.TextSecondary};
`;

export const StyledCalendarIcon = styled(CalendarTwoTone).attrs(() => ({
  twoToneColor: COLORS.Green6,
}))`
  margin-right: 6px;
`;

export const StyledIndexIcon = styled(LineChartOutlined)`
  color: ${COLORS.Green6};
  margin-right: 6px;
`;

export const StyledPriceCol = styled.div<{ width?: string }>`
  width: ${(props) => props.width};
`;

export const StyledIndexCol = styled(StyledPriceCol)`
  display: flex;
  flex-direction: column;
  justify-content: end;
  font-weight: 600;
  font-size: 12px;
`;

export const StyledPriceContainer = styled.div`
  margin: 0 14px;
`;

export const StyledPriceTypeText = styled.span`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
  display: inline-block;
`;

export const StyledPriceUnitText = styled.span`
  font-size: 12px;
  font-weight: 400;
`;

export const StyledPriceValue = styled.div`
  font-size: 14px;
  font-weight: 600;
  background: ${COLORS.BgContainer};
  padding: 8px 12px;
`;

export const StyledPricesContainer = styled.div`
  background-color: #dff4e6;
  position: relative;
  display: flex;
  padding: 16px;
  flex-direction: column;
  width: 100%;
  &:before {
    content: '';
    position: absolute;
    top: -16px;
    left: 0;
    display: block;
    height: 1px;
    width: 100%;
    background: ${COLORS.ColorSplit};
  }
`;

export const StyledPricesTitleContainer = styled.div`
  display: flex;
`;

export const StyledPricesTitle = styled.span`
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${({ color }) => color || COLORS.TextSecondary};
  display: inline-block;
`;
