import { getAppRole } from 'utils';

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { WidgetTitle } from 'components/communities/Charts/ConsumerSummaryView/styles';
import { formatNumberWithSpace } from 'components/communities/Charts/ElectricityConsumption/utils';
import CostSavingSvg from 'components/communities/Charts/img/CostSaving.svg';
import { popoverOverlayStyles } from 'components/communities/Communities/styles';
import Popover from 'components/shared/Popover';
import { SkeletonLine } from 'components/shared/Skeleton';
import { USER_ROLES } from 'constants/types';

import {
  WidgetAmount,
  WidgetAmountType,
  WidgetColumn,
  WidgetImage,
  WidgetImageWrapper,
  WidgetSkeletonRow,
  WidgetWrapper,
} from '../../styles';

const WidgetEarnings = ({
  amount,
  isLoading,
}: {
  amount?: string | number;
  isLoading: boolean;
}) => {
  const { t } = useTranslation();
  const userRole = getAppRole();

  const isPMO = useMemo(() => userRole === USER_ROLES.PMO_ADMIN, [userRole]);

  return (
    <WidgetWrapper>
      <WidgetColumn>
        <WidgetImageWrapper>
          <WidgetImage src={CostSavingSvg} />
        </WidgetImageWrapper>
        {isLoading ? (
          <WidgetSkeletonRow>
            <SkeletonLine active size="small" />
            <SkeletonLine active height={30} />
            <SkeletonLine active size="small" />
          </WidgetSkeletonRow>
        ) : (
          <>
            <WidgetTitle>
              {t('earnings')}
              <Popover
                context={t(
                  isPMO
                    ? 'sum_of_consumers_invoices'
                    : 'the_sum_of_consumer_and_producers_invoices',
                )}
                overlayStyles={popoverOverlayStyles}
              />
            </WidgetTitle>
            <WidgetAmount>
              {amount ? formatNumberWithSpace(amount) : 'N/A'}
              <WidgetAmountType>€</WidgetAmountType>
            </WidgetAmount>
          </>
        )}
      </WidgetColumn>
    </WidgetWrapper>
  );
};

export default WidgetEarnings;
