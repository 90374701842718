import { getAppRole } from 'utils';

import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import { Input, Table } from 'antd';
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
} from 'antd/lib/table/interface';

import { SearchOutlined } from '@ant-design/icons';

import Loader from 'components/shared/Loader';
import { MarginTop68px } from 'components/shared/Margin';
import { SubHeader } from 'components/shared/Text';
import { ROLES } from 'constants/global';
import { Member, USER_ROLES } from 'constants/types';
import { useGetMembersQuery } from 'services/membersManagement';

import { useDebounce } from 'utils/hooks';

import { columns } from './columns';
import {
  StyledEmptyDataWrapper,
  StyledInputWrapper,
  TableWrapper,
} from './styles';

const MembersTable: React.FC = () => {
  const { t } = useTranslation();

  const { data: members = [], isLoading } = useGetMembersQuery();
  const role = getAppRole();

  const [showedData, setShowedData] = useState(() => members);
  const [filteredData, setFilteredData] = useState<Member[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    },
  );

  const debouncedSearchValue = useDebounce(searchValue, 1000);

  const filteredMembers = useMemo(() => {
    let result = members;

    if (debouncedSearchValue) {
      result = result.filter((member) => {
        const fullName = member.middleName
          ? `${member.firstName} ${member.middleName} ${member.lastName}`
          : `${member.firstName} ${member.lastName}`;
        const address = member.address?.street;
        return Object.values(member).some(
          (memberValue) =>
            String(memberValue)
              ?.toLowerCase()
              .includes(debouncedSearchValue?.toLowerCase()) ||
            String(fullName)
              ?.toLowerCase()
              .includes(debouncedSearchValue?.toLowerCase()) ||
            String(address)
              ?.toLowerCase()
              .includes(debouncedSearchValue?.toLowerCase()),
        );
      });
    }

    return result;
  }, [debouncedSearchValue, members]);

  useEffect(() => {
    setShowedData((prevMembers) => {
      if (isLoading) return prevMembers;

      const sortedMembers = [...filteredMembers].sort((a, b) =>
        a.firstName?.toLowerCase() > b.firstName?.toLowerCase() ? 1 : -1,
      );

      return shallowEqual(sortedMembers, prevMembers)
        ? prevMembers
        : sortedMembers;
    });
  }, [isLoading, filteredMembers]);

  useEffect(() => {
    if (role === USER_ROLES.PMO_ADMIN) {
      setShowedData((prev) =>
        prev.filter((member) => member.role !== USER_ROLES.PMO_ADMIN),
      );
    }
  }, [isLoading, role]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Member> | SorterResult<Member>[],
    extra: TableCurrentDataSource<Member>,
  ) => {
    const currentData = extra.currentDataSource;
    setFilteredData(currentData);
  };

  const displayPagination = useMemo(() => {
    return (
      showedData &&
      showedData.length > 10 &&
      (filteredData.length > 10 || filteredData.length === 0)
    );
  }, [filteredData, showedData]);

  const handlePaginationChange = (page: number, pageSize: number) => {
    setTablePagination((prev) => ({ ...prev, current: page, pageSize }));
  };

  const isDisabledInput = members && members?.length > 0 ? false : true;

  const tableColumns = useMemo(() => {
    // show community column only for OYO ADMIN
    return role === ROLES.OYO_ADMIN
      ? columns(t)
      : columns(t).filter(
          ({ dataIndex }: any) => dataIndex !== 'communityName',
        );
  }, [columns, role]);

  return (
    <TableWrapper>
      <StyledInputWrapper>
        <Input
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder={t('search').toString()}
          prefix={<SearchOutlined style={{ opacity: 0.3 }} />}
          disabled={isDisabledInput}
        />
      </StyledInputWrapper>
      {isLoading && (
        <>
          <MarginTop68px />
          <Loader />
        </>
      )}
      {!showedData.length && !isLoading && (
        <StyledEmptyDataWrapper>
          <SubHeader>{t('no_members_yet').toUpperCase()}</SubHeader>
        </StyledEmptyDataWrapper>
      )}
      {showedData.length > 0 && (
        <Table
          columns={tableColumns}
          rowKey={(record) =>
            record._id +
            record.address?.city +
            record.firstName +
            record.lastName
          }
          dataSource={showedData}
          onChange={handleTableChange}
          pagination={
            displayPagination
              ? {
                  ...tablePagination,
                  position: ['bottomCenter'],
                  showSizeChanger: true,
                  onChange: handlePaginationChange,
                  showTotal: (total) => `${t('total')} ${total} ${t('items')}`,
                }
              : false
          }
          loading={isLoading}
        />
      )}
    </TableWrapper>
  );
};

export default MembersTable;
