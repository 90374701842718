import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

import { ROLES } from 'constants/global';
import AppRoutes from 'constants/routes';

import { isValidRole } from 'utils/index';

interface IUnprotectedRoute {
  children: ReactElement;
}
const UnprotectedRoute = ({ children }: IUnprotectedRoute) => {
  const isOyoAdmin = isValidRole(ROLES.OYO_ADMIN);
  const isPMO = isValidRole(ROLES.PMO);
  const isConsumer = isValidRole(ROLES.CONSUMER);

  switch (true) {
    case isOyoAdmin:
    case isPMO:
    case isConsumer: {
      return <Navigate to={AppRoutes.Root} replace />;
    }

    default: {
      return children;
    }
  }
};

export default UnprotectedRoute;
