import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormikContext } from 'formik';

import { ThunderboltIcon } from 'components/communities/Charts/SummaryView/components/Title/styles';
import { Prices } from 'components/members/types';
import { TextRegular } from 'components/shared/Text';
import CustomTooltip from 'components/shared/Tooltip';

import { initialValues } from '../../constants';
import {
  EditIcon,
  StyledEmptyGridTitle,
  StyledGridBlock,
  StyledGridHeader,
  StyledGridSubTitle,
  StyledGridTitle,
  StyledPriceCol,
  StyledPriceContainer,
  StyledPriceTypeText,
  StyledPriceUnitText,
  StyledPriceValue,
  StyledPricesContainer,
  StyledPrmEditButton,
} from './styles';

interface ElectricityGridPricesProps {
  index: number;
  priceType: Prices | null;
}

const emptySymbol = '-';

const ElectricityGridPrices: FC<ElectricityGridPricesProps> = ({
  index,
  priceType,
}) => {
  const { t } = useTranslation();
  const { values } = useFormikContext<typeof initialValues>();

  const hasData = useMemo(() => !!index && !!priceType, [index, priceType]);

  const renderStaticGrid = useCallback(() => {
    return (
      <StyledPriceCol width="150px">
        <StyledPriceContainer>
          <div>
            <StyledPriceUnitText>{t('eur_kwh')}</StyledPriceUnitText>
          </div>
          <StyledPriceValue>
            {values.prms[index].gridPrice?.static}
          </StyledPriceValue>
        </StyledPriceContainer>
      </StyledPriceCol>
    );
  }, [values, index]);

  const renderDynamicGrid = useCallback(() => {
    return [
      <StyledPriceCol width="150px">
        <StyledPriceContainer>
          <div>
            <StyledPriceTypeText>{t('hshp')}</StyledPriceTypeText>
            <StyledPriceUnitText>, {t('eur_kwh')}</StyledPriceUnitText>
          </div>
          <StyledPriceValue>
            {values.prms[index].gridPrice?.dynamic?.HS_HH}
          </StyledPriceValue>
        </StyledPriceContainer>
      </StyledPriceCol>,
      <StyledPriceCol width="150px">
        <StyledPriceContainer>
          <div>
            <StyledPriceTypeText>{t('hshc')}</StyledPriceTypeText>
            <StyledPriceUnitText>, {t('eur_kwh')}</StyledPriceUnitText>
          </div>
          <StyledPriceValue>
            {values.prms[index].gridPrice?.dynamic?.HS_LH}
          </StyledPriceValue>
        </StyledPriceContainer>
      </StyledPriceCol>,
      <StyledPriceCol width="150px">
        <StyledPriceContainer>
          <div>
            <StyledPriceTypeText>{t('bshp')}</StyledPriceTypeText>
            <StyledPriceUnitText>, {t('eur_kwh')}</StyledPriceUnitText>
          </div>
          <StyledPriceValue>
            {values.prms[index].gridPrice?.dynamic?.LS_HH}
          </StyledPriceValue>
        </StyledPriceContainer>
      </StyledPriceCol>,
      <StyledPriceCol width="150px">
        <StyledPriceContainer>
          <div>
            <StyledPriceTypeText>{t('bshc')}</StyledPriceTypeText>
            <StyledPriceUnitText>, {t('eur_kwh')}</StyledPriceUnitText>
          </div>
          <StyledPriceValue>
            {values.prms[index].gridPrice?.dynamic?.LS_LH}
          </StyledPriceValue>
        </StyledPriceContainer>
      </StyledPriceCol>,
      <StyledPriceCol width="150px">
        <StyledPriceContainer>
          <div>
            <StyledPriceTypeText>{t('hdp')}</StyledPriceTypeText>
            <StyledPriceUnitText>, {t('eur_kwh')}</StyledPriceUnitText>
          </div>
          <StyledPriceValue>
            {values.prms[index].gridPrice?.dynamic?.RH}
          </StyledPriceValue>
        </StyledPriceContainer>
      </StyledPriceCol>,
    ];
  }, [values, index]);

  return (
    <StyledGridBlock>
      <StyledGridHeader>
        <StyledGridTitle>
          {t('electricity_price_of_the_grid')}
          <CustomTooltip
            tooltip={
              <TextRegular>{t('by_providing_this_information')}</TextRegular>
            }
          />
        </StyledGridTitle>
        <StyledGridSubTitle>
          {hasData ? <ThunderboltIcon /> : null}
          {priceType === Prices.dynamic && t('dynamic_price')}
          {priceType === Prices.static && t('static_price')}
        </StyledGridSubTitle>
      </StyledGridHeader>
      <StyledPricesContainer hasData={hasData}>
        {priceType === Prices.static && renderStaticGrid()}
        {priceType === Prices.dynamic && renderDynamicGrid()}
        {!hasData && (
          <StyledEmptyGridTitle>{t('add_grid_prices')}</StyledEmptyGridTitle>
        )}
        <StyledPriceCol>
          <StyledPrmEditButton>
            <EditIcon /> {t('update')}
          </StyledPrmEditButton>
        </StyledPriceCol>
      </StyledPricesContainer>
    </StyledGridBlock>
  );
};

export default ElectricityGridPrices;
