import React from 'react';
import { useTranslation } from 'react-i18next';

import CommunityCard from 'components/communities/Communities/components/CommunityCard';
import {
  CardBody,
  CardHeader,
  CardTitle,
  CardsContainer,
  MapContainer,
  MapWrapper,
} from 'components/communities/Communities/styles';
import MapboxMap, { isValidCoordinate } from 'components/shared/MapboxMap';
import { MAP_HEIGHT } from 'constants/global';
import { Community } from 'constants/types';

interface ICommunitiesWithMap {
  communities: Community[];
}

const getCommunitiesCoordinates = (communities: Community[]) => {
  return communities
    .filter((community) => {
      const [longitude, latitude] = community.coordinates;

      return isValidCoordinate(longitude) && isValidCoordinate(latitude);
    })
    .map((community) => {
      return {
        id: community.id,
        latitude: community.coordinates[0],
        longitude: community.coordinates[1],
        title: community.communityName,
        location: community.location,
      };
    });
};

const CommunitiesWithMap = ({ communities }: ICommunitiesWithMap) => {
  const { t } = useTranslation();

  return (
    <>
      <CardsContainer>
        {communities?.map((community) => (
          <CommunityCard key={community.id} community={community} />
        ))}
      </CardsContainer>
      <MapWrapper>
        <MapContainer>
          <CardHeader>
            <CardTitle>{t('communities_map_title')}</CardTitle>
          </CardHeader>
          <CardBody>
            <MapboxMap
              height={MAP_HEIGHT}
              communitiesCoordinates={getCommunitiesCoordinates(communities)}
            />
          </CardBody>
        </MapContainer>
      </MapWrapper>
    </>
  );
};

export default CommunitiesWithMap;
