import { styled } from 'styled-components';

import { TextRegular } from 'components/shared/Text';
import { COLORS } from 'constants/colors';

export const StyledReportWrapper = styled.div`
  box-sizing: border-box;
  background-color: ${COLORS.ColorFillTertiary};
  padding: 25px 27px;
  display: flex;
  align-items: center;
  justify-content: end;
  column-gap: 32px;
  margin-top: 40px;
`;

export const StyledReportButton = styled.div`
  width: 262px;
`;

export const StyledReportSelectRow = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
`;

export const StyledLabel = styled(TextRegular)`
  width: 140px;
  text-align: right;

  &::first-letter {
    text-transform: uppercase;
  }
`;
