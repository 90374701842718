import { camelCaseTo18nKey, uppercaseWord } from 'utils';

import { Link } from 'react-router-dom';

import { Button, Space, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { EyeTwoTone } from '@ant-design/icons';

import { TFunction } from 'i18next';

import { MEMBER_STATUS, Member } from 'constants/types';

import i18n from 'utils/i18n';

import SelfProductionRatio from './SelfProductionRatio/SelfProductionRatio';
import { statusColor } from './constants';
import { TableHeader } from './styles';

export const columns: (t: TFunction) => ColumnsType<Member> = (t) => [
  {
    title: <TableHeader>{uppercaseWord(i18n.t('member_name'))}</TableHeader>,
    render: (name) => `${name.firstName} ${name.middleName} ${name.lastName}`,
    sorter: (firstMember, nextMember) =>
      firstMember.firstName.localeCompare(nextMember.firstName),
    width: '15%',
  },
  {
    title: <TableHeader>{uppercaseWord(i18n.t('member_role'))}</TableHeader>,
    dataIndex: 'role',
    render: (role) => t(role?.toLowerCase()),
    filters: [
      { text: 'PMO', value: 'PMO' },
      { text: t('admin'), value: 'OYO_Admin' },
      { text: t('consumer'), value: 'Consumer' },
      { text: t('producer'), value: 'Producer' },
    ],
    onFilter: (value, record) => record.role?.includes(String(value)),
    width: '5%',
  },

  {
    title: <TableHeader>{uppercaseWord(i18n.t('address'))}</TableHeader>,
    render: (record) => record.address?.street,
    sorter: (firstMember, nextMember) =>
      firstMember.address?.street.localeCompare(nextMember.address?.street),
    width: '15%',
  },

  {
    title: <TableHeader>{uppercaseWord(i18n.t('community'))}</TableHeader>,
    dataIndex: 'communityName',
    width: '15%',
    sorter: (firstMember, nextMember) =>
      firstMember.communityName.localeCompare(nextMember.communityName),
  },
  {
    title: (
      <TableHeader>
        {uppercaseWord(i18n.t('self_production_ratio_ttm'))}
      </TableHeader>
    ),
    dataIndex: 'selfProductionRatio',
    render: (_?: number, user?: Member) => (
      <SelfProductionRatio userId={user?._id} role={user?.role} />
    ),
    width: '15%',
  },
  {
    title: <TableHeader>{uppercaseWord(i18n.t('status'))}</TableHeader>,
    sorter: (firstMember, nextMember) => {
      const order = { Active: 0, Pending: 1, Conflict: 2, Disabled: 3 };
      return order[firstMember.status] - order[nextMember.status];
    },
    dataIndex: 'status',
    width: '10%',
    render: (tag: keyof typeof MEMBER_STATUS) => {
      return (
        <Tag color={statusColor[tag]}>
          {t(camelCaseTo18nKey(MEMBER_STATUS?.[tag] || '', t))}
        </Tag>
      );
    },
  },
  {
    title: <TableHeader>{uppercaseWord(i18n.t('payment_status'))}</TableHeader>,
    dataIndex: 'payment',
    width: '10%',
    render: (payment) => t(payment?.toLowerCase()),
    sorter: (firstMember, nextMember) => {
      const order = { PAID: 0 };
      return order[firstMember.payment] - order[nextMember.payment];
    },
  },
  {
    title: <TableHeader>{uppercaseWord(i18n.t('action'))}</TableHeader>,
    width: '10%',
    render: (member) => (
      <Space>
        <Link
          to={`/members/${member._id}?role=${member.role}&community=${
            member.communityName
          }&name=${member.firstName} ${member.middleName} ${member.lastName}${
            member?.communityId ? `&communityId=${member?.communityId}` : ''
          }`}
        >
          <Button size="small" icon={<EyeTwoTone />} />
        </Link>
      </Space>
    ),
  },
];
