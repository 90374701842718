import { getAppRole } from 'utils';

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { formatNumberWithSpace } from 'components/communities/Charts/ElectricityConsumption/utils';
import CostSavedSvg from 'components/communities/Charts/img/CostSaving.svg';
import { WidgetWrapper } from 'components/communities/styles';
import Popover from 'components/shared/Popover';
import { SkeletonLine } from 'components/shared/Skeleton';
import { SUPPORTED_LANGUAGES } from 'constants/global';
import AppRoutes from 'constants/routes';
import { USER_ROLES } from 'constants/types';

import {
  StyledGreenSubtitle,
  WidgetBlock,
  WidgetCostAmount,
  WidgetHeaderRow,
  WidgetRow,
  WidgetSkeletonRow,
  WidgetSubtitle,
  WidgetTitle,
} from '../../styles';
import { WidgetCostSavedImage, WidgetEuro } from './styles';

interface WidgetCostSavedProps {
  costSavedAmount?: number;
  isLoading: boolean;
}

const DEFAULT_ACTUAL_COST_SAVED = 'N/A';

const WidgetCostSaved: FC<WidgetCostSavedProps> = ({
  costSavedAmount = null,
  isLoading,
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const role = getAppRole();
  const amount = costSavedAmount ?? DEFAULT_ACTUAL_COST_SAVED;

  const handleNavigateToSettingsTab = () => {
    if (role === USER_ROLES.ADMIN || role === USER_ROLES.PMO_ADMIN) {
      const fullPath = `${location.pathname}${location.search}&tab=Settings`;
      return navigate(fullPath);
    }
    navigate(AppRoutes.Profile + '?tab=Settings');
  };

  const subtitle = costSavedAmount && t('comparing_to_grid_prices');

  const fontSizeByLanguage =
    i18n.language === SUPPORTED_LANGUAGES.Français ? '14px' : '16px';

  return (
    <WidgetWrapper>
      <WidgetHeaderRow>
        <WidgetRow>
          <WidgetTitle>
            {t('cost_saved')}
            <Popover
              context={t('the_sum_of_consumer_and_producers_invoices')}
            />
          </WidgetTitle>
        </WidgetRow>
      </WidgetHeaderRow>
      <WidgetRow>
        <WidgetCostSavedImage src={CostSavedSvg} />
        <WidgetBlock>
          <WidgetSubtitle fontSize={fontSizeByLanguage}>
            {subtitle}
          </WidgetSubtitle>
          {isLoading ? (
            <WidgetSkeletonRow>
              <SkeletonLine active size="small" />
              <SkeletonLine active height={30} />
              <SkeletonLine active size="small" />
            </WidgetSkeletonRow>
          ) : (
            <>
              <WidgetCostAmount>
                {formatNumberWithSpace(amount)}
                <WidgetEuro>€</WidgetEuro>
              </WidgetCostAmount>
            </>
          )}
        </WidgetBlock>
      </WidgetRow>
    </WidgetWrapper>
  );
};

export default WidgetCostSaved;
