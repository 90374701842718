export enum COLORS {
  // BG Colors
  BgLayout = '#F5F5F5',
  BgContainer = '#FFFFFF',
  BgContainerDisabled = '#D9D9D9',

  BgElevated = '#FFFFFF',
  Border = '#F0F0F0',
  EmptyState = '#E2E2E2',

  // Green Colors
  Green1 = '#DCFCE7',
  Green2 = '#BBF7D0',
  Green3 = '#86EFAC',
  Green4 = '#4ADE80',
  Green5 = '#22C55E',
  Green6 = '#16A34A',
  Green7 = '#15803D',
  Green8 = '#166534',
  Green9 = '#14532D',
  Green10 = '#092916',

  //Grey Colors
  Grey1 = '#BFBFBF',
  Grey2 = '#EDEDF0',
  Grey3 = '#B2B2B4',
  Grey4 = '#565656',
  Grey5 = '#1E1E1E',
  Grey6 = '#E6E6E6',
  Grey7 = '#D8D8D8',

  TextBase = '#000000',
  TextSecondary = '#4D5851',
  TextDescription = '#00000073',
  TextTertiary = '#8c8c8c',
  ColorFillTertiary = '#00000008',
  ColorSplit = '#AEAEAE',

  // Orange Colors
  Orange1 = '#FFEDD5',
  Orange2 = '#FED7AA',
  Orange3 = '#FDBA74',
  Orange4 = '#FB923C',
  Orange5 = '#F97316',
  Orange6 = '#EA580C',
  Orange7 = '#C2400C',

  // Yellow Colors
  Yellow1 = '#FEF9C3',
  Yellow2 = '#FEF08A',
  Yellow4 = '#FACC15',
  Yellow5 = '#EAB308',
  Yellow6 = '#CA8A04',
  Yellow7 = '#A16207',
  Gold5 = '#F59E0B',
  Gold6 = '#D97706',
  Gold7 = '#B45309',

  //Cyan
  Cyan7 = '#0E7490',

  //Blue
  Blue5 = '#0EA5E9',
  Blue6 = '#0284C7',
  geekBlue5 = '#3B82F6',
  geekBlue6 = '#2563EB',

  ErrorBase = '#EF4444',
  ErrorBorder = '#F87171',

  SuccessBase = '#52c41a',

  PrimaryBase = '#1677FF',
  InputBorder = '#4096ff',
}
