import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import CreateReportWithSelectCheckboxes from 'components/communities/components/CreateReportWithSelectCheckboxes';
import {
  Container,
  HeaderContainer,
  HeaderRow,
  HeaderRowItem,
  PageHeader,
  Wrapper,
} from 'components/communities/styles';
import PeriodPicker from 'components/shared/PeriodPicker';
import TabsComponent from 'components/shared/Tabs';
import { Header, TextRegular } from 'components/shared/Text';
import { COLORS } from 'constants/colors';
import { API_URLS } from 'constants/global';
import { USER_ROLES } from 'constants/types';
import { useGetConsumersQuery } from 'services/communityManagement';

import { capitalizeAllWords, getAppRole } from 'utils/index';

import EnergyDataChart from './components/EnergyDataChart';
import { StyledTabs } from './styles';
import { useTabsConfig } from './useTabsConfig';

const URLS = {
  total_production: API_URLS.GET_TOTAL_PRODUCTION_REPORT,
  self_consumption: API_URLS.GET_CONSUMERS_CSV_ENERGY_REPORTS,
};

const ProducerEnergyData = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const tabsConfig = useTabsConfig();
  const { data } = useGetConsumersQuery({ userId: id });
  const role = getAppRole();

  const consumers = useMemo(() => {
    const consumersIds = new Set();
    const newConsumers: any[] = [];

    data?.consumers.forEach((consumer: any) => {
      if (consumersIds.has(consumer.user?._id)) return;

      consumersIds.add(consumer.user?._id);
      newConsumers.push({
        label: `${consumer.user?.firstName} ${consumer.user?.lastName}`,
        key: consumer.user?._id,
      });
    });
    return newConsumers;
  }, [data?.consumers]);

  const isPmoAdminOrOyoAdmin =
    role === USER_ROLES.PMO_ADMIN || role === USER_ROLES.ADMIN;

  return (
    <Wrapper paddingTop={isPmoAdminOrOyoAdmin ? '0' : '64px'}>
      {!isPmoAdminOrOyoAdmin && (
        <PageHeader background={COLORS.BgContainer}>
          <HeaderContainer>
            <HeaderRow>
              <HeaderRowItem>
                <Header>{capitalizeAllWords(t('energy_data'))}</Header>
              </HeaderRowItem>
              <HeaderRowItem position="flex-end">
                <TextRegular>{t('show_data_for')}</TextRegular>
                <PeriodPicker />
              </HeaderRowItem>
            </HeaderRow>
          </HeaderContainer>
        </PageHeader>
      )}
      <Container>
        <EnergyDataChart />
        <CreateReportWithSelectCheckboxes
          datePickerType="date"
          consumers={consumers}
          apiUrls={URLS}
        />
        <HeaderRow>
          <HeaderRowItem>
            <Header>{capitalizeAllWords(t('energy_follow_up'))}</Header>
          </HeaderRowItem>
        </HeaderRow>
        <StyledTabs>
          <TabsComponent backgroundColor={COLORS.BgLayout} items={tabsConfig} />
        </StyledTabs>
      </Container>
    </Wrapper>
  );
};

export default ProducerEnergyData;
