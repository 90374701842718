import React, { FC } from 'react';

import { LegendText } from 'components/shared/Text/LegendText';
import { EnergyTypes } from 'constants/energy-types';

import { ChartDataType, InfoItemsType } from '../types';
import {
  SelfConsumptionBlock,
  SelfConsumptionItem,
  SelfConsumptionItemAmount,
  SelfConsumptionItemPercent,
  SelfConsumptionItemTitle,
  SelfConsumptionItems,
  SelfConsumptionLegendItem,
} from './styles';

interface SelfConsumptionInfoProps {
  data?: ChartDataType[];
  infoItems: InfoItemsType;
  hoveredType?: EnergyTypes;
}

interface SelfConsumptionInfoItemProps {
  type?: string;
  color: string;
  title: string;
  percent?: number | string | null;
  amount?: number;
  hoveredType?: EnergyTypes;
}

const SelfConsumptionInfoItem: FC<SelfConsumptionInfoItemProps> = ({
  type,
  color,
  title,
  percent,
  amount,
  hoveredType,
}) => {
  return percent === null || percent ? (
    <SelfConsumptionItem highlighted={hoveredType === type}>
      <SelfConsumptionItemTitle>
        <SelfConsumptionLegendItem color={color}> </SelfConsumptionLegendItem>
        <LegendText bold={hoveredType === type}>{title}</LegendText>
        {amount ? ` (${amount})` : ''}
      </SelfConsumptionItemTitle>
      <SelfConsumptionItemAmount>
        {percent !== null ? percent : 'N/A'}
        <SelfConsumptionItemPercent> %</SelfConsumptionItemPercent>
      </SelfConsumptionItemAmount>
    </SelfConsumptionItem>
  ) : (
    <></>
  );
};

const SelfConsumptionInfo: FC<SelfConsumptionInfoProps> = ({
  hoveredType,
  data,
  infoItems,
}) => {
  return (
    <SelfConsumptionBlock>
      <SelfConsumptionItems>
        {data?.map((item) => (
          <SelfConsumptionInfoItem
            key={item.type}
            type={item.type}
            color={infoItems[item?.type || '']?.color}
            title={infoItems[item?.type || '']?.title}
            percent={item?.value}
            amount={item?.amount}
            hoveredType={hoveredType}
          />
        ))}
      </SelfConsumptionItems>
    </SelfConsumptionBlock>
  );
};

export default SelfConsumptionInfo;
