import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { COLORS } from 'constants/colors';

export const StyledWrapper = styled.div`
  position: absolute;
  left: 32px;
  bottom: -32px;
`;

export const StyledLink = styled(Link)<{ active: boolean }>`
  color: ${(props) =>
    props.active ? COLORS.TextBase : COLORS.TextDescription} !important;
  pointer-events: ${(props) => (props.active ? 'none' : 'auto')} !important;
`;
