import { useTranslation } from 'react-i18next';

import { NotAvailableValue } from 'components/communities/styles';

import { StyledKw } from '../../../ElectricityConsumption/components/AreaChart/styles';
import {
  RepartitionSubtitle,
  RingProggresPercent,
  RingProgressRow,
  RingProgressTitle,
  RingProgressWrapper,
} from '../../styles';
import RingProggresChart from './RingProgressChart';

const RingProgressInfo = ({ percent }: { percent: number | undefined }) => {
  const { t } = useTranslation();
  return (
    <RingProgressWrapper>
      <RingProgressTitle>{t('average_repartition_key')}</RingProgressTitle>
      <RepartitionSubtitle>{t('for_selected_time_period')}</RepartitionSubtitle>
      <RingProgressRow>
        <RingProggresChart percent={percent ? percent / 100 : 0} />
        {percent ? (
          <RingProggresPercent>
            {percent}
            <StyledKw>%</StyledKw>
          </RingProggresPercent>
        ) : (
          <NotAvailableValue> N/A </NotAvailableValue>
        )}
      </RingProgressRow>
    </RingProgressWrapper>
  );
};

export default RingProgressInfo;
