import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Container,
  HeaderContainer,
  HeaderRow,
  HeaderRowItem,
  PageHeader,
  Wrapper,
} from 'components/communities/styles';
import { StripeMessageTypes } from 'components/communities/types';
import ModalMessage from 'components/shared/ModalMessage';
import PeriodPicker from 'components/shared/PeriodPicker';
import { Header, TextRegular } from 'components/shared/Text';
import { COLORS } from 'constants/colors';
import { CHART_TYPE } from 'constants/global';
import AppRoutes from 'constants/routes';

import { capitalizeAllWords } from 'utils/index';

import ConsumerCreateReport from '../../../FinancialData/components/CreateReport/ConsumerCreateReport';
import FinancialFollowUpChartWrapper from './components/FinancialFollowUpChartWrapper';
import InvoiceHistory from './components/InvoiceHistoryTable';
import { getCsvFinancialReport } from './utils';

const useUrlQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ConsumerFinancialData = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const query = useUrlQuery();
  const payment = query.get('payment');
  const isHeaderVisible = !pathname.includes(AppRoutes.Members);

  return (
    <Wrapper paddingTop={isHeaderVisible ? '64px' : '0'}>
      {isHeaderVisible && (
        <PageHeader background={COLORS.BgContainer}>
          <HeaderContainer>
            <HeaderRow>
              <HeaderRowItem>
                <Header>{capitalizeAllWords(t('financial_data'))}</Header>
              </HeaderRowItem>
              <HeaderRowItem position="flex-end">
                <TextRegular>{t('show_data_for')}</TextRegular>
                <PeriodPicker chartType={CHART_TYPE.FINANCIAL_DATA} />
              </HeaderRowItem>
            </HeaderRow>
          </HeaderContainer>
        </PageHeader>
      )}
      <Container>
        <FinancialFollowUpChartWrapper />
        <ConsumerCreateReport
          datePickerType="month"
          fetchCsvFile={getCsvFinancialReport}
        />
        <HeaderRow>
          <HeaderRowItem>
            <Header>{capitalizeAllWords(t('invoice_history'))}</Header>
          </HeaderRowItem>
        </HeaderRow>
        <InvoiceHistory />
      </Container>
      {payment === StripeMessageTypes.Success && (
        <ModalMessage
          messageType={StripeMessageTypes.Success}
          title={t('payment_success')}
          subTitle={t('consumer_payment_success_message')}
          btnText="Ok"
          handleBtn={() => navigate(AppRoutes.FinancialData)}
        />
      )}
      {payment === StripeMessageTypes.Cancel && (
        <ModalMessage
          messageType={StripeMessageTypes.Cancel}
          title={t('payment_failed')}
          subTitle={t('consumer_payment_failed_message')}
          btnText="Ok"
          handleBtn={() => navigate(AppRoutes.FinancialData)}
        />
      )}
    </Wrapper>
  );
};

export default ConsumerFinancialData;
