import { isValidRole } from 'utils';

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import CommunitiesWithMap from 'components/communities/Communities/components/CommunitiesWithMap';
import PlusOutlines from 'components/communities/Communities/img/PlusOutlined.svg';
import { BtnPrimary, BtnSecondary } from 'components/shared/Button';
import Image from 'components/shared/Image';
import { Header, TextRegular } from 'components/shared/Text';
import { ROLES } from 'constants/global';
import AppRoutes from 'constants/routes';
import { useGetCommunitiesQuery } from 'services/communityManagement';

import { BtnWrapper, Card, Container, HeaderWrapper } from './styles';

const Communities: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isOyoAdmin = isValidRole(ROLES.OYO_ADMIN);

  const {
    data: communities = [],
    isSuccess,
    isLoading,
  } = useGetCommunitiesQuery();

  const hasCommunities = isSuccess && communities.length > 0;
  const hasNoCommunities = isSuccess && communities.length === 0;

  const renderCommunities = () => {
    if (isLoading) {
      return false;
    }

    if (hasCommunities) {
      return <CommunitiesWithMap communities={communities} />;
    }

    return (
      <Card isEmpty={hasNoCommunities}>
        <TextRegular>{t('no_communities').toUpperCase()}</TextRegular>
        {isOyoAdmin && (
          <BtnSecondary onClick={() => navigate(AppRoutes.AddNewCommunity)}>
            {t('add_new_community')}
          </BtnSecondary>
        )}
      </Card>
    );
  };

  return (
    <Container>
      <HeaderWrapper>
        <Header>{t('communities')}</Header>
        <BtnWrapper>
          {isOyoAdmin && (
            <BtnPrimary onClick={() => navigate(AppRoutes.AddNewCommunity)}>
              <Image src={PlusOutlines} alt="PlusIcon" margin="0 5px 0 0" />
              {t('add_new_community')}
            </BtnPrimary>
          )}
        </BtnWrapper>
      </HeaderWrapper>
      {renderCommunities()}
    </Container>
  );
};

export default Communities;
