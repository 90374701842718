import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import FinancialFollowup from 'components/communities/Charts/FinancialFollowup';
import { ALL_PRM } from 'components/communities/Dashboard/constants';
import { mapFinancialData } from 'components/communities/Dashboard/utils';
import { labelsByType } from 'components/communities/constants';
import { formatFinancialDataWithEmptyTypes } from 'components/communities/utils';
import { useGetFinancialDataQuery } from 'services/dashboardManagment';
import { useGetPrmByUserIdQuery } from 'services/prmManagement';

import { useAppSelector } from 'utils/hooks';
import { getDateRange } from 'utils/url/getDateRange';

import { infoItems, legendItems, types } from './constants';
import { somePrmDummyData } from './constants';

const FinancialFollowUpChartWrapper = () => {
  const { id } = useParams();
  const period = useAppSelector((state) => state.dashboardPeriod.period);
  const [selectedPrm, setSelectedPrmKey] = useState(ALL_PRM);
  const { t } = useTranslation();
  const { data: prmData } = useGetPrmByUserIdQuery({ userId: id });
  const { data: financialData } = useGetFinancialDataQuery({
    ...getDateRange(period),
    prmId: selectedPrm === ALL_PRM ? undefined : selectedPrm,
    userId: id,
  });

  const hasFinancialData =
    financialData &&
    financialData.length > 0 &&
    financialData.some((item: any) => !!labelsByType[item.status]);

  const financialDataMapped = hasFinancialData
    ? formatFinancialDataWithEmptyTypes(
        mapFinancialData(financialData, t),
        types,
        labelsByType,
      )
    : somePrmDummyData(t);

  const financialDataTotals = financialDataMapped?.reduce(
    (acc: any, currentDataItem: any) => {
      let indexByType = null;
      if (currentDataItem.type === 'paid_amount') indexByType = 0;
      if (currentDataItem.type === 'overdue_amount') indexByType = 1;
      if (currentDataItem.type === 'expected_amount') indexByType = 2;
      acc[indexByType as number] += currentDataItem.value;
      return acc;
    },
    [0, 0, 0],
  );

  const consumerDropdownItems = [
    {
      label: t(ALL_PRM),
      key: ALL_PRM,
    },
  ];

  if (Array.isArray(prmData)) {
    prmData.forEach((prm) => {
      consumerDropdownItems.push({
        label: prm?.name || prm.prmValue?.toString() || '',
        key: prm?._id,
      });
    });
  }

  return (
    <FinancialFollowup
      withSelect
      selectItems={consumerDropdownItems}
      setSelectValue={setSelectedPrmKey}
      selectValue={selectedPrm}
      infoItems={infoItems(t)}
      infoData={financialDataTotals}
      legendItems={legendItems(t)}
      data={financialDataMapped}
      hasData={hasFinancialData}
    />
  );
};

export default FinancialFollowUpChartWrapper;
