import { v4 as uuidv4 } from 'uuid';

import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DynamicEditForm from 'components/communities/components/DynamicEditForm';
import GenericFormName from 'components/communities/components/GenericFormName';
import GenericFormSwitch from 'components/communities/components/GenericFormSwitch';
import Loader from 'components/shared/Loader';
import { ROLES } from 'constants/global';
import { useUpdateMemberMutation } from 'services/membersManagement';
import { IMember } from 'services/types';

import { editMemberSchema } from 'utils/validation/schemas';

import DetailCard from '../../../../../Profile/components/Card';
import {
  Button,
  EditIcon,
  StyledBlockBody,
  StyledBlockHeader,
  StyledBlockTitle,
  StyledBlockWrapper,
} from '../../styles';
import { consumerLabels, legalRepresentativeLabels } from './constants';

interface ProfileDataProps {
  member: IMember | undefined;
  refetch: () => void;
}

const ProfileData: FC<ProfileDataProps> = ({ member, refetch }) => {
  const { t } = useTranslation();
  const [isEditMode, setEditMode] = useState(false);
  const [userProfile, setUserProfile] = useState(member);

  const [updateMember, { isLoading, isSuccess, data: memberData }] =
    useUpdateMemberMutation();

  useEffect(() => {
    setUserProfile(memberData);
  }, [memberData]);

  useEffect(() => {
    setUserProfile(member);
  }, [member]);

  const name = `${userProfile?.firstName} ${userProfile?.middleName} ${userProfile?.lastName}`;
  const siretNumber = userProfile?.additionalInfo.siretNumber;
  const location = userProfile?.address.street;
  const legalPerson = userProfile?.additionalInfo.legalPerson;
  const legalPersonName = `${legalPerson?.firstName} ${legalPerson?.middleName} ${legalPerson?.lastName}`;
  const hasSpecialTariffs = userProfile?.additionalInfo.hasSpecialTariffs;
  const specialTariffsText = hasSpecialTariffs ? t('discounted') : t('regular');

  const userEditFormData = {
    consumer_data: [
      {
        id: uuidv4(),
        label: 'consumer_name',
        key: '',
        value: name,
        component: GenericFormName,
      },
      {
        id: uuidv4(),
        label: 'siren_number',
        key: 'additionalInfo.siretNumber',
        value: siretNumber,
      },
      {
        id: uuidv4(),
        label: 'location',
        key: 'address.street',
        value: location,
      },
      {
        id: uuidv4(),
        label: 'special_tariffs',
        key: 'additionalInfo.hasSpecialTariffs',
        value: hasSpecialTariffs,
        component: GenericFormSwitch,
      },
    ],
    legal_representative: [
      {
        id: uuidv4(),
        label: 'legal_person_name',
        key: 'additionalInfo.legalPerson',
        value: legalPersonName,
        component: GenericFormName,
      },
      {
        id: uuidv4(),
        label: 'legal_person_email_address',
        key: 'additionalInfo.legalPerson.email',
        value: legalPerson?.email,
      },
      {
        id: uuidv4(),
        label: 'legal_person_position',
        key: 'additionalInfo.legalPerson.position',
        value: legalPerson?.position,
      },
      {
        id: uuidv4(),
        label: 'legal_person_phone_number',
        key: 'additionalInfo.legalPerson.contactNumber',
        value: legalPerson?.contactNumber,
      },
    ],
  };

  const handleOnEdit = () => {
    if (userProfile?.role === ROLES.CONSUMER) {
      setUserProfile(member);
      setEditMode(true);
    }
  };
  const handleOnSave = (values: IMember) => {
    setEditMode(false);
    updateMember(values);
    refetch();
  };

  return (
    <StyledBlockWrapper>
      {isLoading && <Loader />}
      <StyledBlockHeader>
        <StyledBlockTitle>{t('profile_data')}</StyledBlockTitle>
        <Button
          size="small"
          type="text"
          disabled={isEditMode}
          onClick={handleOnEdit}
        >
          <EditIcon disabled={isEditMode} />
          {t('edit')}
        </Button>
      </StyledBlockHeader>
      <StyledBlockBody>
        <DynamicEditForm
          isEditMode={isEditMode}
          formData={userEditFormData}
          initialValues={userProfile}
          validationSchema={editMemberSchema(t)}
          onClose={() => setEditMode(false)}
          onSave={handleOnSave}
        />
        <DetailCard
          isEditMode={isEditMode}
          cardWidth="100%"
          labelWidth={'150px'}
          labels={consumerLabels}
          title={t('consumer_data').toString()}
          firstValue={name}
          secondValue={siretNumber}
          thirdValue={location}
          fourthValue={specialTariffsText}
        />
        <DetailCard
          isEditMode={isEditMode}
          cardWidth="100%"
          labelWidth={'150px'}
          labels={legalRepresentativeLabels}
          title={t('legal_representative').toString()}
          firstValue={legalPersonName}
          secondValue={legalPerson?.email}
          thirdValue={legalPerson?.position}
          fourthValue={legalPerson?.contactNumber}
        />
      </StyledBlockBody>
    </StyledBlockWrapper>
  );
};

export default ProfileData;
