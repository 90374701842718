import { RingProgress } from '@ant-design/plots';

import { COLORS } from 'constants/colors';

import { StyledRepartitionRing } from '../../styles';

const RingProggresChart = ({ percent }: { percent: number }) => {
  const config = {
    height: 54,
    width: 55,
    autoFit: false,
    percent: percent,
    color: [COLORS.Green5, '#D4D5D6'],
    innerRadius: 0.55,
    radius: 0.98,
    statistic: undefined,
    lineWidth: 10,
    progressStyle: {
      style: {
        lineWidth: 10,
      },
    },
  };
  return (
    <StyledRepartitionRing>
      <RingProgress {...config} />
    </StyledRepartitionRing>
  );
};

export default RingProggresChart;
