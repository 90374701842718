import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FormikProvider, useFormik } from 'formik';

import { Tooltip } from 'antd';

import { useGetPrmByUserIdQuery } from 'services/prmManagement';

import { StyledTabWrapper } from '../../../Profile/styles';
import {
  StlyedProfileSupportText,
  StyledBlockHeader,
  StyledBlockTitle,
  StyledBlockWrapper,
  StyledContainer,
  StyledProfileSupportEmail,
} from '../ProfileDataTab/styles';
import PrmBody from './components/PrmBody';
import { initialValues } from './constants';
import { PageWrapper, StyledAddPrmButton, StyledPlusIcon } from './styles';

const ConsumerSettingsTab = () => {
  const { id } = useParams();
  const { data: prmData, refetch } = useGetPrmByUserIdQuery({ userId: id });
  const [communityId, setCommunityId] = useState('');
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    onSubmit: async (values: typeof initialValues) => {},
  });

  useEffect(() => {
    if (Array.isArray(prmData)) {
      setCommunityId(prmData[0]?.community);
      const prms = prmData?.map(
        ({ prmValue, name, power, gridPrice, status, _id, prmType }) => {
          return {
            id: _id,
            prmValue,
            power,
            name,
            gridPrice,
            status,
            prmType,
          };
        },
      );
      formik.setFieldValue('prms', prms);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prmData]);

  return (
    <StyledTabWrapper>
      <StyledContainer>
        <PageWrapper>
          <StyledBlockWrapper>
            <StyledBlockHeader>
              <StyledBlockTitle>{t('prm_numbers')}</StyledBlockTitle>
              <Tooltip
                placement="top"
                color="white"
                title={
                  <StlyedProfileSupportText>
                    {t('to_add_another_prm_please_contact_us_at')}
                    <StyledProfileSupportEmail href="mailto:support@oyo.ai">
                      support@oyo.ai
                    </StyledProfileSupportEmail>
                  </StlyedProfileSupportText>
                }
              >
                <StyledAddPrmButton>
                  <StyledPlusIcon /> {t('add_another_prm_number')}
                </StyledAddPrmButton>
              </Tooltip>
            </StyledBlockHeader>
            <FormikProvider value={formik}>
              <PrmBody refetchPrm={refetch} communityId={communityId} />
            </FormikProvider>
          </StyledBlockWrapper>
        </PageWrapper>
      </StyledContainer>
    </StyledTabWrapper>
  );
};

export default ConsumerSettingsTab;
