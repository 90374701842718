import { styled } from 'styled-components';

export const StyledInfoRow = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding-left: 24px;
  margin-bottom: 40px;
  width: 209px;
`;

export const StyledInfoBlock = styled.div<{
  borderColor?: string;
  fullWidth?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  border-left: 3px solid ${(props) => props.borderColor};
  height: 60px;
  padding-left: 6px;
  max-width: ${(props) => (props.fullWidth ? '100%' : 'none')};
`;

export const StyledInfoTitle = styled.div`
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 3px;
`;

export const StyledInfoPower = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
`;

export const StyledInfoUnit = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.65);
`;
