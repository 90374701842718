import { getAppRole } from 'utils';

import { useTranslation } from 'react-i18next';

import { PageHeader, Wrapper } from 'components/communities/styles';
import LanguageSelect from 'components/localization/language-select/LanguageSelect';
import TabsComponent from 'components/shared/Tabs';
import { COLORS } from 'constants/colors';
import { USER_ROLES } from 'constants/types';
import { useGetMemberByIdQuery } from 'services/pmoMemberDashboardManagment';

import {
  MailIcon,
  StyledLanguageContainer,
  StyledLanguageTitle,
  StyledLanguageWrapper,
  StyledMail,
  StyledProfileHeader,
  StyledProfileRow,
  StyledProfileTitle,
  StyledTabs,
} from './styles';
import { useTabsConfig } from './useTabsConfig';

const Profile = () => {
  const { t } = useTranslation();
  const { tabsConfig, handleChangeTab, activeTabKey } = useTabsConfig(
    getAppRole(),
  );
  const { data: member } = useGetMemberByIdQuery(undefined);

  const memberName = `${member?.firstName} ${member?.middleName} ${member?.lastName}`;

  return (
    <Wrapper>
      <PageHeader background={COLORS.BgContainer}>
        <StyledProfileHeader>
          <StyledProfileRow>
            <StyledProfileTitle>
              {memberName ? memberName : 'Profile Name'}
            </StyledProfileTitle>
          </StyledProfileRow>
          <StyledMail>
            <MailIcon /> {member?.email}
          </StyledMail>
        </StyledProfileHeader>
      </PageHeader>
      <StyledTabs>
        <TabsComponent
          activeTabKey={activeTabKey}
          onChange={handleChangeTab}
          backgroundColor={COLORS.BgLayout}
          items={tabsConfig}
        />
      </StyledTabs>
      {activeTabKey !== 'Settings' && (
        <StyledLanguageWrapper
          hasTopMargin={
            member?.role === USER_ROLES.ADMIN ||
            member?.role === USER_ROLES.PMO_ADMIN
          }
        >
          <StyledLanguageContainer>
            <StyledLanguageTitle>{t('language_settings')}</StyledLanguageTitle>
            <LanguageSelect />
          </StyledLanguageContainer>
        </StyledLanguageWrapper>
      )}
    </Wrapper>
  );
};

export default Profile;
