import { styled } from 'styled-components';

import { EditTwoTone } from '@ant-design/icons';

import { COLORS } from 'constants/colors';

export const StyledGridBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: ${COLORS.Grey6};
  padding: 16px 8px 16px 16px;
  margin-bottom: 16px;
`;

export const StyledGridHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  margin-top: 8px;
`;

export const StyledGridTitle = styled.div`
  font-weight: 600;
  font-size: 12px;
  display: flex;
  align-items: center;
  column-gap: 5px;
  color: ${COLORS.TextSecondary};
  text-transform: uppercase;
`;

export const StyledGridPriceItem = styled.div<{ background: string }>`
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 0px 8px;
  height: 77px;
  background-color: ${(props) => props.background};
`;

export const StyledGridSeason = styled.div<{ marginRight?: string }>`
  font-size: 14px;
  line-height: 22px;
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '17px')};
`;

export const StyledGridHours = styled.div`
  font-size: 12px;
  line-height: 20px;
`;

export const StyledGridHoursItem = styled.div`
  display: flex;
  column-gap: 85px;
`;

export const StyledGridSmInput = styled.div`
  width: 167px;
  margin-left: 10px;
  margin-right: 85px;
`;
export const StyledGridLgInput = styled.div`
  width: 301px;
  margin-left: 10px;
`;

export const StyledPricesContainer = styled.div<{ hasData: boolean }>`
  display: flex;
  margin: 0 8px;
  flex-direction: row;
  ${({ hasData }) =>
    !hasData &&
    'align-items: baseline; width: 60%; justify-content: space-between;'}
`;

export const StyledPriceCol = styled.div<{ width?: string }>`
  width: ${(props) => props.width};
`;

export const StyledPriceContainer = styled.div`
  margin: 0 14px;
`;

export const StyledPriceTypeText = styled.span`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
  display: inline-block;
`;

export const StyledPriceUnitText = styled.span`
  font-size: 12px;
  font-weight: 400;
`;

export const StyledPriceValue = styled.div`
  font-size: 14px;
  font-weight: 600;
  background: ${COLORS.BgContainer};
  padding: 8px 12px;
  border-radius: 2px;
`;

export const EditIcon = styled(EditTwoTone).attrs(() => ({
  twoToneColor: COLORS.Green6,
}))``;

export const StyledPrmEditButton = styled.div`
  display: flex;
  align-items: center;
  column-gap: 3px;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  pointer-events: visible;
  padding-top: 34px;
  text-transform: capitalize;
`;

export const StyledGridSubTitle = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: ${COLORS.TextBase};
  margin-top: 14px;
`;

export const StyledEmptyGridTitle = styled.div`
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  color: ${COLORS.TextSecondary};
`;
