import { styled } from 'styled-components';

import { COLORS } from 'constants/colors';

export const InfoBlock = styled.div`
  width: 198px;
  height: 278px;
  padding: 12px 13px 16px 16px;
  background-color: ${COLORS.BgLayout};
  box-sizing: border-box;
`;

export const InfoCostEarnedRow = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-bottom: 12px;
`;

export const InfoCostEarnedImage = styled.img`
  width: 36px;
  height: 36px;
  padding: 10px;
  border-radius: 6px;
  background-color: ${COLORS.BgElevated};
`;

export const InfoItemsRow = styled.div<{ gap: string }>`
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: ${(props) => props.gap};
`;

export const InfoItem = styled.div<{ color: string; height: string }>`
  display: flex;
  flex-direction: column;
  align-items: start;
  height: ${(props) => props.height};
  padding-left: 12px;
  border-left: 3px solid ${(props) => props.color};
  border-radius: 2px;
`;

export const InfoItemTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`;

export const InfoItemAmount = styled.span`
  font-size: 17px;
  font-weight: 600;
  line-height: 28px;
`;

export const InfoItemEuro = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;

export const StyledInfoItemsBlock = styled.div`
  margin-left: -24px;
`;
