import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useGetCommunityElectricityConsumptionQuery } from 'services/communityManagement';
import {
  useGetSelfConsumedBySourceQuery,
  useGetSelfConsumedByTypeQuery,
} from 'services/dashboardManagment';

import { useAppSelector } from 'utils/hooks';
import { getDateRange } from 'utils/url/getDateRange';

import ElectricityConsumption from '../Charts/ElectricityConsumption';
import SelfConsumption from '../Charts/SelfConsumption';
import { sortSelfConsumedByHoursData } from '../Dashboard/utils';
import CommunityMap from '../MyCommunity/components/CommunityMap';
import {
  areaColors,
  infoItems,
  legendItems,
  lineColors,
  mapElectricityConsumptionData,
} from '../MyCommunity/constants/ElectricityConsumption';
import { getPieColorBySource, getPieColorByType } from '../MyCommunity/utils';
import {
  selfConsumedEmptyStateByConsumerType,
  selfConsumedEmptyStateByHours,
  selfConsumedInfoItemsByConsumerType,
  selfConsumedInfoItemsByHours,
} from '../constants';
import { ChartsWrapper, Container, Row } from '../styles';
import { getFormattedInfoChartValue } from '../utils';

interface ICommunityData {
  consumptionTotal?: number;
  selfConsumption?: number;
  gridConsumption?: number;
  overproduction?: number;
}

const CommunityData = ({
  consumptionTotal,
  selfConsumption,
  gridConsumption,
  overproduction,
}: ICommunityData) => {
  const { t } = useTranslation();
  const period = useAppSelector((state) => state.dashboardPeriod.period);

  const { state: community } = useLocation();

  const { data: electricityConsumptionData } =
    useGetCommunityElectricityConsumptionQuery({
      ...getDateRange(period),
      communityId: community?.id,
    });

  const { data: selfConsumedByTypeData } = useGetSelfConsumedByTypeQuery({
    ...getDateRange(period),
    userId: community?.pmoId,
  });
  const { data: selfConsumedBySourceData } = useGetSelfConsumedBySourceQuery({
    ...getDateRange(period),
    userId: community?.pmoId,
  });
  const electricityConsumptionInfoDataArray = [
    consumptionTotal,
    selfConsumption,
    gridConsumption,
    overproduction,
  ];

  const electricityConsumptionDataMapped = useMemo(
    () =>
      mapElectricityConsumptionData(
        electricityConsumptionData,
        period,
        undefined,
        t,
      ),
    [electricityConsumptionData, period, t],
  );

  const selfConsumedByTypePresentableData =
    selfConsumedByTypeData && selfConsumedByTypeData.length
      ? selfConsumedByTypeData?.map((item: any) => ({
          value: item.consumedInPercent,
          type: item.scheduleType,
        }))
      : selfConsumedEmptyStateByHours;

  const selfConsumedBySourcePresentableData =
    selfConsumedBySourceData && selfConsumedBySourceData.length
      ? selfConsumedBySourceData?.map((item: any) => ({
          value: item.consumedInPercent,
          type: item.consumerType,
        }))
      : selfConsumedEmptyStateByConsumerType;

  return (
    <ChartsWrapper>
      <Container>
        <ElectricityConsumption
          title={t('electricity_consumption')}
          legendItems={legendItems(t)}
          infoItems={infoItems(t)}
          lineColors={lineColors}
          areaColors={areaColors}
          electricityConsumption={electricityConsumptionDataMapped}
          total={getFormattedInfoChartValue(
            consumptionTotal,
            electricityConsumptionInfoDataArray,
          )}
          selfConsumption={getFormattedInfoChartValue(
            selfConsumption,
            electricityConsumptionInfoDataArray,
          )}
          gridConsumption={getFormattedInfoChartValue(
            gridConsumption,
            electricityConsumptionInfoDataArray,
          )}
          overproduction={getFormattedInfoChartValue(
            overproduction,
            electricityConsumptionInfoDataArray,
          )}
        />
        <Row>
          <SelfConsumption
            getPieColor={getPieColorByType}
            chartData={sortSelfConsumedByHoursData(
              selfConsumedByTypePresentableData,
            )}
            infoItems={selfConsumedInfoItemsByHours(t)}
            title={t('energy_self_consumed_by_type_of_hours')}
          />
          <SelfConsumption
            getPieColor={getPieColorBySource}
            chartData={selfConsumedBySourcePresentableData}
            infoItems={selfConsumedInfoItemsByConsumerType(t)}
            title={t('self_consumption_by_consumer_type')}
          />
        </Row>
        <CommunityMap />
      </Container>
    </ChartsWrapper>
  );
};

export default CommunityData;
