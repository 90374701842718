import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import { Breadcrumb } from 'antd';

import HideOnScroll from 'components/shared/HideOnScroll';
import { USER_ROLES } from 'constants/types';

import { capitalizeAllWords } from 'utils/index';

import { StyledLink, StyledWrapper } from './styles';

const Breadcrumbs: React.FC = () => {
  const { t } = useTranslation();
  const { pathname, search, state } = useLocation();
  const { id, communityName }: any = useParams();
  const nextUrl = state?.nextUrl;
  const pathSnippets = pathname.split('/').filter((idx) => idx);
  const queryParams: any = new URLSearchParams(search);
  const nameParam = queryParams.get('name');
  const communityParam = queryParams.get('community');
  const roleParam = queryParams.get('role');

  const searchParamsString = [...queryParams.entries()]
    .map(([key, value]) => {
      return `${key}="${value}"`;
    })
    .join('&')
    .replaceAll('"', '');

  const breadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}${
      searchParamsString ? '?' + searchParamsString : ''
    }`;
    const currentSnippet = pathSnippets[index].split('-').join(' ');
    const isActive = url.split('?')[0] === pathname;

    const isNameAvailable =
      (url.includes(id) && nameParam) ||
      (url.includes(communityName) && nameParam);

    const getUrlPresentable = () => {
      if (index === pathSnippets.length - 1) {
        return nextUrl ? nextUrl : capitalizeAllWords(currentSnippet);
      }

      return capitalizeAllWords(currentSnippet);
    };

    const showingSnippet = getUrlPresentable()
      .toLowerCase()
      .replace(/-| /g, '_');

    const nameOrCommunity =
      roleParam === USER_ROLES.PMO_ADMIN ? communityParam : nameParam;

    return {
      key: url,
      title: (
        <StyledLink to={url} active={isActive}>
          {isNameAvailable
            ? nameOrCommunity
            : capitalizeAllWords(t(showingSnippet))}
        </StyledLink>
      ),
    };
  });

  return (
    <HideOnScroll>
      <StyledWrapper>
        <Breadcrumb separator=">" items={breadcrumbItems} />
      </StyledWrapper>
    </HideOnScroll>
  );
};

export default Breadcrumbs;
