import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Column } from '@ant-design/plots';

import { LegendItemsType } from 'components/communities/Charts/ElectricityConsumption/types';
import CustomFinancialFollowUpTooltip from 'components/communities/Charts/FinancialFollowup/component/CustomTooltip';
import { formatChartDataByFullDate } from 'components/communities/utils';

import { useAppSelector } from 'utils/hooks';

import { ChartDataType } from '../../types';
import { emptyData } from './constants';
import { StyledChartContainer } from './styles';

interface ColumnChartProps {
  data?: ChartDataType[];
  getColumnColorByType: (type: string) => void;
  legendItems?: LegendItemsType[];
}

const ColumnChart: FC<ColumnChartProps> = ({
  data,
  getColumnColorByType,
  legendItems,
}) => {
  const { t } = useTranslation();
  const period = useAppSelector((state) => state.dashboardPeriod.period);
  const config: any = {
    data:
      data && data.length > 0
        ? formatChartDataByFullDate({ data, period, isFinancial: true, t })
        : emptyData(t),
    isStack: true,
    autoFit: true,
    maxColumnWidth: 20,
    marginRatio: 100,
    xField: 'date',
    yField: 'value',
    seriesField: 'type',
    xAxis: {
      grid: null,
    },
    legend: {
      position: 'bottom',
      offsetY: 8,
      offsetX: 20,
      itemWidth: 170,
      items: legendItems,
    },
    tooltip:
      data && data.length > 0
        ? {
            marker: false,
            shared: true,
            customContent: (datum: string, tooltipData: any) =>
              data ? (
                <CustomFinancialFollowUpTooltip
                  period={period}
                  key={datum}
                  data={tooltipData}
                />
              ) : undefined,
          }
        : false,
    columnStyle: ({ type }: { type: string }) => {
      return {
        fill: getColumnColorByType(type),
        stroke: 'white',
        lineWidth: 1,
      };
    },
  };

  return (
    <StyledChartContainer>
      <Column {...config} />
    </StyledChartContainer>
  );
};

export default ColumnChart;
