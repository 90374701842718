import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  Container,
  HeaderContainer,
  HeaderRow,
  HeaderRowItem,
  PageHeader,
  Wrapper,
} from 'components/communities/styles';
import PeriodPicker from 'components/shared/PeriodPicker';
import { Header, TextRegular } from 'components/shared/Text';
import { COLORS } from 'constants/colors';
import { USER_ROLES } from 'constants/types';
import { useGetMemberByIdQuery } from 'services/pmoMemberDashboardManagment';
import { useGetEnergyReportsByUserIdQuery } from 'services/reportManagment';

import { capitalizeAllWords, getAppRole } from 'utils/index';

import ConsumerCreateReport from '../../../FinancialData/components/CreateReport/ConsumerCreateReport';
import { Card } from '../FinancialDataTab/styles';
import EnergyDataChart from './components/EnergyDataChart';
import EnergyFollowUpTable from './components/EnergyFollowUpTable';
import { StyledEnergyTable } from './components/EnergyFollowUpTable/styles';
import { getCsvEnergyReport } from './utils';

const ConsumerEnergyData = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const role = getAppRole();
  const { data: consumer } = useGetMemberByIdQuery(id);
  const { data: energyReportsData } = useGetEnergyReportsByUserIdQuery({
    userId: consumer?._id,
  });

  const isPmoAdminOrOyoAdmin = [
    USER_ROLES.PMO_ADMIN,
    USER_ROLES.ADMIN,
  ].includes(role);

  return (
    <Wrapper paddingTop={isPmoAdminOrOyoAdmin ? '0' : '64px'}>
      {!isPmoAdminOrOyoAdmin && (
        <PageHeader background={COLORS.BgContainer}>
          <HeaderContainer>
            <HeaderRow>
              <HeaderRowItem>
                <Header>{capitalizeAllWords(t('energy_data'))}</Header>
              </HeaderRowItem>
              <HeaderRowItem position="flex-end">
                <TextRegular>{t('show_data_for')}</TextRegular>
                <PeriodPicker />
              </HeaderRowItem>
            </HeaderRow>
          </HeaderContainer>
        </PageHeader>
      )}
      <Container>
        <EnergyDataChart />
        <ConsumerCreateReport
          datePickerType="date"
          fetchCsvFile={getCsvEnergyReport}
        />
        <HeaderRow>
          <HeaderRowItem>
            <Header>{capitalizeAllWords(t('energy_follow_up'))}</Header>
          </HeaderRowItem>
        </HeaderRow>
        {energyReportsData?.length > 0 ? (
          <StyledEnergyTable>
            <EnergyFollowUpTable data={energyReportsData} />
          </StyledEnergyTable>
        ) : (
          <Card isEmpty>
            <TextRegular>{t('no_reports_yet').toUpperCase()}</TextRegular>
          </Card>
        )}
      </Container>
    </Wrapper>
  );
};

export default ConsumerEnergyData;
