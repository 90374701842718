import { FC } from 'react';

import ColumnChart from 'components/communities/Charts/ColumnChart';

import { StyledChartWrapper } from './styles';

interface CommunityDataChartProps {
  chartData?: any;
  hasData: boolean;
}

const CommunityDataChart: FC<CommunityDataChartProps> = ({
  chartData,
  hasData,
}) => {
  return (
    <StyledChartWrapper>
      <ColumnChart
        xAxis={false}
        data={chartData}
        hasData={hasData}
        unit="kWh"
        hideTooltip={true}
        removeShowingDataByFullPeriod={true}
      />
    </StyledChartWrapper>
  );
};

export default CommunityDataChart;
