import { styled } from 'styled-components';

import { COLORS } from 'constants/colors';

export const WidgetWrapper = styled.div`
  width: 327px;
  height: 140px;
  padding: 12px;
  background-color: ${COLORS.BgElevated};
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.06);
`;
export const WidgetTitle = styled.span`
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
`;

export const WidgetAmount = styled.div`
  text-align: right;
  color: rgba(0, 0, 0, 0.88);
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
`;

export const WidgetHeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const WidgetSubtitle = styled.div`
  text-align: right;
  color: ${COLORS.TextSecondary};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  min-height: 24px;
`;

export const WidgetRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const WidgetCo2 = styled.span`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: ${COLORS.TextSecondary};
  margin-top: 5px;
  margin-left: 3px;
`;
export const WidgetCo2Image = styled.img`
  width: 50px;
  height: 50px;
  margin-left: 15px;
`;

export const WidgetBlock = styled.div`
  max-height: 86px;
`;
