import styled from 'styled-components';

export const StyledContainer = styled.div<{ offset: number }>`
  padding-top: ${({ offset }) => `${offset}px`};
  width: 100%;
  min-width: 1440px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
