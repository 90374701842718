import { getAppRole } from 'utils';

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import FullScreenLoader from 'components/shared/Loader/FullScreenLoader';
import PeriodPicker from 'components/shared/PeriodPicker';
import { Header, TextRegular } from 'components/shared/Text';
import { COLORS } from 'constants/colors';
import AppRoutes from 'constants/routes';
import { USER_ROLES } from 'constants/types';
import { useGetPaymentsQuery } from 'services/paymentManagment';

import {
  ChartsWrapper,
  Container,
  HeaderContainer,
  HeaderRow,
  HeaderRowItem,
  PageHeader,
  Wrapper,
} from '../styles';
import ConsumerDashboard from './components/ConsumerDashboard';
import ProducerDashboard from './components/ProducerDashboard';

const renderDashboard = (role: USER_ROLES) => {
  switch (role) {
    case USER_ROLES.PRODUCER:
      return <ProducerDashboard />;
    case USER_ROLES.CONSUMER:
      return <ConsumerDashboard />;
    default:
      return <ConsumerDashboard />;
  }
};

const Dashboard = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const role = getAppRole();
  const isProducer = role === USER_ROLES.PRODUCER;
  const { data, isLoading } = useGetPaymentsQuery({});
  const hasPayments = data?.paymentMethods?.length;

  useEffect(() => {
    if (!isProducer && !isLoading && !hasPayments) {
      navigate(AppRoutes.AddPayment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPayments, isProducer, isLoading]);

  return !isProducer && (isLoading || !hasPayments) ? (
    <FullScreenLoader />
  ) : (
    <Wrapper>
      <PageHeader background={COLORS.BgContainer}>
        <HeaderContainer>
          <HeaderRow>
            <HeaderRowItem>
              <Header>{t('dashboard')}</Header>
            </HeaderRowItem>
            <HeaderRowItem position="flex-end">
              <TextRegular>{t('show_data_for')}</TextRegular>
              <PeriodPicker />
            </HeaderRowItem>
          </HeaderRow>
        </HeaderContainer>
      </PageHeader>
      <ChartsWrapper>
        <Container>{renderDashboard(role)}</Container>
      </ChartsWrapper>
    </Wrapper>
  );
};

export default Dashboard;
