import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from 'components/communities/Charts/CostSaving/styles';
import { CardHeader, ChartTitle } from 'components/communities/styles';

import PowerPlant from './PowerPlant.svg';
import {
  StyledEnergyBody,
  StyledEnergyContent,
  StyledEnergyImage,
  StyledEnergyItem,
  StyledEnergyList,
  StyledEnergyTitle,
} from './styles';

type EnergyTipType = {
  title: string;
  items: string[];
};

interface EnergyTipsProps {
  tip: EnergyTipType;
  consumerName?: string;
}

const EnergyTips: FC<EnergyTipsProps> = ({ tip, consumerName }) => {
  const { t } = useTranslation();

  return (
    <Card width={676}>
      <CardHeader>
        <ChartTitle>{t('energy_tips')}</ChartTitle>
      </CardHeader>
      <StyledEnergyBody>
        <StyledEnergyContent>
          <StyledEnergyTitle>
            {t(tip.title, { consumerName })}
          </StyledEnergyTitle>
          <StyledEnergyList>
            {tip.items.map((item) => (
              <StyledEnergyItem key={item}>{t(item)}</StyledEnergyItem>
            ))}
          </StyledEnergyList>
        </StyledEnergyContent>
        <StyledEnergyImage src={PowerPlant} alt="power plant" />
      </StyledEnergyBody>
    </Card>
  );
};

export default EnergyTips;
