import React from 'react';
import { useTranslation } from 'react-i18next';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import CreatePassword from 'components/auth/CreatePassword';
import ForgotPassword from 'components/auth/ForgotPassword';
import SignIn from 'components/auth/SignIn';
import Communities from 'components/communities/Communities';
import CommunityDetails from 'components/communities/CommunityDetails';
import CreateCommunity from 'components/communities/CreateCommunity';
import Dashboard from 'components/communities/Dashboard';
import EnergyData from 'components/communities/Dashboard/components/EnergyData';
import FinancialData from 'components/communities/Dashboard/components/FinancialData';
import Profile from 'components/communities/Dashboard/components/Profile';
import MyCommunity from 'components/communities/MyCommunity';
import Reports from 'components/communities/Reports';
import ProtectedByRole from 'components/hoc/ProtectedByRole';
import UnprotectedRoute from 'components/hoc/UnprotectedRoute';
import MembersDashboard from 'components/members/Dashboard';
import Members from 'components/members/Members';
import CreateConsumer from 'components/members/createConsumer/CreateConsumer';
import CreateProducer from 'components/members/createProducer/CreateProducer';
import Pricing from 'components/pricing/Pricing';
import TaxPrices from 'components/pricing/components/TaxPrices';
import MainOutlet from 'components/shared/MainOutlet';
import PrivateRoute from 'components/shared/PrivateRoute';
import ScrollToTop from 'components/shared/ScrollToTop';
import AddPayment from 'components/stripe/Payment';
import AppRoutes from 'constants/routes';

import { useAppLanguage } from 'utils/hooks';

import { getBaseRoute } from './utils';

const App: React.FC = () => {
  const { t } = useTranslation();
  useAppLanguage();
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route index element={<Navigate replace to={getBaseRoute()} />} />
        <Route
          path={AppRoutes.SignIn}
          element={
            <UnprotectedRoute>
              <SignIn />
            </UnprotectedRoute>
          }
        />
        <Route
          path={AppRoutes.ForgotPassword}
          element={
            <UnprotectedRoute>
              <ForgotPassword />
            </UnprotectedRoute>
          }
        />
        <Route
          path={AppRoutes.CreatePassword}
          element={
            <UnprotectedRoute>
              <CreatePassword title={t('welcome')} />
            </UnprotectedRoute>
          }
        />
        <Route
          path={AppRoutes.ChangePassword}
          element={
            <UnprotectedRoute>
              <CreatePassword title={t('create_password')} hasTokenValidation />
            </UnprotectedRoute>
          }
        />

        <Route path={AppRoutes.AddPayment}>
          <Route
            index
            element={
              <PrivateRoute>
                <ProtectedByRole>
                  <AddPayment />
                </ProtectedByRole>
              </PrivateRoute>
            }
          />
        </Route>

        <Route
          path={AppRoutes.Root}
          element={
            <PrivateRoute>
              <MainOutlet />
            </PrivateRoute>
          }
        >
          <Route path={AppRoutes.Communities}>
            <Route
              index
              element={
                <ProtectedByRole>
                  <Communities />
                </ProtectedByRole>
              }
            />

            <Route path={AppRoutes.AddNewCommunity}>
              <Route
                index
                element={
                  <ProtectedByRole>
                    <CreateCommunity />
                  </ProtectedByRole>
                }
              />
            </Route>
          </Route>

          {/*<Route*/}
          {/*  path={AppRoutes.Pricing}*/}
          {/*  element={*/}
          {/*    <ProtectedByRole>*/}
          {/*      <Pricing />*/}
          {/*    </ProtectedByRole>*/}
          {/*  }*/}
          {/*/>*/}

          {/*<Route*/}
          {/*  path={AppRoutes.TaxPrices}*/}
          {/*  element={*/}
          {/*    <ProtectedByRole>*/}
          {/*      <TaxPrices />*/}
          {/*    </ProtectedByRole>*/}
          {/*  }*/}
          {/*/>*/}

          <Route path={AppRoutes.MyCommunity}>
            <Route
              index
              element={
                <ProtectedByRole>
                  <MyCommunity />
                </ProtectedByRole>
              }
            />
          </Route>

          <Route path={AppRoutes.Reports}>
            <Route
              index
              element={
                <ProtectedByRole>
                  <Reports />
                </ProtectedByRole>
              }
            />
          </Route>

          <Route path={AppRoutes.CommunityDetails}>
            <Route
              index
              element={
                <ProtectedByRole>
                  <CommunityDetails />
                </ProtectedByRole>
              }
            />
          </Route>

          <Route path={AppRoutes.FinancialData}>
            <Route
              index
              element={
                <ProtectedByRole>
                  <FinancialData />
                </ProtectedByRole>
              }
            />
          </Route>

          <Route path={AppRoutes.EnergyData}>
            <Route
              index
              element={
                <ProtectedByRole>
                  <EnergyData />
                </ProtectedByRole>
              }
            />
          </Route>

          <Route path={AppRoutes.Profile}>
            <Route
              index
              element={
                <ProtectedByRole>
                  <Profile />
                </ProtectedByRole>
              }
            />
          </Route>

          <Route path={AppRoutes.Dashboard}>
            <Route index element={<Dashboard />} />
          </Route>

          <Route path={AppRoutes.Members}>
            <Route index element={<Members />} />

            <Route path={AppRoutes.AddNewConsumer}>
              <Route index element={<CreateConsumer />} />
            </Route>

            <Route path={AppRoutes.MemberDashboard}>
              <Route index element={<MembersDashboard />} />
            </Route>
          </Route>
          <Route path={AppRoutes.AddNewProducer}>
            <Route index element={<CreateProducer />} />
          </Route>
        </Route>
        <Route path="*" element={<Navigate replace to={AppRoutes.Root} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
