import { PrmsType } from './components/PrmBody/types';

export const initialValues: PrmsType = {
  prms: [
    {
      name: '',
      id: '',
      prmValue: '',
      prmType: '',
      status: '',
      power: null,
      gridPrice: {
        dynamic: {
          RH: null,
          HS_HH: null,
          HS_LH: null,
          LS_HH: null,
          LS_LH: null,
        },
        static: null,
      },
    },
  ],
};

export const emptyDynamicObject = {
  RH: null,
  HS_HH: null,
  HS_LH: null,
  LS_HH: null,
  LS_LH: null,
};
