import { CreateConsumerPayload } from 'services/types';

export const initialValues: CreateConsumerPayload = {
  firstName: '',
  middleName: '',
  lastName: '',
  email: '',
  contactNumber: null,
  role: 'Consumer',
  address: {
    city: '',
    country: 'France',
    postalCode: '',
    street: '',
    building: '',
    location: {
      coordinates: [],
    },
  },
  additionalInfo: {
    type: '',
    siretNumber: null,
    prms: [
      {
        prmValue: null,
        power: null,
        name: '',
        gridPrice: {
          dynamic: {
            RH: null,
            HS_HH: null,
            HS_LH: null,
            LS_HH: null,
            LS_LH: null,
          },
          static: null,
        },
      },
    ],
    billingAddress: {
      city: '',
      country: 'France',
      postalCode: '',
      street: '',
      building: '',
    },
    billingPerson: {
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      contactNumber: '',
    },
    legalPerson: {
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      contactNumber: '',
      position: '',
    },
    cppaSigned: false,
    agreementSigned: false,
    enedisConsentSigned: false,
    hasSpecialTariffs: false,
  },
};
