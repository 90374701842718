import { styled } from 'styled-components';

import { Header } from 'components/shared/Text';

export const StyledWrapper = styled.div`
  width: 100%;
  max-width: 442px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const StyledHeader = styled(Header)`
  margin: 32px 0 40px;
`;

export const StyledTab = styled.div<{ active: boolean }>`
  width: 100%;
  display: ${({ active }) => (active ? 'block' : 'none')};
`;

export const StyledTabsWrapper = styled.div`
  margin-top: 45px;
  width: 100%;
`;
