import React, { useState } from 'react';

import styled from 'styled-components';

import { Pie } from '@ant-design/plots';

import { COLORS } from 'constants/colors';

const DEFAULT_CHART_SIZE = 101;
const DEFAULT_COLORS = [COLORS.Yellow5, COLORS.Orange5];
const DEFAULT_INNERRADIUS = 0.6;
export interface IPieItem {
  type: any;
  value: number;
}

interface IPieChart {
  size?: number;
  color?: COLORS[];
  chartData: IPieItem[];
  innerRadius?: number;
  content?: any;
  handleOnHover?: (type: any) => void;
  handleOnBlur?: (type: any) => void;
}

const ChartContainer = styled.div`
  background-color: ${COLORS.BgContainer};
  border-radius: 50%;
`;

const CommunityCardChart = React.memo(
  ({
    chartData,
    size = DEFAULT_CHART_SIZE,
    color = DEFAULT_COLORS,
    innerRadius = DEFAULT_INNERRADIUS,
    handleOnHover,
    handleOnBlur,
  }: IPieChart) => {
    const [hasAnimation, setHasAnimation] = useState(true);
    const hasNoValue = chartData[0].value === 0;

    const onHover = (event: any) => {
      handleOnHover?.(event?.data?.data?.type);
      setHasAnimation(false);
    };

    const onBlur = (event: any) => {
      handleOnBlur?.(event?.data?.data?.type);
      setHasAnimation(false);
    };

    const config = {
      color,
      width: size,
      height: size,
      data: chartData,
      tooltip: false,
      legend: false,
      label: false,
      angleField: 'value',
      colorField: 'type',
      radius: 1,
      innerRadius,
      outerRadius: 1,
      appendPadding: 3,
      statistic: {
        title: undefined,
        content: false,
      },
      pieStyle: {
        lineWidth: 3,
        fillOpacity: 0.9,
      },
      animation: hasAnimation,
      interactions: hasNoValue ? false : [{ type: 'element-active' }],
      interaction: {
        elementHighlight: true,
      },
      state: {
        active: {
          style: {
            stroke: COLORS.BgContainer,
            fillOpacity: 1,
            lineWidth: hasNoValue ? 3 : 8,
          },
        },
      },
      onReady: (plot: any) => {
        plot.on('element:mouseenter', onHover);
        plot.on('element:mouseleave', onBlur);
        plot.on('element:mouseout', onBlur);
      },
    };

    return (
      <ChartContainer>
        {/* @ts-ignore */}
        <Pie {...config} />
      </ChartContainer>
    );
  },
);

export default CommunityCardChart;
