import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DetailCardRow } from '../../styles';
import {
  DetailCardBlock,
  DetailCardLabel,
  DetailCardTitle,
  DetailCardValue,
} from './styles';

interface DetailCardProps {
  isEditMode?: boolean;
  title: string;
  labels?: Record<string, string>;
  labelWidth?: string;
  firstValue?: string;
  secondValue?: string | number | null;
  thirdValue?: string | number | null;
  fourthValue?: string | number | null;
  cardWidth: string;
}

const DetailCard: FC<DetailCardProps> = ({
  isEditMode,
  title,
  labels,
  firstValue = 'N/A',
  secondValue = 'N/A',
  thirdValue = 'N/A',
  fourthValue = 'N/A',
  labelWidth,
  cardWidth,
}) => {
  const { t } = useTranslation();

  if (isEditMode) return null;

  return (
    <DetailCardBlock width={cardWidth}>
      <DetailCardTitle>{title}</DetailCardTitle>
      <DetailCardRow>
        <DetailCardLabel width={labelWidth}>
          {labels && t(labels.first)}
        </DetailCardLabel>
        <DetailCardValue>{firstValue}</DetailCardValue>
      </DetailCardRow>
      <DetailCardRow>
        <DetailCardLabel width={labelWidth}>
          {' '}
          {labels && t(labels.second)}
        </DetailCardLabel>
        <DetailCardValue>{secondValue}</DetailCardValue>
      </DetailCardRow>
      <DetailCardRow>
        <DetailCardLabel width={labelWidth}>
          {' '}
          {labels && t(labels.third)}
        </DetailCardLabel>
        <DetailCardValue>{thirdValue}</DetailCardValue>
      </DetailCardRow>
      <DetailCardRow>
        <DetailCardLabel width={labelWidth}>
          {labels && t(labels.fourth)}
        </DetailCardLabel>
        <DetailCardValue>{labels?.fourth ? fourthValue : ''}</DetailCardValue>
      </DetailCardRow>
    </DetailCardBlock>
  );
};

export default DetailCard;
