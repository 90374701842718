import { css } from 'styled-components';

export enum BREAKPOINTS_RESOLUTION {
  Small = 768,
  Medium = 979,
  Large = 1199,
}

export const ScreenSmall = (styles: TemplateStringsArray | any): any => css`
  @media (max-width: ${BREAKPOINTS_RESOLUTION.Small}px) {
    ${css(styles)}
  }
`;

export const ScreenMedium = (styles: TemplateStringsArray | any): any => css`
  @media (max-width: ${BREAKPOINTS_RESOLUTION.Medium}px) {
    ${css(styles)}
  }
`;

export const ScreenLarge = (styles: TemplateStringsArray | any): any => css`
  @media (max-width: ${BREAKPOINTS_RESOLUTION.Large}px) {
    ${css(styles)}
  }
`;
