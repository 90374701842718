import { FieldArray, useFormikContext } from 'formik';

import { initialValues } from '../../constants';
import PrmNumber from '../PrmNumber';
import { StyledPrmBody } from './styles';

const PrmBody = ({
  communityId,
  refetchPrm,
}: {
  communityId: string;
  refetchPrm: () => void;
}) => {
  const { values } = useFormikContext<typeof initialValues>();

  return (
    <StyledPrmBody>
      <FieldArray
        name="additionalInfo.prms"
        render={(arrayHelpers) => (
          <>
            {values.prms &&
              values.prms.map((number, idx) => (
                <PrmNumber
                  refetchPrm={refetchPrm}
                  key={idx}
                  communityId={communityId}
                  index={idx}
                />
              ))}
          </>
        )}
      />
    </StyledPrmBody>
  );
};

export default PrmBody;
