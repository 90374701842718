import { styled } from 'styled-components';

export const StyledEnergyBody = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: start;
  column-gap: 73px;
  padding: 30px;
`;

export const StyledEnergyContent = styled.div`
  width: 463px;
`;

export const StyledEnergyTitle = styled.h4`
  font-weight: 600;
  line-height: 22px;
  margin: 0px 0px 20px 0px;
`;

export const StyledEnergyItem = styled.li`
  font-weight: 400;
`;

export const StyledEnergyImage = styled.img`
  width: 80px;
  height: 80px;
  flex-shrink: 0;
`;

export const StyledEnergyList = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 7px;
  margin: 0px;
  padding: 0px 0px 0px 18px;
`;
