import { FC } from 'react';

import { DetailCardRow } from '../../styles';
import {
  DetailCardBlock,
  DetailCardLabel,
  DetailCardTitle,
  DetailCardValue,
} from './styles';

interface DetailCardProps {
  title: string;
  labels?: Record<string, string>;
  labelWidth?: string;
  firstValue?: string;
  secondValue?: string | number | null;
  thirdValue?: string;
  fourthValue?: string | number | null;
}

const DetailCard: FC<DetailCardProps> = ({
  title,
  labels,
  firstValue = 'N/A',
  secondValue = 'N/A',
  thirdValue = 'N/A',
  fourthValue = 'N/A',
  labelWidth,
}) => {
  return (
    <DetailCardBlock>
      <DetailCardTitle>{title}</DetailCardTitle>
      <DetailCardRow>
        <DetailCardLabel width={labelWidth}>{labels?.first}</DetailCardLabel>
        <DetailCardValue>{firstValue}</DetailCardValue>
      </DetailCardRow>
      <DetailCardRow>
        <DetailCardLabel width={labelWidth}>{labels?.second}</DetailCardLabel>
        <DetailCardValue>{secondValue}</DetailCardValue>
      </DetailCardRow>
      <DetailCardRow>
        <DetailCardLabel width={labelWidth}>{labels?.third}</DetailCardLabel>
        <DetailCardValue>{thirdValue}</DetailCardValue>
      </DetailCardRow>
      <DetailCardRow>
        <DetailCardLabel width={labelWidth}>
          {labels?.fourth || ''}
        </DetailCardLabel>
        <DetailCardValue>{labels?.fourth ? fourthValue : ''}</DetailCardValue>
      </DetailCardRow>
    </DetailCardBlock>
  );
};

export default DetailCard;
