import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { TablePaginationConfig } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { MAX_PAGINATION_PAGE_SIZE } from 'components/communities/constants';
import Loader from 'components/shared/Loader';
import { TextRegular } from 'components/shared/Text';
import { useGetEnergyReportsByUserIdQuery } from 'services/reportManagment';

import {
  FollowUpTable,
  StyledEmptyDataWrapper,
  StyledTableWrapper,
} from '../../Dashboard/components/ProducerDashboard/components/EnergyData/styles';
import { Card } from '../../Dashboard/components/ProducerDashboard/components/FinancialDataTab/styles';
import { columns } from './constants';
import { parseCommunityFollowUpTableData, sharedOnCell } from './utils';

interface FilterType {
  text: string;
  value: string;
}

const CommunityFollowUpTable = () => {
  const { id } = useParams();
  const {
    data: tableData,
    isSuccess,
    isLoading,
  } = useGetEnergyReportsByUserIdQuery({
    userId: id,
  });
  const { t } = useTranslation();
  const [showedData, setShowedData] = useState<any>([]);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    },
  );
  const [filterPrmNumber, setFilterPrmNumber] = useState<FilterType[]>([]);

  useEffect(() => {
    if (tableData) {
      const { parsedData, prmNumbersFilters } = parseCommunityFollowUpTableData(
        tableData,
        t,
      );
      setShowedData(parsedData);
      setFilterPrmNumber(prmNumbersFilters);
    }
  }, [tableData]);

  const handlePaginationChange = (page: number, pageSize: number) => {
    setTablePagination((prev) => ({ ...prev, current: page, pageSize }));
  };

  const modifiedColumns: ColumnsType<any> = [
    ...columns(t),
    {
      title: t('prms').toUpperCase(),
      dataIndex: 'prmNumber',
      onCell: sharedOnCell,
      filters: filterPrmNumber,
      onFilter: (value, record) => record.prmNumber.includes(String(value)),
    },
    {
      title: `${t('self_consumed_energy').toUpperCase()}, ${t('kwh')}`,
      dataIndex: 'selfConsumption',
      onCell: sharedOnCell,
      render: (text: string) => parseInt(text),
      sorter: (report, nextReport) =>
        parseInt(report.selfConsumption) - parseInt(nextReport.selfConsumption),
    },
    {
      title: `${t('total_consumption').toUpperCase()}, ${t('kwh')}`,
      dataIndex: 'totalConsumption',
      onCell: sharedOnCell,
      render: (text: string) => parseInt(text),
      sorter: (report, nextReport) =>
        parseInt(report.totalConsumption) -
        parseInt(nextReport.totalConsumption),
    },
  ];

  const hasEnoughDataToShowPagination =
    showedData && showedData?.length > MAX_PAGINATION_PAGE_SIZE;
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : isSuccess && tableData && showedData.length > 0 ? (
        <StyledTableWrapper>
          <FollowUpTable
            columns={modifiedColumns}
            dataSource={showedData}
            pagination={
              hasEnoughDataToShowPagination
                ? {
                    ...tablePagination,
                    position: ['bottomCenter'],
                    showSizeChanger: true,
                    onChange: handlePaginationChange,
                    showTotal: (total) =>
                      `${t('total')} ${total} ${t('items')}`,
                  }
                : false
            }
          />
        </StyledTableWrapper>
      ) : (
        <StyledEmptyDataWrapper>
          <Card isEmpty>
            <TextRegular>{t('no_data_yet').toUpperCase()}</TextRegular>
          </Card>
        </StyledEmptyDataWrapper>
      )}
    </>
  );
};

export default CommunityFollowUpTable;
