import React from 'react';

import { Field, FieldProps } from 'formik';

import { FormInput } from 'components/shared/Input';

type FormikInputProps = {
  fieldName: string;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  suffix?: React.ReactNode;
  prefix?: React.ReactNode;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const FormikInput: React.FC<FormikInputProps> = ({
  fieldName,
  placeholder,
  type = 'text',
  disabled = false,
  suffix,
  prefix,
  onKeyPress,
  onChange,
}) => {
  return (
    <Field name={fieldName}>
      {({ field, meta }: FieldProps) => (
        <FormInput
          {...field}
          suffix={suffix}
          prefix={prefix}
          type={type}
          placeholder={placeholder}
          isError={!!meta.error}
          disabled={disabled}
          onKeyPress={onKeyPress}
          onChange={(e) => {
            onChange?.(e);
            return field.onChange(e);
          }}
          onWheel={(e) => (type === 'number' ? e.currentTarget.blur() : null)}
        />
      )}
    </Field>
  );
};

export default FormikInput;
