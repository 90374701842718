import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IMember } from 'services/types';

import DetailCard from '../../../../../Profile/components/Card';
import {
  StyledBlockBody,
  StyledBlockHeader,
  StyledBlockTitle,
  StyledBlockWrapper,
} from '../../styles';
import { BillingAddressLabels, BillingInfoLabels } from './constants';

interface BillingDataProps {
  member: IMember | undefined;
}

const BillingData: FC<BillingDataProps> = ({ member }) => {
  const { t } = useTranslation();

  const billingPerson = member?.additionalInfo.billingPerson;
  const billingName = `${billingPerson?.firstName} ${billingPerson?.middleName} ${billingPerson?.lastName}`;
  const billingAddress = member?.additionalInfo.billingAddress.street;

  return (
    <StyledBlockWrapper>
      <StyledBlockHeader>
        <StyledBlockTitle>{t('billing_data')}</StyledBlockTitle>
      </StyledBlockHeader>
      <StyledBlockBody>
        <DetailCard
          cardWidth="100%"
          labelWidth={'200px'}
          labels={BillingInfoLabels}
          title={t('billing_information').toString()}
          firstValue={billingName}
          secondValue={billingPerson?.email}
          thirdValue={billingPerson?.contactNumber}
        />
        <DetailCard
          cardWidth="100%"
          labelWidth={'130px'}
          labels={BillingAddressLabels}
          title={t('billing_address').toString()}
          firstValue={billingAddress}
          secondValue={''}
          thirdValue={''}
        />
      </StyledBlockBody>
    </StyledBlockWrapper>
  );
};

export default BillingData;
