import { useTranslation } from 'react-i18next';

import {
  HeaderContainer,
  HeaderRow,
  HeaderRowItem,
  PageHeader,
  Wrapper,
} from 'components/communities/styles';
import PeriodPicker from 'components/shared/PeriodPicker';
import { Header, TextRegular } from 'components/shared/Text';
import { COLORS } from 'constants/colors';
import { PERIOD } from 'constants/types';
import { useGetCommunityQuery } from 'services/communityManagement';
import { useGetCommunityElectricityTotalQuery } from 'services/pmoDashboardManagment';

import { useAppSelector } from 'utils/hooks';
import { getDateRange } from 'utils/url/getDateRange';

import { uniqBy } from '../../../utils';
import SummaryView from '../Charts/SummaryView';
import CommunityData from '../CommunityData';

const MyCommunity = () => {
  const { t } = useTranslation();
  const period = useAppSelector(
    (state) => state.dashboardPeriod.period,
  ) as PERIOD;
  const { data } = useGetCommunityQuery();
  const producersAmount = data && uniqBy(data?.producers, 'user')?.length;
  const { data: electricityTotal } = useGetCommunityElectricityTotalQuery({
    ...getDateRange(period),
    communityId: data?.id,
  });
  const uniqueUserIdsSet = new Set();

  const consumersAmount = data?.consumers?.filter((obj) => {
    if (uniqueUserIdsSet.has(obj?.user?._id)) {
      return false;
    } else {
      uniqueUserIdsSet.add(obj?.user?._id);
      return true;
    }
  }).length;

  return (
    <Wrapper>
      <PageHeader background={COLORS.BgContainer}>
        <HeaderContainer>
          <HeaderRow>
            <HeaderRowItem>
              <Header>{data?.communityName || t('community_name')}</Header>
            </HeaderRowItem>
            <HeaderRowItem position="flex-end">
              <TextRegular>{t('show_data_for')}</TextRegular>
              <PeriodPicker />
            </HeaderRowItem>
          </HeaderRow>
          <SummaryView
            pvPower={data?.pvPower}
            consumersAmount={consumersAmount}
            producersAmount={producersAmount}
            communityLocation={data?.location}
            period={period}
            consumptionTotal={electricityTotal?.consumption}
            productionTotal={electricityTotal?.production}
          />
        </HeaderContainer>
      </PageHeader>
      <CommunityData
        consumptionTotal={electricityTotal?.consumption}
        selfConsumption={electricityTotal?.selfConsumption}
        gridConsumption={electricityTotal?.gridConsumption}
        overproduction={electricityTotal?.overproduction}
      />
    </Wrapper>
  );
};

export default MyCommunity;
