import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';

import { SUPPORTED_LANGUAGES } from 'constants/global';

import tEnglish from './en.json';
import tFrench from './fr.json';

const resources = {
  [SUPPORTED_LANGUAGES.English]: {
    translation: tEnglish,
  },
  [SUPPORTED_LANGUAGES.Français]: {
    translation: tFrench,
  },
};

let defaultLang = SUPPORTED_LANGUAGES.Français;

i18n.use(initReactI18next).init({
  resources,
  lng: defaultLang,
  fallbackLng: SUPPORTED_LANGUAGES.English,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
