import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormikContext } from 'formik';

import { Select } from 'antd';

import { POWER_DROPDOWN_ITEMS } from 'components/communities/CreateCommunity/settings/constants';
import {
  StyledDropDownWrapper,
  StyledInputWrapper,
  StyledPowerOfProductionWrapper,
  StyledSelect,
} from 'components/communities/CreateCommunity/settings/styles';
import { FormInput } from 'components/shared/Input';
import { InputGroup } from 'components/shared/InputGroup';
import { POWER_OPTION } from 'constants/global';

import { initialValues } from '../../constants';

const ProductionPower = () => {
  const { errors, values, setFieldValue } =
    useFormikContext<typeof initialValues>();
  const [selectedPowerOption, setSelectedPowerOption] = useState<POWER_OPTION>(
    POWER_OPTION.KW,
  );
  const [maxPower, setMaxPower] = useState(values?.additionalInfo?.power);
  const { t } = useTranslation();

  const handleSelectChange = (value: unknown) => {
    setSelectedPowerOption(value as POWER_OPTION);

    if (maxPower) {
      const updatedMaxPower =
        value === POWER_OPTION.KW ? maxPower * 1000 : maxPower / 1000;
      setMaxPower(updatedMaxPower);
    }
  };

  const handleMaxPowerChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const power = +event.target.value;
    if (power) {
      if (selectedPowerOption === POWER_OPTION.MW) {
        await setFieldValue('additionalInfo.power', power);
      } else {
        await setFieldValue('additionalInfo.power', power / 1000);
      }
    }
    setMaxPower(power);
  };

  return (
    <StyledPowerOfProductionWrapper>
      <StyledInputWrapper>
        <InputGroup
          label={t('production_power').toString()}
          error={errors?.additionalInfo?.power}
        >
          <FormInput
            type="number"
            placeholder="500 KW - 3 MW"
            value={maxPower ?? undefined}
            isError={!!errors?.additionalInfo?.power}
            onChange={handleMaxPowerChange}
          />
        </InputGroup>
      </StyledInputWrapper>
      <StyledDropDownWrapper>
        <InputGroup isShowError={false}>
          <StyledSelect
            defaultValue={selectedPowerOption}
            onChange={handleSelectChange}
          >
            {POWER_DROPDOWN_ITEMS.map((key) => (
              <Select.Option key={key} value={key}>
                {key.toUpperCase()}
              </Select.Option>
            ))}
          </StyledSelect>
        </InputGroup>
      </StyledDropDownWrapper>
    </StyledPowerOfProductionWrapper>
  );
};

export default ProductionPower;
