import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CardDeliveredBody,
  CardHeader,
  CardMedium,
  ChartTitle,
} from 'components/communities/styles';

import AreaChart from './components/AreaChart';
import RingProgressInfo from './components/RingProgressInfo';

type RepartitionKeyDataType = {
  date: string;
  value: number | null;
};
interface RepartitionKeyProps {
  data: RepartitionKeyDataType[] | undefined;
  percent: number | undefined;
}

const RepartitionKey: FC<RepartitionKeyProps> = ({ data, percent }) => {
  const { t } = useTranslation();

  return (
    <CardMedium height={366}>
      <CardHeader>
        <ChartTitle>{t('repartition_key')}</ChartTitle>
      </CardHeader>
      <CardDeliveredBody verticalDirection="start">
        <AreaChart data={data} />
        <RingProgressInfo percent={percent} />
      </CardDeliveredBody>
    </CardMedium>
  );
};

export default RepartitionKey;
