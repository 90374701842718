import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from 'antd';

import { IMember } from 'services/types';

import DetailCard from '../../../../../Profile/components/Card';
import {
  EditIcon,
  StlyedProfileSupportText,
  StyledBlockBody,
  StyledBlockHeader,
  StyledBlockTitle,
  StyledBlockWrapper,
  StyledEditButton,
  StyledProfileSupportEmail,
} from '../../styles';
import { legalRepresentativeLabels, producerLabels } from './constants';

interface ProfileDataProps {
  member: IMember | undefined;
}

const ProfileData: FC<ProfileDataProps> = ({ member }) => {
  const { t } = useTranslation();

  const name = `${member?.firstName} ${member?.middleName} ${member?.lastName}`;
  const siretNumber = member?.additionalInfo.siretNumber;
  const location = member?.address.street;
  const legalPerson = member?.additionalInfo.legalPerson;
  const coordinates = member?.address.location.coordinates;
  const legalPersonName = `${legalPerson?.firstName} ${legalPerson?.middleName} ${legalPerson?.lastName}`;
  const personCoordinates = coordinates
    ? coordinates[0] + ', ' + coordinates[1]
    : null;

  return (
    <StyledBlockWrapper>
      <StyledBlockHeader>
        <StyledBlockTitle>{t('profile_data')}</StyledBlockTitle>
        <Tooltip
          placement="top"
          color="white"
          title={
            <StlyedProfileSupportText>
              {t('in_case_of_any_issues_please_contact_us_at')}
              <StyledProfileSupportEmail href="mailto:support@oyo.ai">
                support@oyo.ai
              </StyledProfileSupportEmail>
            </StlyedProfileSupportText>
          }
        >
          <StyledEditButton>
            <EditIcon /> {t('edit')}
          </StyledEditButton>
        </Tooltip>
      </StyledBlockHeader>
      <StyledBlockBody>
        <DetailCard
          cardWidth="100%"
          labelWidth={'150px'}
          labels={producerLabels}
          title={t('producer_data').toString()}
          firstValue={name}
          secondValue={siretNumber}
          thirdValue={location}
          fourthValue={personCoordinates}
        />
        <DetailCard
          cardWidth="100%"
          labelWidth={'200px'}
          labels={legalRepresentativeLabels}
          title={t('legal_representative').toString()}
          firstValue={legalPersonName}
          secondValue={legalPerson?.email}
          thirdValue={legalPerson?.position}
          fourthValue={legalPerson?.contactNumber}
        />
      </StyledBlockBody>
    </StyledBlockWrapper>
  );
};

export default ProfileData;
