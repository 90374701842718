import { FC, useState } from 'react';

import { Pie } from '@ant-design/plots';

import { COLORS } from 'constants/colors';

import { ChartDataType } from '../types';
import { StyledPieChartContainer } from './styles';

interface PieChartProps {
  data?: ChartDataType[];
  getPieColor: (type: string) => void;
  handleOnHover?: (type: any) => void;
  handleOnBlur?: (type: any) => void;
}

const PieChart: FC<PieChartProps> = ({
  data,
  getPieColor,
  handleOnHover,
  handleOnBlur,
}) => {
  const [hasAnimation, setHasAnimation] = useState(true);

  const onHover = (event: any) => {
    handleOnHover?.(event?.data?.data);
    setHasAnimation(false);
  };

  const onBlur = (event: any) => {
    handleOnBlur?.(event?.data?.data?.type);
    setHasAnimation(false);
  };

  const config: any = {
    data,
    animation: hasAnimation,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    pieStyle: ({ type }: { type: string }) => {
      return {
        fill:
          !data || data[0]?.isEmpty
            ? COLORS.TextDescription
            : getPieColor(type),
        stroke: COLORS.BgLayout,
        fillOpacity: 0.9,
      };
    },
    interactions: [
      {
        type: !data || data[0]?.isEmpty ? '' : 'element-active',
      },
    ],
    interaction: {
      elementHighlight: true,
    },
    legend: false,
    label: false,
    tooltip: false,
    state: {
      active: {
        style: {
          stroke: COLORS.BgContainer,
          fillOpacity: 1,
          inset: 4,
        },
      },
    },
    onReady: (plot: any) => {
      plot.on('element:mouseenter', onHover);
      plot.on('element:mouseleave', onBlur);
      plot.on('element:mouseout', onBlur);
    },
  };

  return (
    <StyledPieChartContainer>
      <Pie {...config} />
    </StyledPieChartContainer>
  );
};

export default PieChart;
