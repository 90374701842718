import styled from 'styled-components';

import { Checkbox } from 'antd';

import { SubHeader } from 'components/shared/Text';
import { COLORS } from 'constants/colors';

export const StyledPMOSettingInstruction = styled(SubHeader)``;

export const StyledCheckbox = styled(Checkbox)<{ isError?: boolean }>`
  & .ant-checkbox-inner {
    border-color: ${(props) => props.isError && COLORS.ErrorBase};
  }
`;

export const StyledCheckboxesWrapper = styled.div`
  width: 100%;
  align-items: start;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;
