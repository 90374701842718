import { styled } from 'styled-components';

import { ExpandAltOutlined } from '@ant-design/icons';

import { SubHeader } from 'components/shared/Text';
import { COLORS } from 'constants/colors';

export const TableWrapper = styled.div`
  padding: 0 32px;
`;

export const TableHeader = styled(SubHeader)`
  margin: 0;
  text-align: start;
  white-space: nowrap;
`;

export const StyledInputWrapper = styled.div`
  max-width: 325px;
  margin: 24px 0;
`;

export const StyledExpandAltOutlined = styled(ExpandAltOutlined)`
  color: ${COLORS.PrimaryBase};
`;

export const StyledEmptyDataWrapper = styled.div`
  width: 100%;
  height: 650px;
  background-color: ${COLORS.BgContainerDisabled};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSelfProductionWrapper = styled.div`
  display: flex;
  justify-content: left;
`;
