import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { API_URLS, REQUEST_METHOD } from '../constants/global';
import { baseQuery } from './baseQuery';
import { IDBPrmPrice } from './types';

export const prmPricesApi = createApi({
  reducerPath: 'prmPricesApi',
  baseQuery,
  endpoints: (builder) => ({
    getPrmByCommunityId: builder.query<IDBPrmPrice[], { communityId: string }>({
      query: ({ communityId }) => {
        return {
          url: `${API_URLS.GET_COMMUNITY_PRM_PRICES}/${communityId}`,
          method: REQUEST_METHOD.GET,
        };
      },
    }),
  }),
  tagTypes: ['prmPricesApi'],
});

export const { useGetPrmByCommunityIdQuery } = prmPricesApi;
