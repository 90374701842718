import React from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import {
  CardBody,
  CardHeader,
  CardTitle,
  MapContainer,
} from 'components/communities/Communities/styles';
import {
  getConsumersCoordinatesPresentable,
  getConsumersPerimeterCoordinates,
} from 'components/communities/Dashboard/utils';
import MapboxMap from 'components/shared/MapboxMap';
import { MAP_HEIGHT, SCREEN_CONTENT_SIZE } from 'constants/global';
import { IDBCommunity } from 'constants/types';
import { useGetConsumersQuery } from 'services/communityManagement';

const Container = styled(MapContainer)`
  width: ${SCREEN_CONTENT_SIZE};
  margin: 24px 0;
`;

const getMapConfigPresentable = (data: IDBCommunity) => ({
  height: MAP_HEIGHT,
  communitiesCoordinates: getConsumersCoordinatesPresentable(data),
  communitiesPerimeter: getConsumersPerimeterCoordinates(data),
});

interface IConsumersMap {
  userId?: string;
}

const ConsumersMap = ({ userId }: IConsumersMap) => {
  const { t } = useTranslation();
  const { isSuccess, data } = useGetConsumersQuery({ userId });

  return (
    <Container>
      <CardHeader>
        <CardTitle>{t('consumers_on_map')}</CardTitle>
      </CardHeader>
      <CardBody>
        {isSuccess && (
          <MapboxMap
            {...getMapConfigPresentable(
              data && data.consumers.length > 0 ? data : {},
            )}
          />
        )}
      </CardBody>
    </Container>
  );
};

export default ConsumersMap;
