import { TFunction } from 'i18next';

import { FinancialDataTypes } from 'components/communities/Dashboard/types';
import { mapProducerInvoiceStatus } from 'components/communities/constants';
import { COLORS } from 'constants/colors';
import { ROLES } from 'constants/global';
import { CONSUMER_INVOICE_STATUSES } from 'constants/types';

export const getFinancialChartInfoDataByType = (type: string, t: TFunction) => {
  switch (type) {
    case FinancialDataTypes.ALL_EARNINGS:
      return {
        types: ['earned_from_community', 'earned_tax_refund'],
        infoItems: [
          {
            title: t('earned_from_community').toString(),
            color: COLORS.Green6,
            unit: '€',
          },
          {
            title: t('earned_tax_refund').toString(),
            color: COLORS.geekBlue6,
            unit: '€',
          },
        ],
        legendItems: [
          {
            value: 'earned_from_community',
            name: t('earned_from_community'),
            marker: { symbol: 'square', style: { fill: COLORS.Green6 } },
          },
          {
            value: 'earned_tax_refund',
            name: t('earned_tax_refund'),
            marker: { symbol: 'square', style: { fill: COLORS.geekBlue6 } },
          },
        ],
      };
    case FinancialDataTypes.EARNED_FROM_COMMUNITY:
      return {
        types: ['paid_amount', 'overdue_amount', 'expected_amount'],
        infoItems: [
          {
            title: t('paid_amount_prm').toString(),
            color: COLORS.Green7,
            unit: '€',
          },
          {
            title: t('overdue_amount_prm').toString(),
            color: COLORS.ErrorBorder,
            unit: '€',
          },
          {
            title: t('expected_amount').toString(),
            color: COLORS.Green4,
            unit: '€',
          },
        ],
        legendItems: [
          {
            value: 'paid_amount',
            name: t('paid_amount'),
            marker: { symbol: 'square', style: { fill: COLORS.Green7 } },
          },
          {
            value: 'overdue_amount',
            name: t('overdue_amount'),
            marker: { symbol: 'square', style: { fill: COLORS.ErrorBorder } },
          },
          {
            value: 'expected_amount',
            name: t('expected_amount'),
            marker: { symbol: 'square', style: { fill: COLORS.Green4 } },
          },
        ],
      };
    case FinancialDataTypes.EARNED_TAX_REFUND:
      return {
        types: ['Turpe', 'Prime', 'Accise'],
        infoItems: [
          {
            title: t('expected_turpe_refund').toString(),
            color: COLORS.Blue5,
            unit: '€',
          },
          {
            title: t('expected_prime_refund').toString(),
            color: COLORS.Yellow5,
            unit: '€',
          },
          {
            title: t('expected_accise_refund').toString(),
            color: COLORS.Orange5,
            unit: '€',
          },
        ],

        legendItems: [
          {
            value: 'Turpe',
            name: 'TURPE',
            marker: { symbol: 'square', style: { fill: COLORS.Blue5 } },
          },
          {
            value: 'Prime',
            name: 'PRIME',
            marker: { symbol: 'square', style: { fill: COLORS.Yellow5 } },
          },
          {
            value: 'Accise',
            name: 'ACCISE',
            marker: { symbol: 'square', style: { fill: COLORS.Orange5 } },
          },
        ],
      };
  }
};

export const parseFinancialDataByType = (data: any, type: string) => {
  switch (type) {
    case FinancialDataTypes.ALL_EARNINGS:
      return data
        ?.filter(
          (item: any) =>
            (item.role === ROLES.CONSUMER &&
              item.status === CONSUMER_INVOICE_STATUSES.PAID) ||
            (item.role === ROLES.PRODUCER &&
              item.status === CONSUMER_INVOICE_STATUSES.ISSUED),
        )
        ?.map((item: any) => {
          if (
            item.role === ROLES.CONSUMER &&
            item.status === CONSUMER_INVOICE_STATUSES.PAID
          ) {
            return {
              ...item,
              status: 'earned_from_community',
            };
          }

          return {
            ...item,
            status: 'earned_tax_refund',
          };
        });
    case FinancialDataTypes.EARNED_FROM_COMMUNITY:
      return data
        ?.filter(
          (item: any) =>
            item.role === ROLES.CONSUMER &&
            mapProducerInvoiceStatus[item.status],
        )
        ?.map((item: any) => {
          return {
            ...item,
            status: mapProducerInvoiceStatus[item.status],
          };
        });
    case FinancialDataTypes.EARNED_TAX_REFUND:
      const filteredByProducerRole = data.filter(
        (item: any) => item.role === ROLES.PRODUCER,
      );
      return transformFinancialTaxRefundData(filteredByProducerRole);
  }
};

const transformFinancialTaxRefundData = (data: any) => {
  const transformedData: any = [];

  data.forEach((originalObject: any) => {
    Object.entries(originalObject).forEach(([key, value]) => {
      if (key === 'turpe' || key === 'prime' || key === 'accise') {
        transformedData.push({
          ...originalObject,
          status: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the key
          total: value,
        });
      }
    });
  });

  return transformedData;
};

export const getFinancialDataTotalByType = (data: any, type: string) => {
  switch (type) {
    case FinancialDataTypes.ALL_EARNINGS:
      return data?.reduce(
        (acc: any, currentDataItem: any) => {
          let indexByType = 0;
          if (currentDataItem.status === 'earned_from_community')
            indexByType = 0;
          if (
            currentDataItem.status === 'earned_tax_refund' ||
            currentDataItem.role === ROLES.PRODUCER
          )
            indexByType = 1;

          acc[indexByType] += currentDataItem.total;
          return acc;
        },
        [0, 0],
      );
    case FinancialDataTypes.EARNED_FROM_COMMUNITY:
      return data?.reduce(
        (acc: any, currentDataItem: any) => {
          let indexByType = 0;

          if (currentDataItem.status === 'paid_amount') indexByType = 0;
          if (currentDataItem.status === 'overdue_amount') indexByType = 1;
          if (currentDataItem.status === 'expected_amount') indexByType = 2;

          acc[indexByType] += currentDataItem.total;
          return acc;
        },
        [0, 0, 0],
      );
    case FinancialDataTypes.EARNED_TAX_REFUND:
      return data?.reduce(
        (acc: any, currentDataItem: any) => {
          let indexByType = 0;
          if (currentDataItem.status === 'Turpe') indexByType = 0;
          if (currentDataItem.status === 'Prime') indexByType = 1;
          if (currentDataItem.status === 'Accise') indexByType = 2;
          acc[indexByType] += currentDataItem.total;
          return acc;
        },
        [0, 0, 0],
      );
  }
};
