import { getAppRole } from 'utils';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import WidgetEarnings from 'components/communities/MyCommunity/components/WidgetEarnings';
import WidgetEmissions from 'components/communities/MyCommunity/components/WidgetEmissions';
import { Row } from 'components/communities/styles';
import { COLORS } from 'constants/colors';
import { ROLES } from 'constants/global';
import { PERIOD } from 'constants/types';
import { useGetCommunityEarningsQuery } from 'services/communityManagement';
import {
  useGetConsumerActualEmissionPreventedQuery,
  useGetEarningsQuery,
} from 'services/dashboardManagment';
import {
  useGetCommunitySelfConsumptionRatioQuery,
  useGetCommunitySelfProductionRatioQuery,
} from 'services/pmoDashboardManagment';

import { capitalizeFirstLetter } from 'utils/forms';
import { getDateRange } from 'utils/url/getDateRange';

import SummaryCard from './components/Card';
import Title from './components/Title';
import { VALUE_MAX } from './constants';

interface ISummaryView {
  communityLocation?: string;
  consumersAmount?: number;
  producersAmount?: number;
  pvPower?: string;
  selfConsumedEnergy?: number;
  gridEnergy?: number;
  selfProducedEnergy?: number;
  overproduction?: number;
  period: PERIOD;
  communityId?: string;
  consumptionTotal?: number;
  productionTotal?: number;
  userId?: string;
}

const SummaryView = ({
  communityLocation,
  consumersAmount,
  producersAmount,
  pvPower,
  period,
  communityId,
}: ISummaryView) => {
  const { t } = useTranslation();
  const { state: community } = useLocation();
  const role = getAppRole();
  const { data: selfProductionRatioPercent } =
    useGetCommunitySelfProductionRatioQuery({
      ...getDateRange(period),
      communityId,
    });
  const { data: selfConsumptionRatioPercent } =
    useGetCommunitySelfConsumptionRatioQuery({
      ...getDateRange(period),
      communityId,
    });
  const { data: communityEarningsData, isLoading: isLoadingCommunityEarnings } =
    useGetCommunityEarningsQuery({
      ...getDateRange(period),
      communityId,
    });
  const {
    data: actualEmissionPreventedData,
    isLoading: isLoadingActualEmissionPrevented,
  } = useGetConsumerActualEmissionPreventedQuery({
    ...getDateRange(period),
    userId: community?.pmoId,
  });
  const { data: userEarningsData, isLoading: isLoadingUserEarnings } =
    useGetEarningsQuery({
      ...getDateRange(period),
      communityId,
    });

  const communityEarningsAmount = communityEarningsData?.earnings
    ? communityEarningsData.earnings
    : 'N/A';
  const userEarningsAmount = userEarningsData?.earnings
    ? userEarningsData.earnings
    : 'N/A';

  return (
    <>
      <Title
        communityLocation={communityLocation}
        consumersAmount={consumersAmount}
        producersAmount={producersAmount}
        pvPower={pvPower}
      />
      <Row>
        <SummaryCard
          title={t('self_production_ratio').toUpperCase()}
          tooltipText={t('self_production_ratio_tooltip')}
          chartColors={[COLORS.Green5, COLORS.Orange5]}
          selfEnergyTitle={capitalizeFirstLetter(t('self_produced_energy'))}
          gridEnergyTitle={capitalizeFirstLetter(t('grid_energy'))}
          selfEnergy={selfProductionRatioPercent}
          gridEnergy={
            selfProductionRatioPercent && VALUE_MAX - selfProductionRatioPercent
          }
        />
        <WidgetEarnings
          isLoading={
            role === ROLES.PMO
              ? isLoadingCommunityEarnings
              : isLoadingUserEarnings
          }
          amount={
            role === ROLES.PMO ? communityEarningsAmount : userEarningsAmount
          }
        />
        <WidgetEmissions
          isLoading={isLoadingActualEmissionPrevented}
          amount={actualEmissionPreventedData?.actualEmissionPrevented}
        />
        <SummaryCard
          title={t('self_consumption_ratio').toUpperCase()}
          tooltipText={t('self_consumption_ratio_tooltip')}
          chartColors={[COLORS.geekBlue5, COLORS.TextTertiary]}
          selfEnergyTitle={capitalizeFirstLetter(t('self_consumed_energy'))}
          gridEnergyTitle={capitalizeFirstLetter(
            t('overproduction').toString(),
          )}
          selfEnergy={selfConsumptionRatioPercent}
          gridEnergy={
            selfConsumptionRatioPercent &&
            VALUE_MAX - selfConsumptionRatioPercent
          }
        />
      </Row>
    </>
  );
};

export default SummaryView;
