import { ChartsWrapper, Container } from 'components/communities/styles';
import { USER_ROLES } from 'constants/types';

import PmoConsumerDashboard from './components/ConsumerDashboard';
import PmoProducerDashboard from './components/ProducerDashboard';

const renderDashboard = (role: string | null) => {
  switch (role) {
    case USER_ROLES.PRODUCER:
      return <PmoProducerDashboard />;
    case USER_ROLES.CONSUMER:
      return <PmoConsumerDashboard />;
    default:
      return <PmoConsumerDashboard />;
  }
};

const DashboardTab = ({ role }: { role: string | null }) => {
  return (
    <>
      <ChartsWrapper>
        <Container>{renderDashboard(role)}</Container>
      </ChartsWrapper>
    </>
  );
};

export default DashboardTab;
