import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Tag } from 'antd/lib';

import { TFunction } from 'i18next';

import { ThunderboltIcon } from 'components/communities/Charts/SummaryView/components/Title/styles';
import { PRM_STATUSES } from 'constants/types';

import { getDateRange } from 'utils/date/date-range';

import PrmPrices from '../PrmPrices';
import {
  CalendarIcon,
  FileIcon,
  StyledDeactivationStatus,
  StyledDeletedStatus,
  StyledExpiredDuration,
  StyledPrmBgWrapper,
  StyledPrmInfoItem,
  StyledPrmNumber,
  StyledPrmTitle,
  StyledPrmWrapper,
} from './styles';

export const getPrmStatusLayout = (type: string, t: TFunction) => {
  switch (type) {
    case PRM_STATUSES.ACTIVE:
      return <Tag color="success">{t('active')}</Tag>;
    case PRM_STATUSES.PENDING:
      return <Tag color="warning">{t('pending')}</Tag>;
    case PRM_STATUSES.CONFLICT:
      return <Tag color="error">{t('in_conflict')}</Tag>;
    case PRM_STATUSES.DEACTIVATION:
      return (
        <StyledDeactivationStatus>{t('deactivation')}</StyledDeactivationStatus>
      );
    case PRM_STATUSES.DELETED:
      return <StyledDeletedStatus>{t('deleted')}</StyledDeletedStatus>;
  }
};

interface ConsumerPrmItemProps {
  prm: any;
  isHistoricalPricesShown: boolean;
}

const ConsumerPrmItem: FC<ConsumerPrmItemProps> = ({
  prm,
  isHistoricalPricesShown,
}) => {
  const { t } = useTranslation();

  return (
    <StyledPrmBgWrapper>
      <StyledPrmWrapper>
        <StyledPrmInfoItem width="25%">
          <StyledPrmNumber>
            {t('prm_with_value', { prmValue: prm.prm.prmValue })}
            <span style={{ marginLeft: 10 }}>
              {getPrmStatusLayout(prm.prm.status, t)}
            </span>
          </StyledPrmNumber>
        </StyledPrmInfoItem>
        <StyledPrmInfoItem width="25%">
          <ThunderboltIcon />
          <StyledPrmTitle>{t('power')}</StyledPrmTitle>
          {t('power_unit', { power: prm.prm.power })}
        </StyledPrmInfoItem>
        <StyledPrmInfoItem width="25%">
          <FileIcon />
          <StyledPrmTitle>{t('cppa')}</StyledPrmTitle>
          {prm.cppa}
        </StyledPrmInfoItem>
        <StyledPrmInfoItem width="25%">
          <CalendarIcon />
          <StyledPrmTitle>{t('duration')}</StyledPrmTitle>
          <StyledExpiredDuration>
            {getDateRange({
              startDate: '2022-01-01T13:16:55.550Z',
              endDate: '2024-01-01T13:16:55.550Z',
            })}
          </StyledExpiredDuration>
        </StyledPrmInfoItem>
      </StyledPrmWrapper>
      <PrmPrices
        prmPrice={prm}
        isHistoricalPricesShown={isHistoricalPricesShown}
      />
    </StyledPrmBgWrapper>
  );
};

export default ConsumerPrmItem;
