import { getAppRole } from 'utils';

import { ROLES } from 'constants/global';

import ConsumerEnergyData from '../ConsumerDashboard/components/EnergyData';
import ProducerEnergyData from '../ProducerDashboard/components/EnergyData';

const EnergyData = () => {
  const role = getAppRole();

  return (
    <>
      {role === ROLES.CONSUMER ? (
        <ConsumerEnergyData />
      ) : (
        <ProducerEnergyData />
      )}
    </>
  );
};

export default EnergyData;
