import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CardDeliveredBody,
  CardHeader,
  CardMedium,
  ChartTitle,
} from 'components/communities/styles';

import { LegendItemsType } from '../ElectricityConsumption/types';
import ColumnChart from './components/ColumnChart';
import CostEarnedInfo from './components/CostEarnedInfo';
import { CostEarnedBodyRow, StyledEmptyInfoText } from './styles';
import { ChartDataType } from './types';

interface CostEarnedProps {
  chartData?: ChartDataType[];
  totalCostEarned?: number;
  totals: number[];
  infoItems: any;
  title: string;
  getColumnColorByType: (type: string) => void;
  legendItems?: LegendItemsType[];
  isMonthlyStep: boolean;
}

const CostEarned: FC<CostEarnedProps> = ({
  chartData,
  totals,
  infoItems,
  getColumnColorByType,
  title,
  legendItems,
  totalCostEarned,
  isMonthlyStep,
}) => {
  const { t } = useTranslation();

  return (
    <CardMedium height={367}>
      <CardHeader>
        <ChartTitle>{title}</ChartTitle>
      </CardHeader>
      <CardDeliveredBody verticalDirection="center">
        <CostEarnedBodyRow>
          {!isMonthlyStep && (
            <StyledEmptyInfoText>
              {t('select_period_with_monthly_step')}
            </StyledEmptyInfoText>
          )}
          <ColumnChart
            legendItems={legendItems}
            getColumnColorByType={getColumnColorByType}
            data={chartData}
          />
          <CostEarnedInfo
            totalCostEarned={totalCostEarned}
            data={totals}
            infoItems={infoItems}
          />
        </CostEarnedBodyRow>
      </CardDeliveredBody>
    </CardMedium>
  );
};

export default CostEarned;
