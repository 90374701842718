import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import CardLine from 'components/communities/Communities/components/CardLine';
import {
  Card,
  CardBody,
  CardBodyColumn,
  CardBodyWrapper,
  CardChartContainer,
  CardChartTitle,
  CardHeader,
  CardTitle,
  ChartCardLine,
  ChartCardLineItem,
  ChartWrapper,
  Content,
  EnergyIconOrange,
  EnergyIconYellow,
  LegendText,
  PopoverIcon,
  TextWrapper,
  popoverOverlayStyles,
} from 'components/communities/Communities/styles';
import {
  prepareNextUrl,
  preparePieChartData,
} from 'components/communities/Communities/utils';
import CommunityCardChart from 'components/shared/PieChart';
import Popover from 'components/shared/Popover';
import { TextBold, TextRegular } from 'components/shared/Text';
import { EnergyTypes } from 'constants/energy-types';
import AppRoutes from 'constants/routes';
import { Community } from 'constants/types';
import { useGetCommunitySelfConsumptionRatioQuery } from 'services/pmoDashboardManagment';

import { getUniqeConsumersById, uniqBy } from 'utils/index';

const communitiesWithMockData = ['651beaf9dd5ec2d0948d1b10'];

interface ICommunityCard {
  community: Community;
}

const CommunityCard = ({ community }: ICommunityCard) => {
  const [hoveredType, setHoveredType] = useState(EnergyTypes.NONE);

  const navigate = useNavigate();
  const { t } = useTranslation();
  let selfCommunityConsumptionRatio;
  if (communitiesWithMockData.includes(community.id)) {
    // temporary solution until we have Enedis data for all communities
    const { data } =
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useGetCommunitySelfConsumptionRatioQuery({ communityId: community.id });
    selfCommunityConsumptionRatio = data;
  }

  const consumersAmount =
    community && getUniqeConsumersById(community?.consumers).length;
  const producersAmount =
    community && uniqBy(community?.producers, 'user')?.length;

  const communityLocation = community?.location?.split(',')?.[0];
  const pvPower = `${community?.pvPower} MW`;
  const perimeter = `${community?.perimeter} km`;

  const nextUrl = prepareNextUrl(community.communityName);

  return (
    <Card
      key={community.id}
      onClick={() =>
        navigate(
          `${AppRoutes.Communities}/${nextUrl}?name=${community.communityName}`,
          {
            state: { ...community, nextUrl: community.communityName },
          },
        )
      }
    >
      <CardHeader>
        <CardTitle>{community.communityName}</CardTitle>
      </CardHeader>
      <CardBody>
        <CardBodyWrapper>
          <CardBodyColumn>
            <CardBodyWrapper>
              <CardBodyWrapper size={1}>
                <TextRegular>{t('location')}</TextRegular>
              </CardBodyWrapper>
              <CardBodyWrapper size={1}>
                <TextWrapper>
                  <TextBold>{communityLocation}</TextBold>
                </TextWrapper>
              </CardBodyWrapper>
            </CardBodyWrapper>
            <CardLine title={t('consumers')} value={consumersAmount} />
            <CardLine title={t('producers')} value={producersAmount} />
            <CardLine title={t('pv_power')} value={pvPower} />
            <CardLine title={t('perimeter')} value={perimeter} />
            <CardLine title={t('pmo')} />
            <TextBold>{community?.pmoOrgName}</TextBold>
            <TextWrapper width="200px">
              <TextRegular>{community.pmoEmail}</TextRegular>
            </TextWrapper>
          </CardBodyColumn>
        </CardBodyWrapper>
        <CardBodyWrapper>
          <CardChartContainer>
            <CardChartTitle>
              <TextBold>{t('self_consumption_ratio_ttm')}</TextBold>
              <Popover
                context={t('self_consumption_ratio_tooltip_content')}
                overlayStyles={popoverOverlayStyles}
              >
                <PopoverIcon />
              </Popover>
            </CardChartTitle>
            <ChartWrapper>
              <CommunityCardChart
                chartData={preparePieChartData(
                  community,
                  selfCommunityConsumptionRatio,
                )}
                handleOnHover={setHoveredType}
                handleOnBlur={() => setHoveredType(EnergyTypes.NONE)}
              />
            </ChartWrapper>
            <Content>
              <ChartCardLine>
                <ChartCardLineItem>
                  <EnergyIconYellow />
                  <LegendText bold={hoveredType === EnergyTypes.SELF_CONSUMED}>
                    {t('self_consumed_energy')}
                  </LegendText>
                </ChartCardLineItem>
                <ChartCardLineItem>
                  <TextBold>
                    {selfCommunityConsumptionRatio ||
                      community.selfConsumedEnergy}
                  </TextBold>
                  <TextRegular>{' %'}</TextRegular>
                </ChartCardLineItem>
              </ChartCardLine>
              <ChartCardLine>
                <ChartCardLineItem>
                  <EnergyIconOrange />
                  <LegendText bold={hoveredType === EnergyTypes.GRID_ENERGY}>
                    {t('grid_energy')}
                  </LegendText>
                </ChartCardLineItem>
                <ChartCardLineItem>
                  <TextBold>
                    {100 - selfCommunityConsumptionRatio ||
                      community.gridEnergy}
                  </TextBold>
                  <TextRegular>{' %'}</TextRegular>
                </ChartCardLineItem>
              </ChartCardLine>
            </Content>
          </CardChartContainer>
        </CardBodyWrapper>
      </CardBody>
    </Card>
  );
};

export default CommunityCard;
