import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Space } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';

import { getPrmStatusTagByInvoiceType } from 'components/communities/Dashboard/components/ConsumerDashboard/components/FinancialDataTab/components/InvoiceHistoryTable/columns';
import { Card } from 'components/communities/Dashboard/components/ProducerDashboard/components/FinancialDataTab/styles';
import {
  fetchAndDownloadPdfFile,
  fetchViewerPdfFile,
} from 'components/communities/Dashboard/utils';
import DownloadAction from 'components/communities/components/DownloadAction';
import ViewAction from 'components/communities/components/ViewAction';
import { MAX_PAGINATION_PAGE_SIZE } from 'components/communities/constants';
import Loader from 'components/shared/Loader';
import PdfViewer from 'components/shared/PdfViewer';
import { TextRegular } from 'components/shared/Text';
import { useGetCommunityInvoiceHistoryQuery } from 'services/communityManagement';

import { columns } from './columns';
import { statusFilters } from './constants';
import {
  FinancialFollowUpTableComponent,
  StyledCommunityWrapper,
} from './styles';

const FinancialFollowUpTable = () => {
  const { t } = useTranslation();
  const {
    data: tableData,
    isLoading,
    isSuccess,
  } = useGetCommunityInvoiceHistoryQuery();
  const [prmNameFilters, setPrmNameFilters] = useState<any>([]);
  const [consumerNameFilters, setConsumerNameFilters] = useState<any>([]);
  const [pdfFile, setPdfFile] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    },
  );

  useEffect(() => {
    const uniquePrmArray = new Set(
      tableData?.map((item: any) => item.prmName || item.prmNumber),
    );
    const uniquePrmsFilter = Array.from(uniquePrmArray).map((item) => ({
      text: item,
      value: item,
    }));
    setPrmNameFilters(uniquePrmsFilter);
  }, [tableData]);

  useEffect(() => {
    const uniqueConsumerNameArray = new Set(
      tableData?.map((item: any) => item.consumerName),
    );
    const uniqueConsumerNameFilter = Array.from(uniqueConsumerNameArray).map(
      (item) => ({
        text: item,
        value: item,
      }),
    );
    setConsumerNameFilters(uniqueConsumerNameFilter);
  }, [tableData]);

  const handleViewPdfFile = async (
    downloadLink: string,
    dateCreated: string,
  ) => {
    try {
      const url: any | undefined = await fetchViewerPdfFile(
        downloadLink,
        dateCreated,
      );

      if (url) {
        setPdfFile(url);
        setIsModalOpen(true);
      }
      if (!url) {
        setPdfFile('');
        setIsModalOpen(false);
      }
    } catch (error) {
      setIsModalOpen(false);
    }
  };
  const handlePaginationChange = (page: number, pageSize: number) => {
    setTablePagination((prev) => ({ ...prev, current: page, pageSize }));
  };

  const modifiedColumns: ColumnsType<any> = [
    ...columns(t),
    {
      title: t('consumer_name').toUpperCase(),
      dataIndex: 'consumerName',
      filters: consumerNameFilters,
      onFilter: (value, record) => record.consumerName.includes(String(value)),
    },

    {
      title: t('prm').toUpperCase(),
      dataIndex: 'prmName',
      filters: prmNameFilters,
      onFilter: (value, record) => {
        return (
          record.prmName.includes(String(value)) ||
          record.prmNumber.includes(String(value))
        );
      },
      render: (val, { prmNumber }) => {
        // show prmNumber if there no prmName
        return val || prmNumber;
      },
    },
    {
      title: t('invoice_amount_eur').toUpperCase(),
      width: '15%',
      dataIndex: 'invoiceAmount',
      sorter: (firstnInvoice, nextInvoice) =>
        parseInt(firstnInvoice.invoiceAmount) -
        parseInt(nextInvoice.invoiceAmount),
      render: (amount) => amount.toFixed(2),
    },
    {
      title: t('status').toUpperCase(),
      dataIndex: 'status',
      filters: statusFilters,
      onFilter: (value, record) => record.status.includes(String(value)),
      render: (status) => getPrmStatusTagByInvoiceType(status, t),
    },

    {
      title: t('action').toUpperCase(),
      width: '10%',
      render: ({ downloadLink, dateCreated }) => (
        <Space>
          <div
            onClick={() => fetchAndDownloadPdfFile(downloadLink, dateCreated)}
          >
            <DownloadAction />
          </div>
          <div onClick={() => handleViewPdfFile(downloadLink, dateCreated)}>
            <ViewAction />
          </div>
        </Space>
      ),
    },
  ];
  const hasEnoughDataToShowPagination =
    tableData && tableData?.length > MAX_PAGINATION_PAGE_SIZE;
  return (
    <>
      <StyledCommunityWrapper>
        {isLoading ? (
          <Loader />
        ) : isSuccess && tableData && tableData?.length > 0 ? (
          <FinancialFollowUpTableComponent
            columns={modifiedColumns}
            dataSource={tableData}
            pagination={
              hasEnoughDataToShowPagination
                ? {
                    ...tablePagination,
                    position: ['bottomCenter'],
                    showSizeChanger: true,
                    onChange: handlePaginationChange,
                    showTotal: (total) =>
                      `${t('total')} ${total} ${t('items')}`,
                  }
                : false
            }
          />
        ) : (
          <Card isEmpty>
            <TextRegular>{t('no_invoices').toUpperCase()}</TextRegular>
          </Card>
        )}
      </StyledCommunityWrapper>
      <PdfViewer
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        pdfFile={pdfFile}
      />
    </>
  );
};

export default FinancialFollowUpTable;
