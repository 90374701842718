export const consumerLabels = {
  first: 'consumer_name',
  second: 'siret_number',
  third: 'location',
  fourth: 'accise_type',
};

export const legalRepresentativeLabels = {
  first: 'legal_person_name',
  second: 'legal_person_email_address',
  third: 'legal_person_position',
  fourth: 'legal_person_contact_number',
};
