import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import ChartInfo from 'components/communities/Charts/ChartInfo';

import { CardHeader, ChartTitle } from '../../styles';
import ColumnChart from '../ColumnChart';
import FinancialFollowupDropdown from './component/Dropdown';
import { Card, CardBody, ChartContainer } from './styles';

interface FinancialFollowupProps {
  legendItems: any;
  infoItems: any;
  data?: any;
  infoData: number[];
  selectValue: string;
  hasData: boolean;
  setSelectValue: Dispatch<SetStateAction<any>>;
  selectItems: {
    key: string;
    label: string;
  }[];
  withSelect?: boolean;
}

const FinancialFollowup: FC<FinancialFollowupProps> = ({
  selectValue,
  setSelectValue,
  selectItems,
  data,
  legendItems,
  infoItems,
  infoData,
  hasData,
  withSelect,
}) => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader>
        <ChartTitle>{t('financial_followup')}</ChartTitle>
        {withSelect && (
          <FinancialFollowupDropdown
            currentPrm={selectValue}
            items={selectItems}
            onSelect={setSelectValue}
          />
        )}
      </CardHeader>
      <CardBody>
        <ChartInfo data={infoData} infoItems={infoItems} />
        <ChartContainer>
          <ColumnChart
            xAxis={true}
            hasData={hasData}
            data={data}
            legendItems={legendItems}
            removeShowingDataByFullPeriod={!hasData}
          />
        </ChartContainer>
      </CardBody>
    </Card>
  );
};

export default FinancialFollowup;
