import { FC } from 'react';

import { getFormattedInfoChartValue } from 'components/communities/utils';

import { formatNumberWithSpace } from '../ElectricityConsumption/utils';
import {
  StyledInfoBlock,
  StyledInfoPower,
  StyledInfoRow,
  StyledInfoTitle,
  StyledInfoUnit,
} from './styles';

interface InfoItem {
  title: string;
  color: string;
  unit: string;
}

interface ChartInfoProps {
  data: number[];
  infoItems: InfoItem[];
  fullWidth?: boolean;
}

interface ChartInfoItemProps {
  borderColor: string;
  title: string;
  amount?: number | string;
  unit: string;
  fullWidth?: boolean;
}

const ChartInfoItem: FC<ChartInfoItemProps> = ({
  borderColor,
  title,
  amount,
  unit,
  fullWidth,
}) => {
  if (!title) {
    return <></>;
  }
  return (
    <StyledInfoBlock fullWidth={fullWidth} borderColor={borderColor}>
      <StyledInfoTitle>{title}</StyledInfoTitle>
      <StyledInfoPower>
        {formatNumberWithSpace(amount)} <StyledInfoUnit>{unit}</StyledInfoUnit>
      </StyledInfoPower>
    </StyledInfoBlock>
  );
};

const ChartInfo: FC<ChartInfoProps> = ({ data, infoItems, fullWidth }) => (
  <StyledInfoRow>
    {infoItems.map((infoItem, idx) => (
      <ChartInfoItem
        fullWidth={fullWidth}
        key={idx}
        title={infoItem.title}
        borderColor={infoItem.color}
        amount={getFormattedInfoChartValue(data?.[idx], data, true)}
        unit={infoItem.unit}
      />
    ))}
  </StyledInfoRow>
);

export default ChartInfo;
