import { styled } from 'styled-components';

import {
  CalendarTwoTone,
  FileDoneOutlined,
  LineChartOutlined,
} from '@ant-design/icons';

import { COLORS } from 'constants/colors';

export const customTooltipStyles = { display: 'inline-block', height: '16px' };
export const customAddPriceBtnStyles = {
  width: 'auto',
  fontSize: '14px',
  padding: '0 45px',
  height: 'auto',
  borderRadius: '4px',
};

export const StyledPricesWrapper = styled.div`
  background-color: ${COLORS.BgLayout};
`;

export const StyledPricesContainer = styled.div`
  background-color: ${COLORS.BgLayout};
  display: flex;
  border-top: 1px solid ${COLORS.ColorSplit};
  margin: 0 8px;
  padding: 16px 24px;
`;

export const StyledUpcomingPricesContainer = styled.div`
  background-color: #0000000f;
  display: flex;
  margin: 0 8px 24px 8px;
  padding: 16px 24px;
`;

export const StyledPriceCol = styled.div<{ width?: string }>`
  width: ${(props) => props.width};
`;

export const StyledHistoricalPriceCol = styled.div`
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  display: flex;
`;

export const StyledPricesTitleContainer = styled.div`
  display: flex;
`;

export const StyledPricesTitle = styled.span`
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${({ color }) => color || COLORS.TextSecondary};
  display: inline-block;
`;

export const StyledCalendarIcon = styled(CalendarTwoTone).attrs(() => ({
  twoToneColor: COLORS.Green6,
}))`
  margin-right: 6px;
`;

export const StyledIndexIcon = styled(LineChartOutlined)`
  color: ${COLORS.Green6};
  margin-right: 6px;
`;

export const StyledPriceContainer = styled.div`
  margin: 0 14px;
`;

export const StyledPriceTypeText = styled.span`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
  display: inline-block;
`;

export const StyledPriceUnitText = styled.span`
  font-size: 12px;
  font-weight: 400;
`;

export const StyledPriceValue = styled.div`
  font-size: 14px;
  font-weight: 600;
  background: ${COLORS.BgContainer};
  padding: 8px 12px;
`;

export const StyledHistoricalPriceConatiner = styled.div`
  display: flex;
`;

export const StyledHistoricalPriceWrapper = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
`;

export const StyledHistoricalPricesContainer = styled.div<{ isShown: boolean }>`
  background-color: ${COLORS.BgLayout};
  display: flex;
  border-top: ${({ isShown }) =>
    isShown ? `1px solid ${COLORS.ColorSplit}` : 'none'};
  margin: 0 8px;
  padding: ${({ isShown }) => (isShown ? '16px 24px' : '0 24px')};
  height: ${({ isShown }) => (isShown ? '100px' : '0')};
  transition: height 0.25s;
  overflow: hidden;
`;

export const StyledConsumerWrapper = styled.div`
  background-color: ${COLORS.BgContainer};
  padding: 16px 0;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
`;

export const StyledConsumerSubtitle = styled.div`
  color: rgba(0, 0, 0, 0.65);
`;

export const StyledConsumerName = styled.span`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin-left: 16px;
`;

export const StyledConsumerInfoItem = styled.div<{ width?: string }>`
  width: ${(props) => props.width};
`;

export const FileIcon = styled(FileDoneOutlined)`
  color: ${COLORS.Green6};
  font-size: 12px;
  margin-right: 6px;
`;

export const StyledDurationText = styled.span`
  color: ${COLORS.ErrorBase};
`;

export const StyledDivider = styled.div<{ isExpanded: boolean }>`
  border-top: ${({ isExpanded }) =>
    isExpanded ? '1px solid #0000000F' : 'none'};
  margin-top: ${({ isExpanded }) => (isExpanded ? '16px' : '0')};
  padding-top: ${({ isExpanded }) => (isExpanded ? '8px' : '0')};
`;

export const StyledNoPricesContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 24px;
`;

export const StyledNoPricesText = styled.div`
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #000000a6;
`;
