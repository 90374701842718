import styled from 'styled-components';

import { COLORS } from 'constants/colors';

export const Card = styled.div<{ isEmpty?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 1376px;
  height: 316px;
  background-color: ${({ isEmpty }) =>
    isEmpty ? COLORS.Grey2 : COLORS.BgContainer};

  border-radius: 6px;
  border: ${({ isEmpty }) =>
    isEmpty ? 'none' : '1px solid rgba(0, 0, 0, 0.06)'};

  overflow-wrap: break-word;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 48px;
`;

export const StyledTabs = styled.div`
  width: 1440px;
  box-sizing: border-box;
  background-color: ${COLORS.BgLayout};
`;
