import { useParams } from 'react-router-dom';

import { useGetMemberByIdQuery } from 'services/pmoMemberDashboardManagment';

import { StyledTabWrapper } from '../../../Profile/styles';
import BillingData from './components/BillingData';
import ProfileData from './components/ProfileData';
import Security from './components/Security';
import { StyledContainer } from './styles';

const ProducerProfileTab = () => {
  const { id } = useParams();
  const { data: member } = useGetMemberByIdQuery(id);

  return (
    <StyledTabWrapper>
      <StyledContainer>
        <ProfileData member={member} />
        <BillingData member={member} />
        <Security />
      </StyledContainer>
    </StyledTabWrapper>
  );
};

export default ProducerProfileTab;
