import { createApi } from '@reduxjs/toolkit/query/react';

import { API_URLS, REQUEST_METHOD } from 'constants/global';

import { baseQuery } from './baseQuery';
import {
  CreateConsumerPayload,
  CreateProducerPayload,
  IDBUser,
  IMember,
} from './types';

export const createConsumer = createApi({
  reducerPath: 'createConsumer',
  baseQuery,
  endpoints: (builder) => ({
    createConsumer: builder.mutation<any, CreateConsumerPayload>({
      query: (data) => ({
        url: API_URLS.MEMBERS,
        method: REQUEST_METHOD.POST,
        body: data,
      }),
    }),
    createProducer: builder.mutation<any, CreateProducerPayload>({
      query: (data) => ({
        url: API_URLS.MEMBERS,
        method: REQUEST_METHOD.POST,
        body: data,
      }),
    }),
    getMembers: builder.query<any[], void>({
      query: () => ({
        url: API_URLS.MEMBERS,
        method: REQUEST_METHOD.GET,
      }),
    }),
    updateMember: builder.mutation<IMember, IMember>({
      query: (data) => ({
        url: API_URLS.MEMBER,
        method: REQUEST_METHOD.PUT,
        body: data,
      }),
    }),
  }),
  tagTypes: ['members'],
});

export const {
  useCreateConsumerMutation,
  useGetMembersQuery,
  useCreateProducerMutation,
  useUpdateMemberMutation,
} = createConsumer;
