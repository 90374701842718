import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { emptyCommunityData } from 'components/communities/Dashboard/components/ConsumerDashboard/dummyData/CommunityData';
import {
  CardHeader,
  CardMedium,
  ChartTitle,
} from 'components/communities/styles';
import { COLORS } from 'constants/colors';

import CommunityDataChart from './components/CommunityDataChart';
import CommunityDataInfo from './components/CommunityDataInfo';
import { CardBody, CommunityDataRow } from './styles';
import { BenchMarkDataType, CommunityDataType } from './types';

const chartColors = [COLORS.Yellow5, '#3ECC72' as COLORS, COLORS.Orange5];

interface CommunityDataProps {
  benchMarkData?: BenchMarkDataType;
  data?: CommunityDataType;
  chartData: any;
  communityName?: string;
}

const CommunityData: FC<CommunityDataProps> = ({
  benchMarkData,
  data,
  chartData,
  communityName,
}) => {
  const { t } = useTranslation();

  const isChartDataEmpty = chartData.every(
    (item: any) => !!item.value === false,
  );

  return (
    <>
      <CardMedium height={366}>
        <CardHeader>
          <ChartTitle>
            {`${t('community_data')} ${communityName || ''}`}
          </ChartTitle>
        </CardHeader>
        <CardBody>
          <CommunityDataInfo data={data} />
          <CommunityDataRow>
            <CommunityDataChart
              chartData={isChartDataEmpty ? emptyCommunityData : chartData}
              data={data}
              color={isChartDataEmpty ? [COLORS.Grey3] : chartColors}
            />
          </CommunityDataRow>
        </CardBody>
      </CardMedium>
    </>
  );
};

export default CommunityData;
