import styled from 'styled-components';

import { SubHeader } from 'components/shared/Text';
import { COLORS } from 'constants/colors';

export const StyledLocationWrapper = styled.div`
  width: 100%;
  display: flex;
  flex: 12;
  gap: 20px;
  align-items: flex-start;
`;

export const StyledPostalCode = styled.div`
  width: 92px;
`;

export const StyledCity = styled.div`
  width: 330px;
`;

export const StyledCommunityTitle = styled(SubHeader)<{
  isUppercase?: boolean;
}>`
  width: 100%;
  text-align: left;
  margin: 20px 0;
  color: ${COLORS.TextBase};
  text-transform: ${(props) => props.isUppercase && 'uppercase'};
`;

export const StyledCommunityLabel = styled(SubHeader)`
  text-align: left;
  width: 100%;
  margin: 0px;
`;

export const StyledCoordinateWrapper = styled.div`
  display: flex;
  flex: 6;
`;

export const MapWrapper = styled.div`
  margin-bottom: 20px;
`;
