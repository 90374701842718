import { useState } from 'react';

import { Modal } from 'antd';

import { BtnPrimary } from 'components/shared/Button';

import {
  CancelIcon,
  InfoIcon,
  StyledModalBody,
  StyledModalConfirmButton,
  StyledModalSubtitle,
  StyledModalTitle,
  SuccessIcon,
  WarningIcon,
} from './styles';

interface IModalProps {
  messageType: string;
  title: string;
  subTitle: string;
  btnText: string;
  handleBtn?: () => void;
  btnWidth?: string;
}

const ModalMessage = ({
  messageType,
  title,
  subTitle,
  btnText,
  handleBtn,
  btnWidth = '49px',
}: IModalProps) => {
  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      zIndex={1000000}
      footer={[<></>]}
      closable={false}
    >
      <StyledModalBody>
        {
          {
            success: <SuccessIcon />,
            cancel: <CancelIcon />,
            warining: <WarningIcon />,
            info: <InfoIcon />,
            none: <></>,
          }[messageType]
        }
        <StyledModalTitle>{title}</StyledModalTitle>
        <StyledModalSubtitle>{subTitle}</StyledModalSubtitle>
        <StyledModalConfirmButton width={btnWidth}>
          <BtnPrimary onClick={handleBtn ? handleBtn : handleOk}>
            {btnText}
          </BtnPrimary>
        </StyledModalConfirmButton>
      </StyledModalBody>
    </Modal>
  );
};

export default ModalMessage;
