import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';

import { HeaderContainer } from 'components/communities/styles';
import { HeaderRow, HeaderRowItem } from 'components/communities/styles';
import PeriodPicker from 'components/shared/PeriodPicker';
import { Header, TextRegular } from 'components/shared/Text';
import { ROLES } from 'constants/global';
import { useGetMemberByIdQuery } from 'services/pmoMemberDashboardManagment';
import { useGetPrmByUserIdQuery } from 'services/prmManagement';

import DetailCard from './components/Card';
import StatusCard from './components/Card/StatusCard';
import PmoMemberTitle from './components/Title';
import {
  consumerLabels,
  legalRepresentativeLabels,
  producerLabels,
} from './constants';
import { DetailCardsRow, SummaryDetailsBlock } from './styles';

export const PmoMemberSummaryDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: member } = useGetMemberByIdQuery(id);
  const [searchParams] = useSearchParams();
  const { community } = Object.fromEntries(searchParams);

  const { memberName, legalPersonName } = useMemo(() => {
    return {
      memberName: `${member?.firstName} ${member?.middleName} ${member?.lastName}`,
      legalPersonName: `${member?.additionalInfo?.legalPerson?.firstName} ${member?.additionalInfo?.legalPerson?.middleName} ${member?.additionalInfo?.legalPerson?.lastName}`,
    };
  }, [member]);

  const { data: prmData = [] } = useGetPrmByUserIdQuery({
    userId: member?._id,
  });

  return (
    <>
      <SummaryDetailsBlock>
        <HeaderContainer>
          <HeaderRow>
            <HeaderRowItem>
              <Header>{memberName}</Header>
            </HeaderRowItem>
            <HeaderRowItem position="flex-end">
              <TextRegular>{t('show_data_for')}</TextRegular>
              <PeriodPicker />
            </HeaderRowItem>
          </HeaderRow>
          <PmoMemberTitle
            location={member?.address.city}
            type={member?.additionalInfo?.type || member?.role}
            power={member?.additionalInfo?.power}
            role={member?.role}
            community={community}
            prmNumbers={member?.additionalInfo?.prms?.length}
          />
          <DetailCardsRow>
            <DetailCard
              labelWidth={'100px'}
              labels={
                member?.role === ROLES.PRODUCER
                  ? producerLabels(t)
                  : consumerLabels(t)
              }
              title={
                member?.role === ROLES.CONSUMER
                  ? t('consumer_data').toString()
                  : t('producer_data').toString()
              }
              firstValue={memberName}
              secondValue={member?.additionalInfo?.siretNumber}
              thirdValue={member?.address.street}
            />
            <DetailCard
              labelWidth={'200px'}
              labels={legalRepresentativeLabels(t)}
              title={t('legal_representative').toString()}
              firstValue={legalPersonName}
              secondValue={member?.additionalInfo?.legalPerson?.email}
              thirdValue={member?.additionalInfo?.legalPerson?.position}
              fourthValue={member?.additionalInfo?.legalPerson?.contactNumber}
            />
            <StatusCard
              role={member?.role}
              status={member?.status}
              title={t('actions_status').toString()}
              prms={prmData}
            />
          </DetailCardsRow>
        </HeaderContainer>
      </SummaryDetailsBlock>
    </>
  );
};

export default PmoMemberSummaryDetails;
