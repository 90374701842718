import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFormikContext } from 'formik';

import { initialValues } from 'components/members/createConsumer/constants';
import { Prices } from 'components/members/types';
import FormikInput from 'components/shared/Input/FormikInput';
import { TextRegular } from 'components/shared/Text';
import CustomTooltip from 'components/shared/Tooltip';

import { useAppSelector } from 'utils/hooks';

import { onKeyPressOnlyNumbersWithDot } from '../../../../../../utils/forms';
import { ConsumerType as ConsumerTypeEnums } from '../../../ConsumerType/constants';
import { PriceDetail } from '../constants';
import {
  StyledInputWrapper,
  StyledInputsRow,
  StyledPriceDetails,
  StyledPriceDetailsWrapper,
  StyledPriceTitle,
  StyledRushInput,
  StyledRushInputWrapper,
  StyledRushTitleWrapper,
  StyledTitle,
  StyledTitlesRow,
  StyledTypeWrapper,
  Title,
} from './styles';

type PriceDetailsProps = {
  priceTypes: PriceDetail[];
  titles: string[];
  index: number;
  priceType: string;
};

const PriceDetails: React.FC<PriceDetailsProps> = ({
  priceTypes,
  priceType,
  titles,
  index,
}) => {
  const { t } = useTranslation();
  const { values } = useFormikContext<typeof initialValues>();
  const { consumerType } = useAppSelector((state) => state.consumer);
  const isDynamic = priceType === Prices.dynamic;

  const dynamicValues = (values.additionalInfo.prms[index]?.gridPrice as any)
    ?.dynamic;
  return (
    <StyledPriceDetailsWrapper>
      <StyledPriceDetails>
        <StyledTitlesRow marginTop={isDynamic ? 23 : 0}>
          {titles.map((item) => (
            <StyledPriceTitle key={item}>{t(item)}</StyledPriceTitle>
          ))}
        </StyledTitlesRow>
        <StyledInputsRow>
          {priceTypes.map(({ key, title, tooltip, key2 }) => (
            <StyledTypeWrapper width={isDynamic ? 140 : 214} key={key}>
              <StyledTitle>
                <Title>{t(title)}</Title>
                {tooltip && isDynamic && (
                  <CustomTooltip
                    tooltip={<TextRegular>{t(tooltip)}</TextRegular>}
                  />
                )}
              </StyledTitle>
              <StyledInputWrapper>
                <FormikInput
                  placeholder="0.530 EUR / kWh"
                  suffix={
                    (
                      priceType === Prices.static
                        ? values?.additionalInfo?.prms[index]?.gridPrice?.static
                        : dynamicValues && dynamicValues?.[key2]
                    )
                      ? 'EUR/kWh'
                      : ' '
                  }
                  fieldName={`additionalInfo.prms.${index}.${key}`}
                  onKeyPress={onKeyPressOnlyNumbersWithDot}
                />
              </StyledInputWrapper>
            </StyledTypeWrapper>
          ))}
        </StyledInputsRow>
      </StyledPriceDetails>
      {isDynamic && consumerType === ConsumerTypeEnums.largeCompany && (
        <StyledRushInputWrapper>
          <StyledRushTitleWrapper>
            <Title>{t('rush_hours')}</Title>
            <CustomTooltip
              tooltip={
                <TextRegular>{t('if_there_is_no_rush_hour_price')}</TextRegular>
              }
            />
          </StyledRushTitleWrapper>
          <StyledRushInput>
            <FormikInput
              placeholder="0.530 EUR / kWh"
              suffix={
                values.additionalInfo.prms[index].gridPrice.dynamic?.RH
                  ? 'EUR/kWh'
                  : ' '
              }
              fieldName={`additionalInfo.prms.${index}.gridPrice.dynamic.RH`}
              onKeyPress={onKeyPressOnlyNumbersWithDot}
            />
          </StyledRushInput>
        </StyledRushInputWrapper>
      )}
    </StyledPriceDetailsWrapper>
  );
};

export default PriceDetails;
