import dayjs from 'dayjs';

import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormikContext } from 'formik';

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import { ThunderboltIcon } from 'components/communities/Charts/SummaryView/components/Title/styles';
import { validatePrmsValues } from 'components/members/createConsumer/ConsumerDetails/utils';
import { Prices } from 'components/members/types';
import { getPrmStatusLayout } from 'components/pricing/components/communityTab/ConsumerPrmItem';
import FormikInput from 'components/shared/Input/FormikInput';
import { InputGroup } from 'components/shared/InputGroup';
import { IPeriods } from 'constants/types';
import { useUpdateUserPrmMutation } from 'services/prmManagement';
import { useGetPrmByCommunityIdQuery } from 'services/prmPricesManagement';

import { getDateRange } from 'utils/date/date-range';

import { initialValues } from '../../constants';
import CommunityPrices from '../CommunityPrices';
import ElectricityGridPrices from '../ElectricityGridPrices';
import { PrmType } from '../PrmBody/types';
import {
  CalendarIcon,
  EditIcon,
  FileIcon,
  StyledBtn,
  StyledExpiredDuration,
  StyledGivenNameWrapper,
  StyledPrmHeader,
  StyledPrmInfoItem,
  StyledPrmInfoText,
  StyledPrmRow,
  StyledPrmTitle,
  StyledPrmWrapper,
  StyledSuccessBtn,
} from './styles';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

interface PrmNumberProps {
  index: number;
  communityId: string;
  refetchPrm: () => void;
}

const PrmNumber: FC<PrmNumberProps> = ({ index, communityId, refetchPrm }) => {
  const { t } = useTranslation();
  const [updateUserPrm, { isError, isSuccess }] = useUpdateUserPrmMutation();
  const { values, setFieldValue } = useFormikContext<typeof initialValues>();
  const [initialPrmValues, setInitialPrmValues] = useState<PrmType | null>(
    null,
  );
  const [priceType, setPriceType] = useState(null);
  const [isEditGivenName, setIsEditGivenName] = useState(false);

  const { data: pricesData } = useGetPrmByCommunityIdQuery(
    { communityId },
    { skip: !communityId },
  );
  const prm = useMemo(() => values.prms[index], [values, index]);
  const prmPrices = useMemo(
    () => pricesData?.find((item) => item.prm._id === prm.id),
    [prm, pricesData],
  );
  const actualPrices = useMemo(() => {
    if (prmPrices && prmPrices.periods && prmPrices.periods.length) {
      const currentDate = dayjs();
      return prmPrices.periods.find(({ startDate, endDate }: IPeriods) =>
        currentDate.isBetween(startDate, endDate),
      );
    }
    return {};
  }, [prmPrices]);

  useEffect(() => {
    if (!values.prms[index].id) return;

    if (!initialPrmValues?.id) {
      // set initial prm values when got them for the first time
      setInitialPrmValues(values.prms[index]);
      const isDynamic = !!values.prms[index].gridPrice?.dynamic;
      const isStatic = !!values.prms[index].gridPrice?.static;
      if (isDynamic) return setPriceType(Prices.dynamic as any);
      if (isStatic) return setPriceType(Prices.static as any);
      setPriceType(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, index]);

  useEffect(() => {
    if (isSuccess) {
      setInitialPrmValues(values.prms[index]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isError]);

  const onToggleEditName = useCallback(
    () => setIsEditGivenName((val: boolean) => !val),
    [],
  );

  const onCancelEditName = useCallback(() => {
    setFieldValue(`prms.${index}`, initialPrmValues);
    setIsEditGivenName(false);
  }, [initialPrmValues, index]);

  const onConfirmEditName = async () => {
    const prms: any = [...values.prms];
    const validatedPrms = validatePrmsValues(prms);

    await updateUserPrm(validatedPrms[index]);
    await refetchPrm();
    setIsEditGivenName(false);
  };

  return (
    <StyledPrmWrapper pointerEvents={isEditGivenName ? 'visible' : 'none'}>
      <StyledPrmHeader>
        <StyledPrmInfoItem>
          <StyledPrmTitle>{`${t('prm').toUpperCase()} ${
            prm.prmValue
          }`}</StyledPrmTitle>
          {getPrmStatusLayout(prm.status, t)}
        </StyledPrmInfoItem>
        <StyledPrmInfoItem>
          <ThunderboltIcon />
          <StyledPrmInfoText>{t('power')}</StyledPrmInfoText>
          {t('power_unit', { power: prm.power })}
        </StyledPrmInfoItem>
        <StyledPrmInfoItem>
          <FileIcon />
          <StyledPrmInfoText>{t('cppa')}</StyledPrmInfoText>
          {prmPrices?.cppa}
        </StyledPrmInfoItem>

        <StyledPrmInfoItem>
          <CalendarIcon />
          <StyledPrmInfoText>{t('duration')}</StyledPrmInfoText>
          <StyledExpiredDuration>
            {getDateRange({
              startDate: '2022-01-01T13:16:55.550Z',
              endDate: '2024-01-01T13:16:55.550Z',
            })}
          </StyledExpiredDuration>
        </StyledPrmInfoItem>
      </StyledPrmHeader>
      <StyledPrmRow>
        <StyledGivenNameWrapper>
          <InputGroup
            error={undefined}
            label={`${t('prm').toUpperCase()} ${t('given_name')}:`}
            customStyles={{ flexDirection: 'row', alignItems: 'center' }}
          >
            <FormikInput
              fieldName={`prms.${index}.name`}
              placeholder="Ex. Marc Department"
              type="text"
              suffix={<EditIcon onClick={onToggleEditName} />}
            />
          </InputGroup>
          {isEditGivenName && [
            <StyledSuccessBtn
              icon={<CheckOutlined />}
              onClick={onConfirmEditName}
            />,
            <StyledBtn icon={<CloseOutlined />} onClick={onCancelEditName} />,
          ]}
        </StyledGivenNameWrapper>
      </StyledPrmRow>
      <StyledPrmRow>
        <ElectricityGridPrices index={index} priceType={priceType} />
      </StyledPrmRow>
      <StyledPrmRow>
        <CommunityPrices
          period={actualPrices}
          indexation={prmPrices?.indexation?.toString() || '-'}
        />
      </StyledPrmRow>
    </StyledPrmWrapper>
  );
};

export default PrmNumber;
