import { styled } from 'styled-components';

import { COLORS } from 'constants/colors';

export const WidgetWrapper = styled.div`
  width: 209px;
  height: 203px;
  background-color: ${COLORS.BgLayout};
  box-sizing: border-box;
  border-radius: 2px;
`;

export const WidgetColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
`;

export const WidgetAmount = styled.span`
  margin-top: 24px;
  font-size: 20px;
  font-weight: 600;
  line-height: 38px;
`;

export const WidgetAmountType = styled.span`
  color: ${COLORS.TextSecondary};
  font-size: 14px;
  margin-left: 5px;
  font-weight: 600;
  line-height: 32px;
`;
export const WidgetDescription = styled.span`
  margin-top: -12px;
  color: ${COLORS.TextSecondary};
  font-size: 14px;
  font-weight: 600;
  line-height: 32px;
`;

export const WidgetImageWrapper = styled.div`
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.BgContainer};
  border-radius: 6px;
  margin-bottom: 16px;
`;
export const WidgetImage = styled.img`
  width: 36px;
  height: 36px;
`;

export const StyledMapWrapper = styled.div`
  display: flex;
  width: calc(100% - 32px);
  padding: 16px;
  border-top: 1px solid ${COLORS.Grey2};
`;

export const WidgetSkeletonRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
