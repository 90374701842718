import { styled } from 'styled-components';

import { COLORS } from 'constants/colors';

export const StyledLineChart = styled.div`
  width: 420px;
  height: 258px;
  margin-left: 20px;
  margin-top: 20px;
`;

export const CardMedium = styled.div`
  display: flex;
  width: 676px;
  height: 366px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;

  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  background: ${COLORS.BgContainer};
`;

export const RingProgressWrapper = styled.div`
  background-color: ${COLORS.BgLayout};
  width: 196px;
  min-height: 123px;
  padding: 12px;
  box-sizing: border-box;
  margin-top: 20px;
  margin-right: 16px;
`;
export const RingProgressTitle = styled.div`
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
`;

export const RingProgressRow = styled.div`
  display: flex;
  column-gap: 32px;
  align-items: center;
`;

export const RingProggresPercent = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
`;

export const RepartitionSubtitle = styled.div`
  color: ${COLORS.TextSecondary};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 12px;
`;

export const StyledRepartitionRing = styled.div`
  background-color: ${COLORS.BgContainer};
  border-radius: 50%;
  padding: 2px;
`;
