enum AppRoutes {
  Root = '/',
  SignIn = '/sign-in',
  ForgotPassword = '/forgot-password',
  CreatePassword = '/create-password/:id',
  ChangePassword = '/create-password/:id/:token',

  // protected routes bellow
  Communities = '/communities',
  CommunityDetails = '/communities/:communityName',
  AddNewCommunity = '/communities/add-new-community',
  Members = '/members',
  AddNewConsumer = '/members/add-new-consumer',
  MyCommunity = '/my-community',
  Documents = '/documents',
  Payments = '/payments',
  Forum = '/forum',
  AddNewProducer = '/members/add-new-producer',
  Dashboard = '/dashboard',
  FinancialData = '/financial-data',
  Reports = '/reports',
  Profile = '/profile',
  EnergyData = '/energy-data',
  MemberDashboard = '/members/:id',
  AddPayment = '/add-payment',
  Pricing = '/pricing',
  TaxPrices = '/pricing/coefficients',
}

export default AppRoutes;
