import { ReactNode } from 'react';

import styled, { createGlobalStyle } from 'styled-components';

import { TabPaneProps, Tabs } from 'antd';

import { COLORS } from 'constants/colors';

const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
`;

interface Tab extends Omit<TabPaneProps, 'tab'> {
  key: string;
  label: ReactNode;
}

interface ITabs {
  items: Tab[];
  backgroundColor?: string;
  defaultActiveTab?: string;
  activeTabKey?: string;
  onChange?: any;
  withScrollBar?: boolean;
  paddingLeft?: string;
}

const HideScrollBar = createGlobalStyle`
  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* For Internet Explorer, Edge */
  .hide-scrollbar {
    -ms-overflow-style: none;
  }
`;

const InnerTabs = ({
  items,
  activeTabKey = '1',
  onChange,
  backgroundColor = COLORS.BgContainer,
  defaultActiveTab = '1',
  withScrollBar = false,
  paddingLeft = '40px',
}: ITabs) => {
  const TabInlineStylesWihoutScroll = {
    alignSelf: 'center',
    margin: '0',
    width: '1440px',
    paddingLeft,
  };

  const TabInlineStylesWithScroll = {
    alignSelf: 'center',
    margin: '5px auto',
    overflow: 'scroll',
    width: '1440px',
    paddingLeft,
  };

  const TabBarInlineStyles = withScrollBar
    ? TabInlineStylesWithScroll
    : TabInlineStylesWihoutScroll;
  return (
    <>
      <HideScrollBar />
      <Container>
        {activeTabKey && onChange ? (
          <Tabs
            style={{ background: backgroundColor }}
            destroyInactiveTabPane
            size="large"
            activeKey={activeTabKey}
            onChange={onChange}
            defaultActiveKey={defaultActiveTab}
            items={items}
            tabBarStyle={TabBarInlineStyles}
          />
        ) : (
          <Tabs
            style={{ background: backgroundColor }}
            destroyInactiveTabPane
            size="large"
            defaultActiveKey={defaultActiveTab}
            items={items}
            tabBarStyle={TabBarInlineStyles}
          />
        )}
      </Container>
    </>
  );
};

export default InnerTabs;
