import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import TitleItem from 'components/communities/Charts/SummaryView/components/Title/components/TitleItem';
import {
  LineDivider,
  LocationIcon,
  SummaryTitle,
  ThunderboltIcon,
} from 'components/communities/Charts/SummaryView/components/Title/styles';
import { ROLES } from 'constants/global';

import { capitalizeFirstLetter } from 'utils/forms';

import { CommunityIcon, HomeIcon } from './styles';

interface PmoMemberTitleProps {
  community?: string;
  location?: string;
  type?: string;
  power?: number | null | string;
  role?: string;
  prmNumbers?: number | null;
}

export const PmoMemberTitle: FC<PmoMemberTitleProps> = ({
  community = 'N/A',
  location = 'N/A',
  type = 'n/A',
  power = 0,
  prmNumbers = 0,
  role,
}) => {
  const { t } = useTranslation();
  return (
    <SummaryTitle>
      <TitleItem
        preffixDescription={t('community').toString().toUpperCase()}
        value={community}
      >
        <CommunityIcon />
      </TitleItem>
      <LineDivider>|</LineDivider>
      <TitleItem value={location}>
        <LocationIcon />
      </TitleItem>
      {role === ROLES.CONSUMER && (
        <>
          <LineDivider>|</LineDivider>
          <TitleItem
            preffixDescription={t('type').toString().toUpperCase()}
            value={capitalizeFirstLetter(type)}
          >
            <HomeIcon />
          </TitleItem>
        </>
      )}
      <LineDivider>|</LineDivider>
      {role === ROLES.PRODUCER ? (
        <TitleItem
          preffixDescription={t('produced_power').toString().toUpperCase()}
          value={power + ' MW'}
        >
          <ThunderboltIcon />
        </TitleItem>
      ) : (
        <TitleItem preffixDescription={"PRM'S"} value={prmNumbers + ''}>
          <ThunderboltIcon />
        </TitleItem>
      )}
    </SummaryTitle>
  );
};

export default PmoMemberTitle;
