import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from 'antd';

import { StyledForm } from 'components/auth/SignIn/styles';
import { BtnPrimary, BtnSecondary } from 'components/shared/Button';
import { FormInput } from 'components/shared/Input';
import FormikInput from 'components/shared/Input/FormikInput';
import { InputGroup } from 'components/shared/InputGroup';
import { COLORS } from 'constants/colors';
import { POWER_OPTION } from 'constants/global';

import { initialValues } from '../constants';
import { FormikProps } from '../types';
import { POWER_DROPDOWN_ITEMS } from './constants';
import {
  StyledDropDownWrapper,
  StyledInputWrapper,
  StyledPowerOfProductionWrapper,
  StyledSelect,
} from './styles';

interface SettingsProps
  extends Pick<
    FormikProps<typeof initialValues>,
    'errors' | 'isValid' | 'handleSubmit' | 'values' | 'setFieldValue'
  > {
  onBackButtonClick: () => void;
  hasToBeValidated: boolean;
  isError: boolean;
  validateFields: (fields: string[]) => Promise<boolean>;
  areValidFields: (fields: string[]) => boolean;
  setHasToBeValidated: (hasToBeValidated: boolean) => void;
}
const Settings: React.FC<SettingsProps> = ({
  errors,
  isValid,
  values,
  isError,
  handleSubmit,
  onBackButtonClick,
  setFieldValue,
  setHasToBeValidated,
  hasToBeValidated,
}) => {
  const [selectedPowerOption, setSelectedPowerOption] = useState<POWER_OPTION>(
    POWER_OPTION.KW,
  );
  const [maxPower, setMaxPower] = useState(values.maxPower);
  const { t } = useTranslation();

  const handleSelectChange = (value: unknown) => {
    setSelectedPowerOption(value as POWER_OPTION);
    if (maxPower) {
      if (value === POWER_OPTION.KW) {
        setMaxPower(maxPower * 1000);
      } else {
        setMaxPower(maxPower / 1000);
      }
    }
  };

  const handleMaxPowerChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const power = +event.target.value;
    if (power) {
      if (selectedPowerOption === POWER_OPTION.MW) {
        await setFieldValue('maxPower', power);
      } else {
        await setFieldValue('maxPower', power / 1000);
      }
    }
    setMaxPower(power);
  };

  const onConfirmClick = async () => {
    setHasToBeValidated(true);
    handleSubmit();
  };

  return (
    <StyledForm>
      <InputGroup
        label={t('validation.max_of_consumers_expected').toString()}
        error={errors.maxConsumers}
      >
        <FormikInput
          fieldName="maxConsumers"
          type="number"
          placeholder="Ex. 100"
        />
      </InputGroup>

      <InputGroup
        label={t('validation.max_of_producers_expected').toString()}
        error={errors.maxProducers}
      >
        <FormikInput
          fieldName="maxProducers"
          type="number"
          placeholder="Ex. 100"
        />
      </InputGroup>

      <InputGroup
        label={t('max_distance_between_participants').toString()}
        error={errors.maxDistance}
      >
        <FormikInput
          fieldName="maxDistance"
          type="number"
          placeholder="Ex. 2 km - 20 km"
        />
      </InputGroup>

      <StyledPowerOfProductionWrapper>
        <StyledInputWrapper>
          <InputGroup
            label={t('max_power_of_production').toString()}
            error={errors.maxPower}
          >
            <FormInput
              type="number"
              placeholder="500 KW - 3 MW"
              value={maxPower ?? undefined}
              isError={!!errors.maxPower}
              onChange={handleMaxPowerChange}
            />
          </InputGroup>
        </StyledInputWrapper>
        <StyledDropDownWrapper>
          <InputGroup isShowError={false}>
            <StyledSelect
              defaultValue={selectedPowerOption}
              onChange={handleSelectChange}
            >
              {POWER_DROPDOWN_ITEMS.map((key) => (
                <Select.Option key={key} value={key}>
                  {key.toUpperCase()}
                </Select.Option>
              ))}
            </StyledSelect>
          </InputGroup>
        </StyledDropDownWrapper>
      </StyledPowerOfProductionWrapper>
      <InputGroup
        label={t('agreement_id_for_collective_self_consumption').toString()}
        error={errors.agreementId}
      >
        <FormikInput
          fieldName="agreementId"
          type="text"
          placeholder="Ex. ACC00000478"
        />
      </InputGroup>
      <InputGroup
        errorCentered={true}
        error={isError ? t('something_went_wrong').toString() : ''}
      >
        <>
          <BtnPrimary
            isDisabled={hasToBeValidated && !isValid}
            onClick={onConfirmClick}
          >
            {t('add_new_community')}
          </BtnPrimary>
          <BtnSecondary color={COLORS.TextBase} onClick={onBackButtonClick}>
            {t('back')}
          </BtnSecondary>
        </>
      </InputGroup>
    </StyledForm>
  );
};

export default Settings;
