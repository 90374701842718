import { styled } from 'styled-components';

import { COLORS } from 'constants/colors';

export const StyledLineChart = styled.div`
  width: 1100px;
  height: 289px;
  margin-bottom: 35px;
  margin-right: 25px;
`;

export const StyledWrapper = styled.div`
  min-width: 238px;
  max-height: 190px;
  padding: 12px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
`;

export const StyledDate = styled.div`
  color: ${COLORS.TextSecondary};
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 10px;
`;

export const StyledRow = styled.div`
  color: ${COLORS.TextBase};
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 10px;
`;

export const StyledPower = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

export const StyledKw = styled.span`
  font-size: 12px;
  font-weight: 600;
`;

export const StyledType = styled.div`
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  margin-left: 4px;
`;
