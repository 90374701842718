import { useFormikContext } from 'formik';

import { getValueByPath } from '../DynamicEditForm/dynamic-edit-form.helper';
import { Placeholder, StyledSwitch, SwitchWrapper } from './styled';

interface IGenericFormSwitch {
  path: string;
  placeholder: string;
  value: boolean;
}

const GenericFormSwitch = ({
  path,
  placeholder,
  value,
}: IGenericFormSwitch) => {
  const { values, setFieldValue } = useFormikContext();
  const pathValue = getValueByPath(values, path);

  return (
    <SwitchWrapper>
      <StyledSwitch
        checked={pathValue}
        onChange={() => setFieldValue(path, !pathValue)}
      />
      <Placeholder>{placeholder}</Placeholder>
    </SwitchWrapper>
  );
};

export default GenericFormSwitch;
